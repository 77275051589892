import { FC, useState, useMemo, useEffect, useRef } from 'react'
import { Form, Input, Row, Col, Card, Checkbox, Tabs, Button, Select, Spin, notification, Tag, Tooltip, Modal } from 'antd'
let FormItem = Form.Item
import { UserItemStore, UserItem } from 'src/stores/users/users-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import ContentTitle from 'src/core/ui/content-header'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import UserRolesView from './roles/userroles-list'
import UserGroupsView from './groups/usergroups-list'
import { nameof } from 'src/core/utils/object'
const { TabPane } = Tabs
import ScopedPermissionList from 'src/components/scopedPermissionList'
import ChangePassowrd from 'src/pages/admin/users/changePasword'
import LangEditor from 'src/components/langEdit'
import { container } from 'src/inversify.config'
import { ExclamationCircleOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import AreaEditor from 'src/components/areaEdit'
import { TenantIdentityDataStore, TenantItem } from '../../../stores/tenant-identity/tenant-identity-store'
import EditMemberDialog from 'src/pages/admin/tenants/components/identity-tenant-roles-editor'
import { AddTenantDialog } from 'src/pages/admin/identity-tenants/tenant-identities'
import { IdentityProps, withIdentity } from '../../../core/services/authentication'
import { isNullOrWhitespace } from 'src/core/utils/object'
import FloatInput from "src/core/ui/awesome/floatComponent/input"
import FloatSelect from '../../../core/ui/awesome/floatComponent/select'
const { info, error, confirm } = Modal;


interface UserViewProps extends WithTranslation,IdentityProps, RouteComponentProps<RouterParameters> {

}

interface UserViewState {
  showPasswordModal: boolean
}

interface RouterParameters {
  id: string
}

const UserView: FC<UserViewProps> = (props) => {

  const { t } = props
  const userStore = useMemo(() => container.get(UserItemStore), [])
  const userStoreState = userStore.state
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [form] = Form.useForm<UserItem>()
  const [data, setData] = useState<TenantItem[]>([])

  const [initialValues, setInitialValues] = useState<UserItem | undefined>()
  const inputRef = useRef()

  const entityIdentityDataStore = container.get<TenantIdentityDataStore>(TenantIdentityDataStore)
  const entityIdentityDataState = entityIdentityDataStore.state


  const isAdmin = props.identity.roles && props.identity.roles.filter((o) => o.includes('Administrator')).length != 0 ? true : false

  const [editIdentityVisible, setEditIdentityVisible] = useState(false)
  const [addTenantVisible, SetAddTenantVisible] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState<any>(undefined)

  useEffect(() => {
    if (props.match.params.id) {
      load()
      loadTenants()
    }
  }, [inputRef]) // eslint-disable-line react-hooks/exhaustive-deps

  const load = async () => {
    await userStore.load(props.match.params.id)
    setInitialValues(userStoreState.item.get())
   
  }


  const loadTenants = async () =>
  {
    const entity = await entityIdentityDataStore.Get(props.match.params.id)
    setData(entity.tenants)
  }

  const onUpdateItem = async () => {
    let values: UserItem
    try {
        values = (await form.validateFields()) as UserItem
        notification['success']({
            message: t("Changes have been saved"),
            onClick: () => {
            },
            //icon: <SmileOutlined style={{ color: '#108ee9' }} />,
        });
    } catch {
      return
    }
    values.areaId = (values.areaId as any).id || values.areaId
    let response = userStore.update(props.match.params.id, values)
    if (response) {
    }
  }

  const showPasswordChangeModal = () => {
    setShowPasswordModal(true)
  }

  const onCloseAddTenant = (selectedTenantId: any, close: boolean) => {
    SetAddTenantVisible(false)
    if (close) {
      setSelectedTenant(selectedTenantId)
      setEditIdentityVisible(true)
    }
  
  }
  const handleClose = async (identityId: string, tenantId: string) => {
    await entityIdentityDataStore.Delete({ tenantId: tenantId, identityId: identityId })
    loadTenants();
  };

  const handleCloseAll = async (identityId: string, tenats: TenantItem[]) => {

    if(!tenats.any()) return;
    if( isNullOrWhitespace(identityId)) return;

    for (const tenant of tenats) {
      await entityIdentityDataStore.Delete({ tenantId: tenant.id, identityId: identityId })
    }
    
    loadTenants();
  };

  return <div>
    <ContentTitle type={t('User')} title={initialValues?.userName} />
    {
      showPasswordModal && <ChangePassowrd userId={props.match.params.id} onClose={() => setShowPasswordModal(false)} />
    }
    <Row gutter={16}>
      <Col span={8}>
        <Card title={t("General properties")}>
          <Spin spinning={userStoreState.isBusy.get()} >
            {initialValues &&
              <Form form={form} layout="vertical" initialValues={initialValues}>
                <FormItem name={nameof<UserItem>('userName')}>
                  <FloatInput label={t("Username")} readOnly={true} />
                </FormItem>
                <FormItem  name={nameof<UserItem>('email')} rules={[{ required: true, message: t('Please input your email!') }]}>
                  <FloatInput label={t("Email")} required placeholder={t("Email")} />
                </FormItem>
                <FormItem name={nameof<UserItem>('firstName')} rules={[{ required: true, message: t('Please input your first name!') }]}>
                  <FloatInput label={t("First name")}  required placeholder={t("First name")} />
                </FormItem>
                <FormItem  name={nameof<UserItem>('lastName')} rules={[{ required: true, message: t('Please input your last name!') }]}>
                  <FloatInput required label={t("Last name")} placeholder={t("Last name")} />
                </FormItem>
                <FormItem  name={nameof<UserItem>('language')} rules={[{ required: true, message: t('Please select your preferred language!') }]}>
                  <LangEditor required label={t("Preferred language")} placeholder={t("Preferred language")} />
              </FormItem>
              <FormItem name={nameof<UserItem>('areaId')} rules={[{ required: true, message: t('Area required') }]}>
                <AreaEditor required label={t("Area")} valueAsItemReference />
              </FormItem>
              <FormItem name={nameof<UserItem>('validateScopes')}  valuePropName='checked'>
                <Checkbox> <span style={{ marginLeft: 10 }}>{t("Restrict client access by scope")}</span></Checkbox>
              </FormItem>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem  name={nameof<UserItem>('jobTitle')}>
                      <FloatInput label={t('Job Title')} placeholder={t('Job Title')} readOnly={true} />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem  name={nameof<UserItem>('manager')}>
                      <FloatInput label={t('Manager')} placeholder={t('Manager')} readOnly={true} />
                    </FormItem>
                  </Col>
                </Row>
                <FormItem  name={nameof<UserItem>('teams')}>
                  <FloatSelect label={t('Teams')} placeholder={t('Teams')} className="normal-looking-multiselect" disabled mode="multiple">
                    {(userStoreState.item.get()?.teams || []).map(t => (<Select.Option key={t} value={t}>{t}</Select.Option>))}
                  </FloatSelect>
                </FormItem>
                <Button style={{ float: 'right', marginLeft: '10px'}} onClick={showPasswordChangeModal}>{t('Change password')}</Button>
                <div
                  style={{
                    textAlign: 'right'
                  }}
                >
                  <Button onClick={() => { form.resetFields() }} style={{ marginRight: 8 }}>
                    {t('Cancel')}
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={() => onUpdateItem()} loading={userStoreState.isBusy?.value} >
                    {t('Save')}
                  </Button>
                </div>
              </Form>}
          </Spin>

        </Card>
      </Col>
      <Col span={16}>
        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab={t("Member of")} key="1" style={{ zIndex: 0 }}>
            <Card>
              <UserGroupsView id={props.match.params.id} />
            </Card>
          </TabPane>
          <TabPane tab={t("Tenants")} key="2">
              <Card title={t("")} bordered={true}>
                <span>
                  {data.any(e => e.id == undefined) &&
                    <Tag
                      onClick={() => {
                        setSelectedTenant(undefined)
                        setEditIdentityVisible(true)

                    }}
                    closable
                    onClose={x => {
                      x.preventDefault()
                      confirm({
                        title: t('Are you sure to revoke this access?'),
                        icon: <ExclamationCircleOutlined />,
                        content: "",
                        okText: t('Yes'),
                        okType: 'danger',
                        okButtonProps: {
                          disabled: false,
                        },
                        cancelText: t('No'),
                        onOk() {
                          handleClose(props.match.params.id, undefined)
                        },
                        onCancel() {

                        },
                      })

                    }}


                      style={{ fontSize: "12px", padding: "2px 15px", cursor: "pointer" }} color={data.any(e => e.id == null) ? "#0e0e4f" : "seagreen"}>
                      {t("Global")} </Tag>}
                  {data.filter(e => e.id != undefined).map(e =>

                    <Tag
                      onClick={() => {
                        setSelectedTenant(e.id)
                        setEditIdentityVisible(true)

                      }}
                      closable
                      onClose={x => {
                        x.preventDefault()
                        confirm({
                          title: t('Are you sure to revoke this access?'),
                          icon: <ExclamationCircleOutlined />,
                          content: "",
                          okText: t('Yes'),
                          okType: 'danger',
                          okButtonProps: {
                            disabled: false,
                          },
                          cancelText: t('No'),
                          onOk() {
                            handleClose(props.match.params.id, e.id)
                          },
                          onCancel() {

                          },
                        })

                      }}
                      style={{ fontSize: "12px", padding: "2px 15px", cursor: "pointer" }} color={e.id ? "seagreen" : "#0e0e4f"}>
                      {e.id ? !e.title || e.title.length === 0 ? t("(Empty Name)") : e.title : t("Global")}
                    </Tag>
                  )}
                  <Tooltip placement="topRight" title={t('Add Tenant Roles')}>
                    <Button
                      onClick={() => {
                        SetAddTenantVisible(true)
                      }}
                      type="primary" icon={<PlusOutlined />} />
                  </Tooltip>

                  <Tooltip placement="topRight" title={t('Remove all Tenant Roles')}>  
                     {data.any() &&                      
                      <Button                  
                            onClick={x => {
                                x.preventDefault()
                                confirm({
                                  title: t('Are you sure you want to remove all permissions from all current tenants for this user?'),
                                  icon: <ExclamationCircleOutlined />,
                                  content: "",
                                  okText: t('Yes'),
                                  okType: 'danger',
                                  okButtonProps: {
                                    disabled: false,
                                  },
                                  cancelText: t('No'),
                                  onOk() {
                                    handleCloseAll(props.match.params.id, data)
                                  },
                                  onCancel() {
          
                                  },
                                })        
                              }
                            }
                        danger type="primary" style={{margin: 5}} icon={<DeleteOutlined />} />
                      } 
                  </Tooltip>

                </span>
                {editIdentityVisible && (
                  <EditMemberDialog
                    identityId={props.match.params.id}
                    entityId={selectedTenant}
                    onClose={() => {
                      setEditIdentityVisible(false)
                      loadTenants()
                    }}
                    {...props}
                  />
                )}

                {addTenantVisible && (
                  <AddTenantDialog
                    isAdmin={isAdmin}
                    onClose={onCloseAddTenant}
                    {...props}
                  />
                )}
              </Card>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  </div>
}

export default withIdentity( withRouter(withTranslation()(UserView)))
