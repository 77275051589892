import * as React from 'react'
import autobind from 'autobind-decorator'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Avatar, Select } from 'antd'
import { FC } from 'react'
import FloatSelect from "src/core/ui/awesome/floatComponent/select"
import esFlag from 'src/core/assets/images/flags/es.png'
import enFlag from 'src/core/assets/images/flags/uk.png'
import frFlag from 'src/core/assets/images/flags/fr.png'
import ptFlag from 'src/core/assets/images/flags/ptPT.png'
import brFlag from 'src/core/assets/images/flags/ptBR.png'
import itFlag from 'src/core/assets/images/flags/it.png'

const { Option } = Select
interface LangEditorProps extends WithTranslation {
  disabled?: boolean
  label?: string
  required?: boolean
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  minWidth?: number
  width?: number
}

const LangEditor: FC<LangEditorProps> = (props) => {


  const getLangs = () => {
    return [
      { key: 'en', value: 'English', flag: enFlag },
      { key: 'es', value: 'Spanish', flag: esFlag },
      { key: 'fr', value: 'French', flag: frFlag },
      { key: 'pt', value: 'Portuguese', flag: ptFlag },
      { key: 'pt-br', value: 'Portuguese', flag: brFlag },
      { key: 'it', value: 'Italian', flag: itFlag },]
  }

  const handleChange = (item: any) => {
    const { onChange } = props
    if (onChange) {
      onChange(item
        ? item.id ? item.id : item
        : undefined
      )
    }
  }


  const { t } = props
  const langs = getLangs()
  return <FloatSelect placeholder={props.placeholder} label={props.label} required={props.required}
    value={props.value}
    disabled={props.disabled}
    style={{ width: props.width ? props.width : '100%', minWidth: props.minWidth ? props.minWidth : '100%' }} onChange={handleChange}>
    {
      langs.map((lang: { key: string, value: string, flag: any }, index: number) => <Option key={index} value={lang.key}>
        <Avatar size="default" shape="square" src={lang.flag} />
        <span style={{ marginLeft: '10px' }}>{t(lang.value)}</span>
      </Option>)
    }
  </FloatSelect>

}

export default withTranslation()(LangEditor)
