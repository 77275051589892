import HttpService from '../../core/services/http.service';
import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'
import { CommandResult } from '../../core/stores/types';
import { container } from '../../inversify.config';

export interface UserProfileItem {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  hasAvatar: boolean;
  language: string;
}

export class UserProfileStore extends FormStore<UserProfileItem, UserProfileItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/profile`)
    this.publicUrl = baseUrl
  }

  

    public async get(): Promise<UserProfileItem> {
      var httpService = container.get(HttpService);
        const result = await  httpService.get<UserProfileItem>(this.baseUrl);
        return result.data;
    }

    public async updateProfile( item: UserProfileItem) {
      var httpService = container.get(HttpService)
      const result = await httpService.put<UserProfileItem, any>(this.baseUrl, item)
      return result.data;
    }

  }