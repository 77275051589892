import { Card, Col, Modal, Row, Skeleton, Spin } from 'antd'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { FC, useMemo, useState } from 'react'
import logo from '../core/assets/logo.png'
import { IdentityProps, withIdentity } from '../../core/services/authentication'
import { container } from '../../inversify.config'
import { DashBoardStore } from '../../stores/dashBoard/dashBoard-store'

import { SettingOutlined, SyncOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import TenantEdit from '../tenantEdit'
import ApplicationEdit from '../applicationEdit'


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export interface AdminPerAreasProps extends WithTranslation, IdentityProps {
  text: string
}

const AdminPerAreas: FC<AdminPerAreasProps> = ({
  identity,
  t,
  text,
  children,
}) => {

  const [loading, setIsLoading] = useState<boolean>(true)
  const currentStore = useMemo(() => container.get(DashBoardStore), [])
  const currentState = currentStore.state
  const [data, setData] = useState<any>()
  const [zoom, setZoom] = useState(false)

  const [tenantId, setTenantId] = useState<string | undefined>()
  const [appId, setAppId] = useState<string | undefined>()

  const options = {
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
      },
      title: {
        display: true,
        text: t('Global Administrator per Areas'),
      },
    },
  }


  const LoadPage = async () => {
    let chart1 = await currentStore.GlobalAdminPerAreas(tenantId, appId)
    let labels = chart1.map(x => x.key)


    const
      current = {
        labels: labels,
        datasets: [
          {
            label: t('Global Admin'),
            data: chart1.map(x => x.value),
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      }
    setData(current)
    setIsLoading(false)
  }

  React.useEffect(
    () => {
      LoadPage()
    }, [appId, tenantId]
  )

  const Refresh = async () => {
    setIsLoading(true)
    await LoadPage()
  }


  return (
    <>
      <div className={"report-container"} >
        <Card
          style={{}}
          actions={
            !zoom ? [
              <ZoomInOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
              <SyncOutlined onClick={() => Refresh()} key="refresh" />


            ] :
              [
                <ZoomOutOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
                <SyncOutlined onClick={() => Refresh()} key="refresh" />
              ]
          }
        >
          <Skeleton loading={loading} avatar active>
            {data && <Bar data={data} options={options} />}
          </Skeleton>
        </Card>
      </div>
      {zoom && <div className={zoom ? "report-fullscreen report-container opacity-1" : "report-fullscreen report-container opacity-0"} >
        <Card
          style={{}}
          actions={
            !zoom ? [
              <ZoomInOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
              <SyncOutlined onClick={() => Refresh()} key="refresh" />


            ] :
              [
                <ZoomOutOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
                <SyncOutlined onClick={() => Refresh()} key="refresh" />
              ]
          }
        ><>
            <Row gutter={50}>
              <Col span={12}><TenantEdit onChange={(value) => setTenantId(value?.value)} /></Col>
              <Col span={12}> <ApplicationEdit onChange={(value) => setAppId(value?.id)} placeholder={t("Select Application")} /></Col>
            </Row>
            <Skeleton loading={loading} avatar active>
              {data && <Bar data={data} options={options} />}
            </Skeleton>
          </>
        </Card>

      </div>}
    </>
  )

}


export default withIdentity(withTranslation()(AdminPerAreas) as any)
