import * as React from 'react'
import { Card, Row, Col, Spin, notification } from 'antd';
import autobind from 'autobind-decorator'
import { UserProfileItem } from 'src/stores/profiles/profile-store'
import { v4 } from 'uuid';
import { getGravatar } from 'src/core/utils/gravatar';
import { withTranslation, WithTranslation } from 'react-i18next';
import Dragger from 'antd/lib/upload/Dragger';
import { authenticationService } from 'src/core/services/authentication';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { read } from 'fs';
import { FC, useEffect, useState } from 'react';
import { CheckOutlined, InboxOutlined } from '@ant-design/icons';

interface ProfilePictureViewProps extends WithTranslation {
    profile: UserProfileItem
}

interface ProfilePictureViewState {
   
}

const ProfilePictureView : FC<ProfilePictureViewProps> = (props) => {

  const [loading, setLoading] = useState( false)
  const [uploading, setUploading] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState(getGravatar(props.profile.email, 180))
  const { t, profile } = props;

  useEffect(() => {
    setLoading(false)
    setAvatarUrl(getGravatar(props.profile.email, 180))
  }, [profile]) // eslint-disable-line react-hooks/exhaustive-deps

  const getBase64 = (file: UploadFile<any>) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => { setUploading(false); setAvatarUrl(reader.result as string) })
    reader.readAsDataURL(file.originFileObj as Blob)
  }

    const onUpdate = (value: UploadChangeParam) => {
      if (value.file.status === "uploading") {
        setUploading(true)
        }
        if (value.file.status === "done") {
            getBase64(value.file);
            notification['success']({
                message: t("Image uploaded successfully"),
                icon: <CheckOutlined style={{ color: '#108ee9' }} />,
            })
        }
    }


        
        return <div>
            {<Card title={t("Your picture")}>
                <Row gutter={16}>
                    {!loading && <Col span={24} className='avatar-container'><img width="100px" src={avatarUrl} /></Col>}

                    <Col span={loading ? 18 : 24}>
                        <Spin spinning={uploading}>
                            <Dragger
                                action="api/v1/profile/avatar"
                                onChange={onUpdate}
                                headers={{
                                    Authorization: `Bearer ${authenticationService.accessToken}`
                                }}
                                showUploadList={false}>
                                <p className="ant-upload-drag-icon">
                                     <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    {t("Click or drag file to update your profile picture")}
                                </p>
                            </Dragger>
                        </Spin>
                    </Col>
                </Row>
            </Card>}
        </div>
    }

export default withTranslation()(ProfilePictureView)


