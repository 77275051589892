import * as React from 'react'
import { Modal, Button, Tooltip, Form, Input, Row, Col, Radio, RadioChangeEvent } from 'antd'
import autobind from 'autobind-decorator'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { CopyOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons'
import FormItem from 'antd/es/form/FormItem'
import { nameof } from '../../../../../../core/utils/object'
import LangEditor from 'src/components/langEdit'
import { IdentityType } from '../../../../../../stores/tenants/common'
import  EditMemberDialog  from './components/identity-tenant-roles-editor'

interface SetPermissionViewProps {
  entityId?: string, isGroup?: boolean; identityId: string, apply: number, onClose: (cancel: boolean) => void,
  onDisableButton: (value: boolean) => void
}



const SetPermissionView: React.FC<SetPermissionViewProps & WithTranslation> = (props) => {

  const { t, apply } = props
  const [currentapply, setApply] = useState(props.apply)

  useEffect(() => {
    setApply(apply)
  }, [apply]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row style={{ width: "100%" }}>
      <EditMemberDialog onDisableButton={(value) => props.onDisableButton(value)} apply={currentapply} onClose={(resp) => props.onClose(resp)} identityId={props.identityId} entityId={props.entityId} isGroup={props.isGroup} />
    </Row>
  )
}

export default (withTranslation()(SetPermissionView) as any) as React.ComponentClass<SetPermissionViewProps>
