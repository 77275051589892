import { Card, Modal, Skeleton, Spin } from 'antd'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { FC, useMemo, useState } from 'react'
import logo from '../core/assets/logo.png'
import { IdentityProps, withIdentity } from '../../core/services/authentication'
import { container } from '../../inversify.config'
import { DashBoardStore } from '../../stores/dashBoard/dashBoard-store'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import moment from 'moment'
import { SettingOutlined, SyncOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { loadPage } from '../../core/utils/nav'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)


export interface ConnectedPerAppProps extends WithTranslation, IdentityProps {
  text: string
}

const ConnectedPerApp: FC<ConnectedPerAppProps> = ({
  identity,
  t,
  text,
  children,
}) => {

  const [loading, setIsLoading] = useState<boolean>(true)
  const currentStore = useMemo(() => container.get(DashBoardStore), [])
  const currentState = currentStore.state
  const [data, setData] = useState<any>()
  const [zoom, setZoom] = useState(false)
  const inputRef = React.useRef()

  const LoadPage = async () => {
    let chart1 = await currentStore.ConnectedPerApp()
    let labels = chart1.map(x => x.key)


    const current = {
      labels,
      datasets: [
        {
          label: t('Users Connected'),
          data: chart1.map(x => x.value),
          borderColor: '#e53935',
          backgroundColor: '#e53935',
        },

      ],
    }
    setData(current)
    setIsLoading(false)
  }

  React.useEffect(
    () => {
      LoadPage()
    }, [inputRef]
  )

  const options = {
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
      },
      title: {
        display: true,
        text: t('Connected per App last 30 minutes'),
      },
    },
  }



  const Refresh = async () => {
    setIsLoading(true)
    await LoadPage()
  }



  return (
    <>
      <div className={"report-container"} >
        <Card
          style={{}}
          actions={
            !zoom ? [
              <ZoomInOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
              <SyncOutlined onClick={() => Refresh()} key="refresh" />


            ] :
              [
                <ZoomOutOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
                <SyncOutlined onClick={() => Refresh()} key="refresh" />
              ]
          }
        >
          <Skeleton loading={loading} avatar active>
            {data && <Bar options={options} data={data as any} />}
          </Skeleton>
        </Card>
      </div>
      {zoom && <div className={zoom ? "report-fullscreen report-container opacity-1" : "report-fullscreen report-container opacity-0"} >
        <Card
          style={{}}
          actions={
            !zoom ? [
              <ZoomInOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
              <SyncOutlined onClick={() => Refresh()} key="refresh" />


            ] :
              [
                <ZoomOutOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
                <SyncOutlined onClick={() => Refresh()} key="refresh" />
              ]
          }
        >
          <Skeleton loading={loading} avatar active>
            {data && <Bar options={options} data={data as any} />}
          </Skeleton>
        </Card>
      </div>
      }
    </>
  )

}


export default withIdentity(withTranslation()(ConnectedPerApp) as any)
