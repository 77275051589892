import * as React from 'react'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import { withIdentity, IdentityProps } from "../../core/services/authentication"
import { withTranslation, WithTranslation } from 'react-i18next'
import { isNullOrWhitespace } from '../../core/utils/object'
import { Avatar, Button, Card, Col, Divider, Row, Skeleton } from 'antd'
import { ApartmentOutlined, CheckOutlined, CompressOutlined, CrownOutlined, DesktopOutlined, EditOutlined, EllipsisOutlined, ExceptionOutlined, FileSearchOutlined, FireOutlined, GoldOutlined, KeyOutlined, LaptopOutlined, SearchOutlined, SecurityScanOutlined, SettingOutlined, UsergroupAddOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import { BaseAppUrl, container } from '../../inversify.config'
import { DashBoardStore, DashBoardSummary } from '../../stores/dashBoard/dashBoard-store'
import CustomLoad from '../../components/customLoad'
import IdentityAs from '../../components/dashboard/identityAs'

const HomePanel: React.FC<IdentityProps & WithTranslation> = (props) => {

  const [isAdmin] = useState(props.identity.roles != undefined && props.identity.roles.some(value => value === "Administrator"))
  const [isGod] = useState(props.identity.roles != undefined && props.identity.roles.some(value => value === "God"))
  const { t } = props

  const currentStore = useMemo(() => container.get(DashBoardStore), [])
  const currentState = currentStore.state

  const inputRef = React.useRef()
  const [dashBoardSummary, setDashBoardSummary] = useState<DashBoardSummary | undefined>()

  const [identifyAsEnabled, setIdentifyASEnabled] = useState<boolean>(false)

  const LoadPage = async () => {
    let data = await currentStore.GetResume()
    setDashBoardSummary(data)
    if (isGod) {
      let isEnabled = await currentStore.identifyAsEnabled()
      setIdentifyASEnabled(isEnabled)
    }
  }

  useEffect(
    () => {

      LoadPage()

    }, [inputRef]
  )



  return <CustomLoad text={t("Loading Dashboard...")} isLoading={currentState.isBusy.get()}>
    <Row gutter={10}>
      <Col span={8}>
        <div className="admin-tile">
          <Link to='admin/users'>
            <div className="admin-tile-icon bg-gradient-dark"><UserOutlined /></div>
            <div className="admin-tile-text">{t('Users')}</div>
            <div className="admin-tile-number">{dashBoardSummary?.usersCount || "0"}</div>
          </Link>
        </div>
      </Col>
      <Col span={8}>
        <div className="admin-tile">
          <Link to='admin/groups'>
            <div className="admin-tile-icon bg-gradient-primary"> <UsergroupAddOutlined /></div>
            <div className="admin-tile-text">{t('Groups')}</div>
            <div className="admin-tile-number">{dashBoardSummary?.groupsCount || "0"}</div>
          </Link>
        </div>
      </Col>
      <Col span={8}>
        <div className="admin-tile">
          <Link to='admin/applications'>
            <div className="admin-tile-icon bg-gradient-info"> <DesktopOutlined /></div>
            <div className="admin-tile-text">{t('Applications')}</div>
            <div className="admin-tile-number">{dashBoardSummary?.appsCount || "0"}</div>
          </Link>
        </div>
      </Col>
      <Col span={8}>
        <div className="admin-tile">
          <Link to='admin/areas'>
            <div className="admin-tile-icon bg-gradient-success">   <GoldOutlined /></div>
            <div className="admin-tile-text">{t('Areas')}</div>
            <div className="admin-tile-number">{dashBoardSummary?.areasCount || "0"}</div>
          </Link>
        </div>
      </Col>
      <Col span={8}>
        <div className="admin-tile">
          <Link to='admin/tenants'>
            <div className="admin-tile-icon bg-gradient-warning">    <ApartmentOutlined /></div>
            <div className="admin-tile-text">{t('Tenants')}</div>
            <div className="admin-tile-number">{dashBoardSummary?.tenantsCount || "0"}</div>
          </Link>
        </div>
      </Col>
    </Row>
    <Row gutter={10}>
      <Col span={12}>
        <div className="admin-tile">
          <Link to='admin/permissions'>
            <div className="admin-tile-icon bg-gradient-danger">   <SecurityScanOutlined /></div>
            <div className="admin-tile-text">{t('Permissions')}</div>
            <div className="admin-tile-number"><SecurityScanOutlined /></div>
          </Link>
        </div>
      </Col>
      <Col span={12}>
        <div className="admin-tile">
          <Link to='admin/pats'>
            <div className="admin-tile-icon bg-gradient-success">     <KeyOutlined /></div>
            <div className="admin-tile-text">{t('Personal Access Tokens')}</div>
            <div className="admin-tile-number"> <KeyOutlined /></div>
          </Link>
        </div>
      </Col>
    </Row>
    <Row gutter={10}>
      <Col span={12}>
        <div className="admin-tile ">
          <Link to='admin/logs'>
            <div className="admin-tile-icon bg-gradient-info">  <FileSearchOutlined /></div>
            <div className="admin-tile-text">{t('Logs')}</div>
            <div className="admin-tile-number"><FileSearchOutlined /></div>
          </Link>
        </div>
      </Col>

      <Col span={12}>
        <div className="admin-tile">
          <Link to='admin/twomodeauth'>
            <div className="admin-tile-icon bg-gradient-primary">    <SecurityScanOutlined /></div>
            <div className="admin-tile-text"> {t('MFA Configuration')}</div>
            <div className="admin-tile-number">   <SecurityScanOutlined /></div>
          </Link>
        </div>
      </Col>



      {isGod &&
        <>
          <Col span={12}>
            <div className="admin-tile">
              <Link to='admin/securityConfig'>
                <div className="admin-tile-icon bg-gradient-dark">     <CrownOutlined /></div>
                <div className="admin-tile-text"> {t('Security Configuration')}</div>
                <div className="admin-tile-number">     <CrownOutlined /></div>
              </Link>
            </div>
          </Col>
          {identifyAsEnabled && <Col span={12}>
            <IdentityAs />
        </Col>}
        <Col span={12}>
          <div className="admin-tile">
            <Link to='admin/control-pats'>
              <div className="admin-tile-icon bg-gradient-dark">     <LaptopOutlined /></div>
              <div className="admin-tile-text"> {t('PATS Control')}</div>
              <div className="admin-tile-number">    <LaptopOutlined /></div>
            </Link>
          </div>
        </Col>
        </>
      }
    </Row>
  </CustomLoad>

}
export default withTranslation()(withIdentity(HomePanel)) as any
