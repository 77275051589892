import * as React from 'react'
import { Row, Col, Button } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';

import logo from '../../assets/images/404/logo.png';
import imagen from '../../assets/images/404/back.png';

interface Page404Props extends WithTranslation {

}

class Page404 extends React.Component<Page404Props, {image:any}>{

    constructor(props: Page404Props) {
        super(props);
    }

    render() {
        const { t } = this.props;

        return (
            <div style={{ width: "100%", height: "100vh", backgroundColor: "#0E0E4F", color: "white", position: "relative" }}>
                <div style={{ padding: "20px", backgroundColor: "#0E0E4F", height: "5%" }}>
                    <img src={logo} alt="Logo" style={{ width: "100px" }} />
                </div>

                <div style={{ textAlign: "center", backgroundColor:"#1E1E5D", marginTop: "40px", height: "35%", width: "100%" }}>
                    <Row>
                        <Col span="12">
                            <h1 style={{ fontSize: "4rem", color:"white", marginTop:"70px" }}>{"OUPS ... IL Y A ERREUR"}</h1>
                        </Col>
                        <Col span="12">
                            <img src={imagen} alt="Illustration" style={{ width: "560px", height: "125%", float: "right", marginRight: "20px" }} />
                        </Col>
                    </Row>
                </div>

                <div style={{ backgroundColor: "white", color: "#1E1E5D", padding: "20px", height: "45%" }}>
                    <p style={{ fontSize: "1.5rem", lineHeight: "1.5", marginTop: "90px", marginLeft: "15%" }}>
                        Nous sommes désolés, la page que vous avez demandée n’existe pas ou a été supprimée.<br />
                        Nous vous invitons à retourner à la page d’accueil.
                    </p>
                    <Button
                        type="primary"
                        size="large"
                        style={{ backgroundColor: "#0E0E4F", borderColor: "#0E0E4F", marginTop: "10px", marginLeft: "15%", borderRadius:"10px" }}
                        href="/"
                    >
                        Page d’accueil
                    </Button>
                </div>
            </div>
        );

    }
   }
    
export default withTranslation()(Page404);