import DataStore from '../../core/stores/data-store'

export enum LoginResult {
  Success = 0,
  Failure,
  UserLocked,
  FullUserLocked,
  Unlocked
}

export enum AuthenticationProvider {
  Local = 0,
  Microsoft
}

export interface AuthLogsSummary  {
  id: string,
  userId: string,
  userName: string,
  email: string,
  reason: string,
  date: Date,
  result: LoginResult,
  provider: AuthenticationProvider,
  returnUrl: string,
  metaData : any
}

export class AuthLogsStore extends DataStore<AuthLogsSummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/authenticationlogs`, []);
  }
}

export enum OperationType {
    Permission = 0,
    BulkInsert = 1,
    PolarisPermissionCreate = 2,
    ApproveOperationRequest = 3

}

export interface SystemLogSummary  {
  id: string,
  userId: string,
  userName: string,
  tenantId: string,
  tenantName: string,
  email: string,
  operation: OperationType,
  date: Date,
  metaData : any
}

export class SystemLogStore extends DataStore<SystemLogSummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/systemlog`, []);
  }
}

export interface SessionLogSummary {
    id: string,
    userId: string,
    userName: string,
    roles: string[],
    applicationName: string,
    date: Date,
}

export class SessionLogStore extends DataStore<SessionLogSummary> {
    constructor(baseUrl: string) {
        super(`${baseUrl}/api/v1/admin/sessionlog`, []);
    }
}

export enum ApiEntryType {
    PermissionController_GetRoles = 0,
    PermissionController_RemoveRolesInTenant = 1,
    PermissionController_GetUserWithRole = 2,
    PermissionController_InviteUsers = 3,
    PermissionController_InviteUsersWithOperationRequest = 4,
    PermissionController_LockUser = 5,
    TenantsController_GetEntities = 6
}

export interface ExternalApiAccessLogSummary {
    id: string,
    ip: string,
    userId: string,
    userName: string,
    apiEntry: string,
    metaData: string,
    date: Date,
}

export class ExternalApiAccessLogStore extends DataStore<ExternalApiAccessLogSummary> {
    constructor(baseUrl: string) {
        super(`${baseUrl}/api/v1/admin/externalApiAccessLog`, []);
    }
}
