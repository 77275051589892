import * as React from 'react'
import { Modal, Button, Tooltip, Form, Input, Row, Col, Radio, RadioChangeEvent, Alert, Spin, Select, FormInstance } from 'antd'
import autobind from 'autobind-decorator'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { CopyOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons'
import FormItem from 'antd/es/form/FormItem'
import { nameof } from '../../../../../../core/utils/object'
import LangEditor from 'src/components/langEdit'
import { IdentityType } from '../../../../../../stores/tenants/common'
import GroupEdit from '../../../../../../components/groupEdit'
import UserEdit from '../../../../../../components/userEdit'
import TenantEdit from '../../../../../../components/tenantEdit'
import { NewUserItem, UserItem, UserItemStore } from '../../../../../../stores/users/users-store'
import AreaEditor from 'src/components/areaEdit'
import { container } from '../../../../../../inversify.config'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import FloatSelect from '../../../../../../core/ui/awesome/floatComponent/select'
import FloatInput from '../../../../../../core/ui/awesome/floatComponent/input'

interface SelectIdentityTenantViewProps {
  onChangeIdentityId: (id) => void
  selectedUserId?: string
  form: FormInstance<UserItem>
}

interface SelectIdentityTenantItem {
  firstName: string,
  lastName: string,
  email: string,
  language: string,
  areaId: string,
  manager?: string,
}

const SelectIdentityTenantView: React.FC<SelectIdentityTenantViewProps & WithTranslation> = (props) => {
  const { t, form } = props
  const [creationMode, setCreationMode] = useState(props.selectedUserId == undefined)
  const userStore = React.useMemo(() => container.get(UserItemStore), [])
  const userStoreState = userStore.state
  const [newUser, setNewUser] = useState(true)
  const [areaDefault, setAreaDefault] = useState(false)

  const inputRef = React.useRef()
  const [initialValues, setInitialValues] = useState<UserItem | undefined>(undefined)
  const [init, setInit] = useState(false)
  useEffect(() => {
    if (props.selectedUserId) {
      load(props.selectedUserId)
    }
    else {
      setInit(true)
    }
  }, [inputRef]) // eslint-disable-line react-hooks/exhaustive-deps

  const load = async (userId, fillForm = false) => {
    await userStore.load(userId)
    setAreaDefault(userStoreState.item.get().areaName == "Customers")
    if (fillForm) {
      form.resetFields()
      form.setFieldsValue(userStoreState.item.get())
    }
    else {
      setInitialValues(userStoreState.item.get())
    }
    setInit(true)
  }





  return (<>
    {userStoreState.errorMessage.get() && <Alert type="error" message={t(userStoreState.errorMessage.value || '')} />}
    <Spin spinning={userStoreState.isBusy.get() || !init}>
      {init && <Row gutter={24}>
        <Col span={24}>
          <Form form={form} autoComplete="off" layout="vertical" initialValues={initialValues}>
            {creationMode &&

              (
                <div style={{ display: "inline", marginBottom: 10 }}>
                  <Checkbox style={{ marginBottom: "10px" }} onChange={(value) => { setNewUser(value.target.checked); props.onChangeIdentityId(undefined); form.resetFields() }} checked={newUser}>{t("New User")}</Checkbox>
                  {newUser && <FormItem name={nameof<UserItem>('userName')} rules={[{ required: true, message: t('Please input your username!') }]}>
                    <FloatInput label={t("Username")} readOnly={props.selectedUserId != undefined} placeholder={t("Username")} />
                  </FormItem>}
                  {!newUser &&
                    <UserEdit label={t("Username")} placeholder={t("Select User")} valueAsItemReference onChange={(value) => { load(value.id, true); props.onChangeIdentityId(value.id) }} {...props} />}
                </div>
              )
            }
            {!creationMode &&
              <FormItem name={nameof<UserItem>('userName')} rules={[{ required: true, message: t('Please input your username!') }]}>
                <FloatInput label={t("Username")} required readOnly={props.selectedUserId != undefined} placeholder={t("Username")} />
              </FormItem>}
            <Row gutter={10} >
              <Col span={12}>
                <FormItem name={nameof<UserItem>('areaId')} rules={[{ required: true, message: t('Area required') }]}>
                  <AreaEditor label={t("Area")} required onChange={(area) => { setAreaDefault(area.default) }} valueAsItemReference />
                </FormItem>
                <FormItem name={nameof<UserItem>('firstName')} rules={[{ required: true, message: t('Please input your first name!') }]}>
                  <FloatInput required label={t("First name")} placeholder={t("First name")} />
                </FormItem>
                <FormItem name={nameof<UserItem>('jobTitle')} rules={[]}>
                  <FloatInput label={t("Work Position")} required readOnly={!areaDefault} placeholder={t("Work Position")} />
                </FormItem>
                <FormItem name={nameof<UserItem>('language')} rules={[{ required: true, message: t('Please select your preferred language!') }]}>
                  <LangEditor label={t("Preferred language")} required placeholder={t("Preferred language")} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem name={nameof<UserItem>('email')} rules={[
                  { required: true, message: t('Please input your email!') },
                  { type: 'email', message: t('Invalid email address.') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!creationMode) return Promise.resolve()
                      let email: string = value
                      if (email.toLowerCase().indexOf("yahoo") >= 0 || email.toLowerCase().indexOf("gmail") >= 0 || email.toLowerCase().indexOf("hotmail") >= 0)
                        return Promise.reject(new Error(t('Invalid email address.')))
                      return Promise.resolve()
                    },
                  }),
                ]}>
                  <FloatInput label={t("Email")} required placeholder={t("Email")} />
                </FormItem>
                <FormItem name={nameof<UserItem>('lastName')} rules={[{ required: true, message: t('Please input your last name!') }]}>
                  <FloatInput required label={t("Last name")} placeholder={t("Last name")} />
                </FormItem>
                <FormItem name={nameof<UserItem>('manager')} rules={[]}>
                  <FloatInput label={t("Manager")} readOnly={!areaDefault} placeholder={t("Manager")} />
                </FormItem>
                <FormItem name={nameof<UserItem>('phoneNumber')} rules={[]}>
                  <FloatInput label={t("Phone Number")} placeholder={t("Phone Number")} />
                </FormItem>
              </Col>
            </Row>



            <FormItem name={nameof<UserItem>('teams')} rules={[]}>
              <FloatSelect label={t("Departments")} mode="tags" disabled={!areaDefault} placeholder={t("Departments")} />
            </FormItem>

          </Form>
        </Col>
      </Row>}
    </Spin >
  </>)
}

export default (withTranslation()(SelectIdentityTenantView) as any) as React.ComponentClass<SelectIdentityTenantViewProps>
