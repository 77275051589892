import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Form, Row, Layout, Card } from 'antd'
import { withRouter, RouteComponentProps } from "react-router"
import { BaseAppUrl } from '../../inversify.config'
const { Content, Footer } = Layout

export interface AccessDeniedProps extends WithTranslation, RouteComponentProps {
  children?: React.ReactNode
}


const AccessDeniedView: React.FC<AccessDeniedProps> = (props) => {
  const { t } = props
  return (
    <Layout style={{ height: "100vh" }} className="loginLayout">
      <Content className="contentLayout">
        <Row justify="space-around" align="middle">
          <Card className="loginCard password" >
            <h1>{t("Access is denied")}</h1>
            <p>
              {t("You dont have access to this resource. You might not have the required permissions. Please, contact your administrator for more information.")}
            </p>
            <p><br /></p>
            <p style={{ textAlign: 'right' }}>
              <a style={{ marginRight: 10 }} className="ant-btn ant-btn-primary" href="/">Home</a>
              <a className="ant-btn" href={`${BaseAppUrl}/api/v1/account/logout`}>{t('Close session')}</a>
            </p>
          </Card>
        </Row>
      </Content>
    </Layout>
  )
}

export default withTranslation()(withRouter(AccessDeniedView) as any) as any