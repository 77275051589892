import React, { FC } from 'react';
import { TenantHierarchyItem } from 'src/stores/tenants/tenants-store';
import { Tag } from 'antd';
import { ApartmentOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';

const EntityHierarchyPath: FC<{ hierarchyItems: TenantHierarchyItem[]; onClick?: (item: TenantHierarchyItem) => void } & WithTranslation> = (props) => {
  const sorted = (props.hierarchyItems || []).orderBy((x) => x.order).toArray<TenantHierarchyItem>();
  // .slice(1);
  return (
    <div className="entity-hierarchy-component-container" style={{ display: 'flex', alignItems: 'center', flexFlow: 'wrap' }}>
      {sorted.map((e, idx) => (
          <React.Fragment key={e.id}>
              <Tag  icon={ < ApartmentOutlined /> }  
                  style={{ marginRight: 2, marginLeft: 2, cursor: props.onClick ? 'pointer' : 'default', color:  "seagreen" }}
            onClick={() => props.onClick && props.onClick(e)}
          >
                  <span>{!e.title || e.title.length == 0 ? props.t("(Empty Name)") : e.title}</span>
          </Tag>
          {idx !== sorted.length - 1 && (
            <span className="hierarchy-separator" style={{ margin: '5px 2px', color: 'lightgrey' }}>
              &gt;
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default withTranslation()(EntityHierarchyPath);

