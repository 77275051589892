import React, { useEffect, FC, useState } from 'react'
import { container } from 'src/inversify.config'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withIdentity, IdentityProps } from 'src/core/services/authentication'
import { TenantSummary, TenantSummaryStore } from 'src/stores/tenants/tenants-store'
import { TableModel, TableView, getDefaultTableSettings } from 'src/core/ui/collections/table'
import { Row, Card, Col, Tag, Select, Tooltip, Button, Checkbox, Drawer } from 'antd'
import { formatMessage } from 'src/core/services/http.service'
import { Query } from 'src/core/stores/data-store'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import ContentHeader from 'src/core/ui/content-header'
import { IdentityType } from 'src/stores/tenants/common'
import { ApartmentOutlined, DeploymentUnitOutlined, DownloadOutlined, EditOutlined, GoldOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons'
import AreaSelect from 'src/components/areaEdit'
import { CacheProps, withCache } from 'src/core/services/cache.service'
import { successNotification } from '../../../components/systemNotification/notificationService'
import Popconfirm from 'antd/es/popconfirm'

const { Option } = Select

interface EntityListPageProps extends WithTranslation, IdentityProps, CacheProps, RouteComponentProps { }

const EntityListPage: FC<EntityListPageProps> = (props) => {
  const [IsAdmin] = useState(props.identity.roles?.any(r => r === "Administrator"))
  const [IsUser] = useState(props.identity.roles?.any(r => r === "User"))
  const [IsManager] = useState(props.identity.roles?.any(r => r === "Manager"))
  const [areaFilter, setAreaFilter] = useState<any>()
  const [creationFilter, setCreationFilter] = useState<any>()
  const [initPagination, setInitPagination] = useState(false)
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
    skip: 0,
    take: getDefaultTableSettings().defaultPageSize,
    parameters: {
      includeHierarchy: 'true',
    },
    odataObject: {
      filter: {}
    },
  } as Query)


  const [newEntityVisibility, setNewEntityVisibility] = useState(false)

  const [indeterminate, setIndeterminate] = useState(0)
  const [showHierarchy, setShowHierarchy] = useState(false)
  const [selectedNode, setSelectedNode] = useState<any>()


  const entitiesStore = container.get<TenantSummaryStore>(TenantSummaryStore)
  const entityState = entitiesStore.state
  const t = props.t

  const getisUserCustomizedValue = (value) => {
    if (value % 3 == 0) return undefined
    if (value % 3 == 1) return true
    else return false
  }
  useEffect(() => {

    var queryStored = props.cache.getWithCustomKey(entitiesStore.baseUrl)
    if (queryStored && queryStored.query)
      setQuery(queryStored.query)
    if (queryStored && queryStored.area)
      setAreaFilter(queryStored.area.id)
    if (queryStored && queryStored.type)
      setCreationFilter(queryStored.type)
    if (queryStored && queryStored.isUserCustomized) {
      var current = 0
      if (queryStored.isUserCustomized % 3 == 0)
        setIndeterminate(0)
      else
        if (queryStored.isUserCustomized % 3 == 1)
          setIndeterminate(1)
        else setIndeterminate(2)

    }
    entitiesStore.load(Build(queryStored?.query || query, queryStored?.area || areaFilter, (queryStored?.type) || creationFilter, queryStored?.isUserCustomized || getisUserCustomizedValue(indeterminate)))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const Build = (query: Query, area: any, type: any, isUserCustomized: any): Query => {
    if (area) {
      var filter = {
        areaId: { eq: { type: 'guid', value: area.id } }
      }
      query.odataObject.filter.area = filter
    }
    else {
      query.odataObject.filter.area = undefined
    }
    if (isUserCustomized) {
      var cfilter = {
        isUserCustomized: { eq: isUserCustomized }
      }
      query.odataObject.filter.isUserCustomized = cfilter
    }
    else {
      query.odataObject.filter.isUserCustomized = undefined
    }
    if (type) {
      query.parameters.creation = type
    }
    else {
      query.parameters.creation = undefined
    }
    props.cache.saveWithCustomKey(entitiesStore.baseUrl, { query: query, area: area, type: type, isUserCustomized: isUserCustomized })
    return query
  }

  const onAreaFilterChange = (area: any) => {
    setInitPagination(!initPagination)
    setAreaFilter(area)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
    entitiesStore.load(Build(newquery, area, creationFilter, getisUserCustomizedValue(indeterminate)))
  }

  const onCreationFilterChange = (type: any) => {
    setInitPagination(!initPagination)
    setCreationFilter(type)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
    entitiesStore.load(Build(newquery, areaFilter, type, getisUserCustomizedValue(indeterminate)))
  }

  const onDownloadExcel = async () => {
    await entitiesStore.export(Build(query, areaFilter, creationFilter, getisUserCustomizedValue(indeterminate)))
    successNotification(t('Operation Completed Successfully'), t('Your operation will be processed in background.'))
  }
  const onChangeisUserCustomized = (value) => {
    var newquery = query
    entitiesStore.load(Build(newquery, areaFilter, creationFilter, getisUserCustomizedValue(indeterminate + 1)))
    setIndeterminate(indeterminate + 1)
  }

  const tableModel = {
    query: query,
    columns: [
      {
        sortable: true,
        searchable: true,
        field: 'title',
        title: t('Title'),
        renderer: (data: TenantSummary) => (
          <span>
            <Button style={{ marginRight: 10, cursor: 'default' }} onClick={() => { setSelectedNode(data.id); setShowHierarchy(true) }} type="primary" icon={< ApartmentOutlined />} />
            <span>{!data.title || data.title.length == 0 ? t("(Empty Name)") : data.title}</span>
          </span>
        ),
      },

      {
        searchable: true,
        field: 'taxNumber',
        title: t('CIF'),
        renderer: (data: TenantSummary) => <span style={{ fontSize: "10px", fontWeight: "bold", cursor: "text" }}>{data.taxNumber}</span>
      },
      {
        field: 'area',
        title: t('Area'),
        renderer: (data: TenantSummary) => <span style={{ fontSize: "10px", fontWeight: "bold", cursor: "text" }}>{data.areaName}</span>
      },
      {
        field: 'requiredTwoModeAuthentification',
        title: t('Enabled'),
        renderer: (data: TenantSummary) => <div style={{ textAlign: 'center', width: "100%" }}> <span style={{ fontSize: "10px", fontWeight: "bold", textAlign: "center", cursor: "pointer" }}> <Popconfirm onConfirm={async () => { await entitiesStore.EditTwoAuthTenantMode(data.id, !data.requiredTwoModeAuthentification); entitiesStore.load(query) }} title={data.requiredTwoModeAuthentification ? t("Are you sure to disable the MFA factor?") : t("Are you sure to enable the MFA factor?")}><span>{data.requiredTwoModeAuthentification ? <Tag icon={<EditOutlined />} color="green">{t("Yes")}</Tag> : <Tag icon={<EditOutlined />} color="red">{t("No")}</Tag>}</span></Popconfirm> {data.requiredTwoModeAuthentification}</span></div>
      },


    ],
    data: entityState.value,
    sortFields: [],
  } as TableModel<TenantSummary>

  var rightToolbar = (
    <React.Fragment>

      <li>
        <AreaSelect minWidth={200} value={areaFilter} placeholder={t("Select Area...")} valueAsItemReference onChange={(value) => onAreaFilterChange(value)} />
      </li>

    </React.Fragment>
  )

  return (
    <div>
      <Row gutter={0}>
        <Col span={16}>
          <ContentHeader title={t('MFA Configuration')} />
        </Col>
        <Col span={8} />
      </Row>
      <Row align="top" justify="space-between">
        <Card style={{ width: '100%' }}>
          <TableView
            enableTableSettings
            rowKey="id"
            canSelect={false}
            rightToolbar={rightToolbar}
            initPagination={initPagination}
            onQueryChanged={(query: Query) => {
              setQuery(query)
              entitiesStore.load(Build(query, areaFilter, creationFilter, getisUserCustomizedValue(indeterminate)))
            }}
            onRefresh={() => entitiesStore.load(query)}
            model={tableModel}
            error={entityState.errorMessage.value && formatMessage(entityState.errorMessage.value)}
          />

        </Card>
      </Row>

    </div>
  )
}

export default withCache(withIdentity(withTranslation()(withRouter(EntityListPage))))
