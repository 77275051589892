import { FC, useState, useMemo, useEffect } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { UserGroupsStore, UserGroupSummary } from 'src/stores/users/usersgroups-store'
import { ItemState, TableModel, TableView } from 'src/core/ui/collections/table'
import NewUserGroupView from './usergroups-new'
import { container } from 'src/inversify.config'
import { formatMessage } from 'src/core/services/http.service'
import { Query } from 'src/core/stores/data-store'
import { clone } from '../../../../core/utils/object'

// tslint:disable-next-line:no-empty-interface
interface UserGroupsViewProps extends RouteComponentProps, WithTranslation {
  id: string
}

const UserGroups: FC<UserGroupsViewProps> = (props) => {
  const { t } = props
  const usersGroupsStore = useMemo(() => container.get(UserGroupsStore), []) as UserGroupsStore
  const usersGroupsStoreState = usersGroupsStore.state
  const [query, setQuery] = useState({ searchQuery: '', orderBy: [{ field: 'groupName', direction: 'Ascending', useProfile: true }], skip: 0, take: 10} as Query)
  useEffect(() => {
    load(query)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps
  const [newUserGroupShown, setNewUserGroupShown] = useState(false)

  const load = (query: Query) => {
    usersGroupsStore.changeUser(props.id)
    usersGroupsStore.load(query)
  }

  const onDeleteRow = async (item: UserGroupSummary) => {
    var result = await usersGroupsStore.delete(item.groupId)
    load(query)
    return result
  }

  const onNewItemClosed = () => {
    setNewUserGroupShown(false)
    load(query)
  }

  const tableModel = {
    query: query,
    columns: [
      {
        sortable: false,
        field: 'groupName',
        title: t('Group'),
        renderer: (data) => <span>{data.groupName}</span>
      }
    ],
    data: usersGroupsStoreState.value,
    sortFields: [{
      field: 'groupName', text: t('Group'), useProfile: true
    }],
  } as TableModel<UserGroupSummary>

  return (
    <div>
      <TableView
        showDeleteStatusWindow
        rowKey={"groupId"}
        title={""}
        error={usersGroupsStoreState.errorMessage.value && formatMessage(usersGroupsStoreState.errorMessage.value)}
        model={tableModel}
        onQueryChanged={(q: Query) => setQuery(q)}
        onNewItem={() => setNewUserGroupShown(true)}
        onRefresh={() => setQuery(clone(query))}
        canEdit={false}
        canDelete={true}
        onDeleteRow={(item) => onDeleteRow(item)}
        canCreateNew={true}>
      </TableView>
      {
        newUserGroupShown && <NewUserGroupView onClose={onNewItemClosed} {...props} />
      }
    </div>
  )
}
// Wire up the React component to the Redux store
export default withTranslation()(withRouter(UserGroups))
