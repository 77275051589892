import * as React from 'react'
import { Form, Spin, Input, Modal, Row, Col, Alert, Checkbox, Select } from 'antd'
let FormItem = Form.Item
let TextArea = Input.TextArea
import autobind from 'autobind-decorator'
import { GroupUserItemStore, NewGroupUserItem, GroupPermission } from 'src/stores/groups/groupsusers-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import UserEditor from 'src/components/userEdit'
import { nameof } from '../../../../core/utils/object'
import { container } from '../../../../inversify.config'
import { useState } from 'react'

interface NewGroupUserViewProps {
  id: string,
  onClose: () => void
}


const NewGroupUserView: React.FC<NewGroupUserViewProps & WithTranslation> = (props) => {

  const [form] = Form.useForm<NewGroupUserItem>()
  const [initialValues, setInitialValues] = React.useState<NewGroupUserItem | undefined>({ permission: GroupPermission.Reader })
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const currentStore = React.useMemo(() => container.get(GroupUserItemStore), [])
  const currentState = currentStore.state



  const onCancel = () => {
    props.onClose()
  }

  const onCreate = async () => {
    setConfirmLoading(true)
    let item: any
    try {
      item = (await form.validateFields()) as NewGroupUserItem
    } catch {
      setConfirmLoading(false)
      return
    }
    let data: NewGroupUserItem = {
      permission: item.permission,
      groupId: props.id,
      userId: item.userId.id
    }
    currentStore.changeGroup(props.id)
    let response = await currentStore.create(data)
    if (response) {
      form.resetFields()
      props.onClose()
    }
    setConfirmLoading(false)
  }

  const { t } = props
  return (
    <Modal
      visible
      onCancel={() => onCancel()}
      confirmLoading={confirmLoading}
      onOk={() => onCreate()}
      closable={false}
      title={t("New role's scope")}
      cancelText={t('Cancel')}
      okText={t('Ok')}
    >
      {currentState.errorMessage.get() && <Alert type="error" message={t(currentState.errorMessage.value || '')} />}
      <Spin spinning={currentState.isBusy.get()}>
        <Form id="modaForm" layout="vertical" form={form} initialValues={initialValues}>
          <FormItem label={t("User")}
            name={nameof<NewGroupUserItem>('userId')}
            rules={[{ required: true, message: t('User required') }]}>
              <UserEditor valueAsItemReference />
          </FormItem>
          <FormItem label={t("Is Owner")}
            name={nameof<NewGroupUserItem>('permission')}
            rules={[{ required: true, message: t('Permission required') }]}>
           
              <Select style={{ width: 150 }}>
                <Select.Option value={GroupPermission.Reader}>{t("No")}</Select.Option>
                <Select.Option value={GroupPermission.Owner}>{t("Yes")}</Select.Option>
              </Select >
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  )
}

// Wire up the React component to the Redux store
export default withTranslation()(NewGroupUserView) 


