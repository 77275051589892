import { Query } from 'src/core/stores/data-store';
import qs from 'query-string';
import buildQuery from 'odata-query';
import { getProperties } from 'src/core/utils/object';

export function buildUrl(query: Query) {
  const parts = []
  if (query.searchQuery) {
    parts.push(`$search=${query.searchQuery}`)
  }

  var oDataQuery = {
    skip: query.skip || 0,
    top: query.take || 10
  } as any

  if (query.orderBy && query.orderBy.length > 0) {
    var sortProfile = query.orderBy.filter((o) => o.useProfile)
    if (sortProfile.length > 0) {
      parts.push(`sortProfile=${sortProfile[0].field} ${sortProfile[0].direction}`)
    } else {
      var order = []
      for (var i = 0; i < query.orderBy.length; i++) {
        let direction = query.orderBy[i].direction === 'Ascending' ? 'asc' : 'desc'
        order.push(`${query.orderBy[i].field} ${direction}`)
      }
      oDataQuery['orderBy'] = order
    }
  }

  if (query.filter) {
    oDataQuery['filter'] = query.filter
  }

  parts.push(buildQuery(oDataQuery).substr(1))

  if (query.parameters) {
    for (var prop in query.parameters as any) {
      if (query.parameters[prop] && query.parameters[prop]!.constructor === Array) {
        for (var idx = 0; idx < (query.parameters[prop] as any)!.length; idx++) {
          if ((query.parameters[prop] as any)![idx]) parts.push(`${prop}=${encodeURIComponent((query.parameters[prop] as any)![idx])}`)
        }
      } else {
        if (query.parameters[prop]) parts.push(`${prop}=${encodeURIComponent(query.parameters[prop] as string)}`)
      }
    }
  }

  const usualResult = parts.join('&')
  let final = usualResult

  if (query.odataObject != null) {
    const f = query.odataObject.filter
    let queryObj = { ...query.odataObject, filter: [] }
    if (f != null)
      queryObj.filter = getProperties(f)
        .map((x) => x.value)
        .filter((x) => x != null)

    const newResult = buildQuery(queryObj)
    final = merge(usualResult, newResult).substr(1)
  }

  return final
}

export function merge  (odataUri1: string, odataUri2: string) {
  const parts1 = qs.parse(odataUri1, { decode: false })
  const split = odataUri2.split('?')
  const path = split[0]
  const parts2 = qs.parse(split[1], { decode: false })

  const filters = [parts1['$filter'], parts2['$filter']].filter((x) => (x || '').length !== 0)
  let filter = null
  if (filters.length === 1) filter = filters[0]
  else if (filters.length === 2) filter = `(${filters[0]})%20and%20(${filters[1]})`

  const selects = [parts1['$select'], parts2['$select']].filter((x: any) => (x || '').length !== 0).map((x: any) => x.split(','))
  let select = null
  if (selects.length !== 0) {
    const set = new Set<string>()
    selects.forEach((arr) =>
      arr.forEach((s) => {
        if (!set.has(s)) set.add(s)
      })
    )
    select = [...(set as any)].join(',')
  }

  // TODO: Merge here more sections

  const final = Object.assign({}, parts1, parts2, { $select: select, $filter: filter })

  if (select == null) delete final['$select']
  if (filter == null) delete final['$filter']

  return `${path}?${getProperties(final)
    .filter((x) => x.value != null)
    .map((x) => `${x.key}=${x.value}`)
    .join('&')}`
}