import React, { FC, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withIdentity, IdentityProps } from 'src/core/services/authentication'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Row, Col, Card, Tabs, Button } from 'antd'
import ContentHeader from 'src/core/ui/content-header'
import { container } from 'src/inversify.config'
import UserPages from './tenant-identities'
import GroupsPage from './tenant-groups'
import { CacheProps, withCache } from 'src/core/services/cache.service';
const { TabPane } = Tabs

interface AdminPageProps extends WithTranslation, CacheProps , IdentityProps, RouteComponentProps<{}> { }

enum AdminTabs {
    General = 'Users',
    Members = 'Groups',
}

const AdminPage: FC<AdminPageProps> = (props) => {
    const { t, cache } = props
    const [tabActive, setTabActive] = useState<string | undefined>(cache.get());
    useEffect(() => {
        if (!props.identity.roles || props.identity.roles.filter((o) => o.includes('Administrator') || o.includes('Manager')).length == 0) {
            props.history.push('/')
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Row gutter={0}>
                <Col span={16}>
                    <ContentHeader showBack  title={t('Permissions')} />
                </Col>
          <Col span={8}>
         
          </Col>
            </Row>
            <Row gutter={16} align="top" justify="space-between">
                <Col span={24}>
                    <div className={"ibox"} style={{ width: '100%', padding: "0px 20px", background: "#fff"}}>
                        {<Tabs defaultActiveKey={tabActive} onChange={(activeKey) => cache.save(activeKey)}>
                            <TabPane tab={t("Users")} key="users">
                                <UserPages isAdmin={props.identity.roles && props.identity.roles.filter((o) => o.includes('Administrator')).length != 0} />
                            </TabPane>
                            <TabPane tab={t("Groups")} key="groups">
                                <GroupsPage isAdmin={props.identity.roles && props.identity.roles.filter((o) => o.includes('Administrator')).length != 0} />
                            </TabPane>
                        </Tabs>}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default withCache( withRouter(withIdentity(withTranslation()(AdminPage))))
