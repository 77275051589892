import { Avatar, Divider, Layout, Row, Card, Tabs, Spin, Col, Tag } from 'antd'
const { Content } = Layout
const TabPane = Tabs.TabPane
import { withTranslation, WithTranslation } from 'react-i18next'
import LoginLocalView from './login-local'
import FooterView from "src/components/footer"
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { FC, useEffect, useState } from 'react'
import whiteLogo from 'src/assets/logo-connect-bk.png'
import src from 'src/core/assets/images/helpDesk.png'
import footerLogo from 'src/assets/logo-simple.png'
import LanguageBox from 'src/components/language-box'
import LoginButton from './login-button'
import CguContentView from 'src/components/cguContentViewer'
import "./login.scss"
import { AppEnvironment } from '../../inversify.config'
import { env } from 'process'
import backImage from './../../assets/Desktop.jpg'

const login_links = "https://figroup.freshworks.com/login/auth/contacts?client_id=78368727158800390&redirect_uri=https%3A%2F%2Ffigroup.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback"
interface LoginViewProps extends WithTranslation {

}

const LoginView: FC<LoginViewProps> = (props) => {
  const { t } = props
  const [localLogin, setLocalLogin] = useState(false)
  const [showCgu, setShowCgu] = useState(false)
  const [loginSuccess, setLoginSuccess] = useState(false)
  const handleLoginOnChange = isLogged => {
    setLoginSuccess(isLogged)
  }
  const environmet = AppEnvironment

  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [move, setMove] = useState({ x: 0, y: 0 })



  const handleMouseMove = (event) => {
    var moveinX = (event.pageX * -2 / 100)
    var moveinY = (event.pageY * -2 / 100)
    setMove({ x: moveinX, y: moveinY })
    setPosition({ x: event.pageX, y: event.pageY })
  }

  return <Layout onMouseMove={handleMouseMove}
    style={
      {
        height: "auto",
        backgroundSize: "110% 110%",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" + backImage + ")",
        backgroundPosition: move.x + 'px ' + move.y + 'px '
      }
    }
    className={"login-layout " + environmet}>
    <Content className="contentLayout">
      <Row gutter={16} align="middle">
        <Col lg={12} sm={24} xs={24} className='intro-col'>
          <Row>
            <img src={whiteLogo} height={80} title="logo" />
                      <p>{t("Inno.Connect est une plateforme de solutions digitales créée par F.initiatives pour fluidifier les échanges, simplifier nos relations et améliorer la performance de nos missions pour nos clients.")}</p>
            <LanguageBox availableLanguages={['en', 'fr']} />
          </Row>
        </Col>

        <Col lg={12} sm={24} xs={24} className='login-col'>
                  <Card className='card-login'>
                      <span>Connexion à Inno.Connect</span>
            <Row>
              <Tabs renderTabBar={() => <></>} activeKey={localLogin ? "localLogin" : "login"} style={{ width: '100%' }}>
                <TabPane tab="login" key='login'>
                  <LoginButton provider='Local' onLocalLogin={() => setLocalLogin(true)} primary />
                  <Divider>{t('Or with the following providers')}</Divider>
                  <LoginButton provider='Microsoft' />
                </TabPane>

                <TabPane tab="localLogin" key='localLogin'>
                  <h4 className='title-local'>{t('Local user')}</h4>
                  <LoginLocalView loginOnChange={handleLoginOnChange} />
                  {
                    !loginSuccess &&
                    <p className="get-back-login clickable" onClick={() => setLocalLogin(false)}>
                      <LeftOutlined className='clickable' /> {t('Select another authentication method')}
                    </p>
                  }
                </TabPane>
              </Tabs>
            </Row>
            {
              !loginSuccess ?
                <Row className='login-links'>
                  <a target="_blank" href={login_links}>
                    <Avatar src={src} />
                    {props.t("Problems to access? Contact Us!")}
                  </a>
                  <a onClick={() => setShowCgu(true)}>{t('Terms of service')}</a>
                </Row>
                :
                <Row className="login-loader">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />{' '}
                  <span>{t('Logging in')}</span>
                </Row>
            }
          </Card>
        </Col>
      </Row>
    </Content>
    <FooterView footerLogo={footerLogo} />
    {showCgu && <CguContentView onClose={() => setShowCgu(false)} />}
  </Layout>
}

export default withTranslation()(LoginView)