import DataStore, { Query, QueryResult } from 'src/core/stores/data-store'
import FormStore from 'src/core/stores/form-store'
import { stringify } from 'query-string'



export interface TenantHierarchyItem {
  order: number
  id: string
  title: string
}

export interface TenantItem {
  id: string
  title: string
  areaId: string
  areaName : string
  hierarchyItems: TenantHierarchyItem[]
  hierarchy: string
  taxNumber: string
  viesNumber: string
  groupAlias : string
}

export interface TenantSummary {
  id: string
  title: string
  areaName: string
  areaId: string
  hierarchyItems: TenantHierarchyItem[]
  hierarchy: string
  taxNumber: string
  viesNumber: string
  auto: boolean
  groupAlias: string,
  requiredTwoModeAuthentification: boolean
}



export interface CreateTenant {
  title: string
  areaId: string
  parentId?: string | null
  taxNumber: string
  viesNumber: string
}


export interface EditTenant {
  title: string
  areaId: string
  taxNumber: string
  viesNumber: string
  parentId?: string | undefined
}

export class TenantSummaryForSelectStore extends DataStore<TenantSummary> {
  private apiUrl: string;
  private apiUrlProfile: string;
  constructor(baseUrl: string) {
    super(baseUrl, [])
    this.apiUrl = `${baseUrl}/api/v1/admin/tenants`;
    this.apiUrlProfile = `${baseUrl}/api/v1/profile/tenants`;
  }

  public async getItems(query: Partial<Query>, fromProfile: boolean = false) {
    if (fromProfile){
      const response = await this.httpService.get<QueryResult<TenantSummary>>(`${this.apiUrlProfile}?${DataStore.buildUrl(query as Query)}`)
      return response.data.items || []
    }
    else{
      const response = await this.httpService.get<QueryResult<TenantSummary>>(`${this.apiUrl}?${DataStore.buildUrl(query as Query)}`)
      return response.data.items || []
    }
    
  }
}


export class AssignedToStore extends DataStore<TenantSummary> {
  private apiUrlProfile: string
  constructor(baseUrl: string) {
    super(baseUrl, [])
    this.apiUrlProfile = `${baseUrl}/api/v1/profile/assignedTo`
  }

  public async getItems(query: Partial<Query>) {
   
    const response = await this.httpService.get<QueryResult<TenantSummary>>(`${this.apiUrlProfile}?${DataStore.buildUrl(query as Query)}`)
      return response.data.items || []

  }
}

export class TenantSummaryStore extends DataStore<TenantSummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/tenants`, [])
  }

  public async getItems(query: Partial<Query>) {
    const response = await this.httpService.get<QueryResult<TenantSummary>>(`${this.baseUrl}?${DataStore.buildUrl(query as Query)}`)
    return response.data.items || []
  }
  public async EditTwoAuthTenantMode(tenantId: string, enable: boolean) {
    return await this.handleCallAsync(async () => {
      var response = await this.httpService.post<any, any>(`${this.baseUrl}/twoModeAuth/${tenantId}`, { enable: enable });
      return true
    })
  }

  public async export(query: Partial<Query>) {
    return await this.handleCallAsync(async () => {
      var response = await this.httpService.get<any>(`${this.baseUrl}/export?${DataStore.buildUrl(query as Query)}`)
      return true
    })
  }
}

export class TenantItemStore extends FormStore<TenantItem, CreateTenant | EditTenant> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/tenants`)
  }

  public async load(id: string, includeHierarchy: boolean = false, includeHierarchyTree: boolean = false) {
    const p = { includeHierarchy: !!includeHierarchy, includeHierarchyTree: !!includeHierarchyTree }
    return await this.handleCallAsync(async () => {
      const response = await this.httpService.get<TenantItem>(`${this.baseUrl}/${encodeURIComponent(id)}?${stringify(p)}`)
      this._state.set((s) => {
        s.item = response.data as TenantItem
        return s
      })
      return response.data as TenantItem
    })
  }
}
