import React, { FC, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Spin, Alert, Modal, Tag, Row, Col, Card, Input, Checkbox } from 'antd'
import { container } from 'src/inversify.config'
import {
  AddTenantMemberBody,
  EditTenantMemberBody,
  TenantMemberFormStore,
  TenantMember,
} from 'src/stores/tenants/tenant-members-store'
import { nameof } from 'src/core/utils/object'
import { UserItemStore } from 'src/stores/users/users-store'
import { UserGroupsForModalStore, UserGroupSummary } from 'src/stores/users/usersgroups-store'
import Form from 'antd/lib/form'
import { IdentityType, EditMemberDialogFormData, HierarchyType } from 'src/stores/tenants/common'
import { Query } from 'src/core/stores/data-store'
import { ApplicationWithRoleStore, ApplicationWithRoles } from 'src/stores/applications/applications-store'
import { EditApplicationsRoles } from './application-role-editor'
import { IdentityProps, withIdentity } from 'src/core/services/authentication'
import { group } from 'console'
import FloatSelect from '../../../../core/ui/awesome/floatComponent/select'
import FloatInput from '../../../../core/ui/awesome/floatComponent/input'





const EditMemberDialog: FC<{ entityId?: string, isGroup?: boolean; identityId: string; onClose: (cancel: boolean) => void; readonly?: boolean; fromProfile?: boolean } & WithTranslation & IdentityProps> = (props) => {
  const { t, entityId, onClose, identityId, isGroup, readonly, fromProfile } = props
  const [currentidentityId, setCurrentidentityId] = useState(identityId)
  const [form] = Form.useForm<EditMemberDialogFormData>()
  const entityMemberStore = container.get<TenantMemberFormStore>(TenantMemberFormStore)
  const groupStore = container.get<UserGroupsForModalStore>(UserGroupsForModalStore)
  const groupState = groupStore.state
  const applicationItemsStore = container.get<ApplicationWithRoleStore>(ApplicationWithRoleStore)
  const [member, setMember] = useState<TenantMember>()
  const [applications, setApplications] = useState<ApplicationWithRoles[]>()
  const [okDisabled, setokDisabled] = useState(false)
  const [search, setSearch] = useState<string>("")
  const [appToRevoke, setAppToRevoke] = useState<string[]>([])
  const [isInheritable, setIsInheritable] = useState(true)
  const entityMemberState = entityMemberStore.state
  const applicationsStoreState = applicationItemsStore.state
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(identityId)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const currentUser = React.useRef<string>(selectedUserId)

  const readonlyStyle = (isReadonly) => ({
    fontSize: "12px",
    padding: "2px 10px",
    marginLeft: "10px",
    cursor: "default"
  })

  useEffect(() => {
    entityMemberStore.setTenantId(entityId)
  }, [entityId]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setAppToRevoke([])
    LoadEntities()
    LoadGroups()
  }, [currentidentityId]) // eslint-disable-line react-hooks/exhaustive-deps


  const LoadGroups = async () => {

    if (currentUser.current) {
      groupStore.changeUser(currentUser.current, fromProfile)
      await groupStore.load({ searchQuery: '', orderBy: [{ field: 'groupName', direction: 'Ascending', useProfile: true }], skip: 0, take: 10000 } as Query)
    }
  }

  const LoadEntities = async () => {
    var entity = await entityMemberStore.Get(currentidentityId, currentidentityId != identityId ? true : isGroup, fromProfile)
    setMember(entity)
    if (entity.identityType == IdentityType.Group) {
      setSelectedUserId(currentidentityId)
    }
    else {
      setSelectedUserId(undefined)
    }
    setIsInheritable(entity.isInheritable)
    var applications = await applicationItemsStore.getAll({
      skip: 0,
      take: 1000000,
    } as Query, fromProfile)

    var current = entity as TenantMember
    if (current.roles) {
      var applistOrder = current.roles.map(r => r.applicationId)
      var currentApplications = applications as ApplicationWithRoles[]
      currentApplications.sort((a, b) => applistOrder.any(x => x == a.id) ? -1 : applistOrder.any(x => x == b.id) ? 1 : 0)


      setApplications(currentApplications)
    }
    else {
      setApplications(applications);
    }

    form.setFieldsValue({ roles: entity.roles ? entity.roles.map((x: any) => ({ label: x.roleName, value: x.roleId, applicationId: x.applicationId, applicationName: x.applicationName, hierarchyType: x.hierarchyType })) : [] })
  }


  const onRevokeAllAccess = (appId: string) => {
    var applicationsToRevoke = appToRevoke
    applicationsToRevoke.push(appId)
    setAppToRevoke(applicationsToRevoke)
  }


  const onEdit = async () => {
    if (fromProfile) onClose(false)
    setConfirmLoading(true)
    if (member) {
      let item: EditMemberDialogFormData
      try {
        item = (await form.validateFields()) as EditMemberDialogFormData
      } catch {
        setConfirmLoading(false)
        return
      }
      if (appToRevoke.length > 0) {
        let response = await entityMemberStore.revokeAccess(member.identityId, appToRevoke, entityId)
      }
      if (member.hierarchyType == HierarchyType.ByHierarchy || member.id == "00000000-0000-0000-0000-000000000000") {
        var email = !member.email || member.email.length == 0 ? member.identityId : member.email
        var displayName = !member.displayName || member.displayName.length == 0 ? email : member.displayName

        const body: AddTenantMemberBody = {
          identityType: member.identityType,
          identityId: member.identityId,
          email: email,
          displayName: displayName,
          roles: item.roles.filter(t => t.hierarchyType != HierarchyType.ByHierarchy).map((r) => ({ roleId: r.value, roleName: r.label, applicationName: r.applicationName, applicationId: r.applicationId, hierarchyType: r.hierarchyType })),
          isInheritable: isInheritable
        }
        let response = await entityMemberStore.addTenantMember(body)
        if (response?.isSuccess) {
          form.resetFields()
          onClose(response)
        }

      }
      else {
        const body: EditTenantMemberBody = {
          isInheritable: isInheritable,
          roles: item.roles.filter(t => t.hierarchyType != HierarchyType.ByHierarchy).map((r) => ({ roleId: r.value, roleName: r.label, applicationName: r.applicationName, applicationId: r.applicationId, hierarchyType: r.hierarchyType })),
        }
        let response = await entityMemberStore.editTenantMember(member.id, body)
        if (response?.isSuccess) {
          form.resetFields()
          onClose(response)
        }
      }
    }
    setConfirmLoading(false)
  }

  const onAddingRole = (value: boolean) => {
    setokDisabled(value)
  }

  return (
    <Modal maskClosable={false}
      className={"own-modal"}
      confirmLoading={confirmLoading}
      okButtonProps={{ disabled: okDisabled }}
      width={650}
      title={member ? <p style={{ margin: "0px" }}>{member.tenantId ? member.tenantName : t("Global")} ({member.displayName}) <Tag style={{ display: member.hierarchyType == HierarchyType.Own ? 'none' : "initial", fontSize: "12px", padding: "2px 10px", marginLeft: "10px" }} key={10002} color={"cadetblue"}>{t("Inherited")} </Tag> </p> : ""}
      visible={true}
      onOk={readonly ? () => onClose(false) : onEdit}
      onCancel={() => onClose(false)}
      cancelText={t('Cancel')}
      okText={t('Ok')}
      cancelButtonProps={readonly && { style: { display: 'none' } }}
    >
      {entityMemberState.errorMessage.get() && <Alert type="error" message={t(entityMemberState.errorMessage.value || '')} />}
      <Spin spinning={entityMemberState.isBusy.get() || applicationsStoreState.isBusy.get()}>
        {member && applications && <Form
          size="middle"
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={{ roles: member.roles ? member.roles.map((x) => ({ label: x.roleName, value: x.roleId, applicationId: x.applicationId, applicationName: x.applicationName, hierarchyType: x.hierarchyType })) : [] }}
        >
          <Row>
            <Col span={24}>
              <Card >
                <Row>
                  {groupState.items?.get().length > 0 &&
                    <Col span={24} style={{ marginBottom: "20px" }}>
                      <strong>{t("User's Groups")} :</strong>

                      {groupState.items.get().map(group =>

                        <Tag onClick={() => {
                          //if (currentidentityId !== group.groupId) {
                          //  setCurrentidentityId(group.groupId)
                          //  setApplications([])
                          //}
                        }
                        } style={readonlyStyle(true)} key={group.groupId} color={currentidentityId === group.groupId ? "#109DFA" : "#3b5999"}>
                          {group.groupName}</Tag>

                      )
                      }

                    </Col>}
                  <Col span={24}>
                    <FloatInput style={{ marginBottom: "10px" }} onChange={value => setSearch(value.target.value)} label={t("Start typing to search for applications...")} placeholder={t("Start typing to search for applications...")} />
                  </Col>

                  <Col span={24} style={{ overflowY: "scroll", maxHeight: "420px" }}>
                    <Form.Item label={t("Roles")} name={nameof<EditMemberDialogFormData>('roles')} rules={[]}>
                      <EditApplicationsRoles readonly={readonly} onRevokeAllAccess={onRevokeAllAccess} form={form} filter={search} applications={applications} onAddingRole={onAddingRole} {...props} />
                    </Form.Item>
                  </Col>
                  {!readonly && <Col span={24}>
                    <div style={{ marginTop: "10px" }}>
                      {member.tenantId &&
                        <Checkbox checked={isInheritable} disabled={readonly} onChange={value => setIsInheritable(!isInheritable)} >{t("Apply to this entity and all its descendants")}</Checkbox>
                      }
                    </div>
                  </Col>}
                </Row>
              </Card>
            </Col>

          </Row>

        </Form>
        }
      </Spin>
    </Modal>
  )
}
export default withTranslation()(withIdentity(EditMemberDialog))