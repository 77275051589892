import React, { FC, useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { TenantHierarchyTreeNode, TenantHierarchyTreeStore } from 'src/stores/tenants/tenant-hierarchy-tree-store';
import SortableTree from 'react-sortable-tree';
import './tenant-details-hierarchy.less';
import { Alert, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { formatMessage } from 'src/core/services/http.service';
import { COPYFILE_FICLONE_FORCE } from 'constants';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router-dom';
const { confirm } = Modal;

export const TenantDetailsHierarchyTreeSection: FC<
  { entityId: string; forceReload: number; onSelectionChange: (entity: { id: string; title: string }) => void } & WithTranslation
> = (props) => {
  const { entityId, onSelectionChange, t, forceReload } = props;
  const hierarchyTreeStore = container.get<TenantHierarchyTreeStore>(TenantHierarchyTreeStore);

  const [treeData, setTreeData] = useState<any>();
  const [selectedId, setSelectedId] = useState(entityId);
  const [dragging, setDragging] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const expand = (node: TenantHierarchyTreeNode) => {
   
    node.expanded = true;
    (node.children || []).forEach(expand);
    return node;
  };

  const loadTree = async (entityId: string) => {
    setLoading(true);
    const root = await hierarchyTreeStore.load(entityId);
    setTreeData([expand(root)]);
    setLoading(false);
  };

  useEffect(() => {
    if (forceReload > 0) loadTree(entityId);
  }, [forceReload]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setError(undefined);
    loadTree(entityId);
    setSelectedId(entityId);
  }, [entityId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (nextTree: any) => {
    setError(undefined);
    if (!dragging) setTreeData(nextTree);
  };

  const onMoveNode = (info: any) => {
    if ((info.prevPath || []).join('/') === (info.nextPath || []).join('/')) {
      setDragging(false);
    }  else {
      const currentState = treeData;
      setTreeData(info.treeData);
      confirm({
        title: t('Are you sure you want to change this entity parent?'),
        icon: <ExclamationCircleOutlined />,
        content: t('This action can affect users permissions'),
        onOk: () => {
          setLoading(true);
          setError(undefined);
          hierarchyTreeStore
            .changeParent(info.node.id, info.nextParentNode?.id)
            .then((r: any) => {
              if (r?.isSuccess) {
                setSelectedId(info.node.id);
                onSelectionChange({ id: info.node.id, title: info.node.title });
              } else {
                setTreeData(currentState);
                setError(r?.error ? formatMessage(r?.error) : t('Unexpected error'));
              }
              setLoading(false);
              setDragging(false);
            })
            .catch((e) => {
              setTreeData(currentState);
              setError(e ? formatMessage(e) : t('Unexpected error'));
              setLoading(false);
              setDragging(false);
            });
        },
        onCancel: () => {
          setTreeData(currentState);
          setDragging(false);
        },
      });
    }
  };

  const onDragStateChanged = ({ isDragging }: { isDragging: boolean }) => {
    if (isDragging) setDragging(isDragging);
  };


  return (
    <Spin className="tree-hierarchy-spin" spinning={loading} style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        {error && error.length !== 0 && <Alert type="error" message={error} />}
        {treeData && (
          <SortableTree
            isVirtualized={false}
            treeData={treeData}
            onChange={onChange}
            getNodeKey={({ node }: any) => node.id}
            onDragStateChanged={onDragStateChanged}
            generateNodeProps={({ node }: any) => ({
              onClick: () => {
                if (selectedId !== node.id) {
                    history.push(`/admin/tenants/${node.id}`)
                }
              },
              className: node.id === selectedId ? 'selected-tree-node' : undefined,
              title: <NodeTitle node={node} t={t} />,
            })}
            onMoveNode={onMoveNode}
          />
        )}
      </div>
    </Spin>
  );
};

const NodeTitle = ({ node, t }: { node: TenantHierarchyTreeNode; t: TFunction }) => {
 
  return (
    <div className="node-title-container">
      {!node.title || node.title.length == 0 ? t('(Empty Name)') : node.title}
    </div>
  );
};
