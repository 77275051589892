import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface GroupUserSummary {
  id : string
    groupId: string;
    groupName: string;
    userId: string;
    userName: string;
    permission: GroupPermission
}

export interface GroupUserReference {
    groupId: string;
}
export enum GroupPermission {
    Reader = "Reader", 
    Owner = "Owner"
}

export interface NewGroupUserItem {
    groupId?: string;
    userId?: string;
    permission: GroupPermission
}

export interface GroupUserItem {
    groupId: string;
    userId: string;
    permission: GroupPermission
}


export class GroupUsersStore extends DataStore<GroupUserSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/groups`, [])
    this.publicUrl = baseUrl
  }
    
    public async changeGroup(groupId: string) {
        this.baseUrl = `api/v1/admin/groups/${groupId}/users`;
    }

  public async update(id: string, item: GroupUserSummary) {
    return this.save(id, item)
  }
}

export class GroupUserItemStore extends FormStore<NewGroupUserItem,NewGroupUserItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/groups`)
    this.publicUrl = baseUrl
  }

  public async changeGroup(groupId: string) {
    this.baseUrl = `api/v1/admin/groups/${groupId}/users`
  }
}