import { container } from 'src/inversify.config';
import DataStore from '../../core/stores/data-store';
import FormStore from '../../core/stores/form-store';
import i18n from '../../i18n';

export interface AppPermissionSummary {
    id: string;
    applicationId: string;
    name: string;
    displayName: string;
    description: string;
}

export class AppPermissionsStore extends DataStore<AppPermissionSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }

  changeUrl(appId: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/applications/${appId}/permissions`;
  }

  public async update(id: string, item: AppPermissionSummary) {
    return this.save(id, item)
  }
   
}

export interface NewAppPermissionItem {
    name: string,
    applicationId: string;
    displayName: string;
    description: string;
}


export class AppPermissionItemStore extends FormStore<NewAppPermissionItem,NewAppPermissionItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`)
    this.publicUrl = baseUrl
  }

  changeUrl(appId: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/applications/${appId}/permissions`
  }
}

