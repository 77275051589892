import * as React from 'react'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import { withIdentity, IdentityProps } from "../../core/services/authentication"
import { withTranslation, WithTranslation } from 'react-i18next'
import { isNullOrWhitespace } from '../../core/utils/object'
import { Avatar, Button, Card, Col, Divider, Row, Skeleton } from 'antd'
import { ApartmentOutlined, CheckOutlined, CompressOutlined, CrownOutlined, DesktopOutlined, EditOutlined, EllipsisOutlined, ExceptionOutlined, FileSearchOutlined, FireOutlined, GoldOutlined, KeyOutlined, SearchOutlined, SecurityScanOutlined, SettingOutlined, UsergroupAddOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import { BaseAppUrl, container } from '../../inversify.config'
import { DashBoardStore, DashBoardSummary } from '../../stores/dashBoard/dashBoard-store'
import CustomLoad from '../../components/customLoad'
import { getGravatar } from '../../core/utils/gravatar'
import Meta from 'antd/lib/card/Meta'
import LastLogin from '../../components/dashboard/lastLogin'
import AdminPerApp from '../../components/dashboard/adminPerApp'
import AdminPerAreas from '../../components/dashboard/adminPerAreas'
import ConnectedPerApp from '../../components/dashboard/connectedPerApp'
import HomePanel from './home-panel'
import ExternalApiAccess from '../../components/dashboard/externalApiAccess'

const Home: React.FC<RouteComponentProps & IdentityProps & WithTranslation> = (props) => {

  const [isAdmin] = useState(props.identity.roles != undefined && props.identity.roles.some(value => value === "Administrator"))
  const [isGod] = useState(props.identity.roles != undefined && props.identity.roles.some(value => value === "God"))
  const { t } = props

  const currentStore = useMemo(() => container.get(DashBoardStore), [])
  const currentState = currentStore.state

  const inputRef = React.useRef()
  const [avatarSrc, setAvatarSrc] = useState("")

  const LoadPage = async () => {
    let avatarSrc = getGravatar(props.identity.email || "gravatar@tempuri.org", 32)
    setAvatarSrc(avatarSrc)
  }

  useEffect(
    () => {

      LoadPage()

    }, [inputRef]
  )

  var displayName = `${props.identity.firstName || ''} ${props.identity.lastName || ''}`
  if (isNullOrWhitespace(displayName)) {
    displayName = props.identity.profile.name
  }

  return <Row className="admin-dashboard">

    <Col span={24}>
      <Button
        style={{
          float: 'right',
        }}
        icon={<FireOutlined />}
        onClick={() => window.open(`${BaseAppUrl}/hangfire?access_token=${props.identity.accessToken}`, '_blank')}
      >
        {t('Hangfire Dashboard')}
      </Button>
      <h1 style={{ marginLeft: 150 }}> <Avatar style={{ border: "solid 2px black", marginRight: 10 }} icon={<UserOutlined />} size={50} shape="circle" src={avatarSrc} />{t("Welcome {0}!").replace("{0}", displayName)}</h1>
    </Col>
    <Col span={24}>
      <Card style={{ margin: 20, minHeight: 400, background: "#f4f4f4" }}>
        <Row gutter={24}>
          <Col span="12">
            <ConnectedPerApp></ConnectedPerApp>
            <AdminPerAreas></AdminPerAreas>
            <AdminPerApp></AdminPerApp>
          </Col>
          <Col span="12">
            <HomePanel />
            <LastLogin></LastLogin>
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>


}

export default withTranslation()(withRouter(withIdentity(Home)))
