import React, { useState, useEffect, useMemo } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Alert, Card, Button, Tag, message, Select, Popconfirm } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { TableColumn, TableModel, TableView, getDefaultTableSettings } from 'src/core/ui/collections/table'
import ContentTitle from 'src/core/ui/content-header'
import { OperationRequestSummary, OperationRequestStore, OperationRequestStatus } from '../../../../../stores/operationRequests/operationRequests-store'
import { clone, formatDate, formatDateTime } from '../../../../../core/utils/object'
import { Query } from '../../../../../../core/stores/data-store'
import { container } from '../../../../../inversify.config'
import { CheckCircleOutlined, CheckOutlined, ClockCircleOutlined, CloseCircleOutlined, CloseOutlined, CloseSquareOutlined, SearchOutlined, SyncOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons'
import CreateOperationRequestView from './create-operationRequest'
import { IdentityType } from '../../../../../stores/tenants/common'
import { CacheProps, withCache } from 'src/core/services/cache.service'
import { IdentityProps, withIdentity } from '../../../../../core/services/authentication'
import Tooltip from 'antd/es/tooltip'
import './operation-request.less'
import QueueAnim from 'rc-queue-anim'
import SideView from 'src/components/side-view'
const { Option } = Select

// tslint:disable-next-line:no-empty-interface
interface PatListViewProps extends RouteComponentProps, WithTranslation, CacheProps, IdentityProps { }

const PatList: React.FC<PatListViewProps> = (props) => {
  const { t } = props
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'modificationDate', direction: 'Descending', useProfile: false }],
    skip: 0,
    take: getDefaultTableSettings().defaultPageSize,
    odataObject: {
      filter: {}
    },
    parameters: {
      'onlyUser': 'true'
    }
  } as Query)

  const [newOperation, setNewOperation] = useState(false)
  const [onEditing, setOnediting] = useState(false)
  const [toEdit, setToEdit] = useState<{ identityId: string, tenantId: string, identityType: IdentityType }>()

  const [isAdmin] = useState(props.identity.roles != undefined && props.identity.roles.some(value => value === "Administrator"))

  const currentStore = useMemo(() => container.get(OperationRequestStore), [])
  const currentState = currentStore.state
  const operationRequestStore = container.get(OperationRequestStore)

  const [historyOn, setHistoryOn] = useState(false)
  const [historyItem, setHistoryItem] = useState([])
  const [selected, setSelected] = useState([])
  const [approvers, setApprovers] = useState([])

  const [errors, setErrors] = useState([])
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    load(query)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  const load = (q: Query) => {
    currentStore.load(q)
  }
  const onQueryChanged = (q: Query) => {
    setQuery(q)
  }

  const getStatus = (status: OperationRequestStatus) => {
    switch (status) {
      case OperationRequestStatus.Applied:
        return <Tag icon={<CheckCircleOutlined />} color="success">
          {t("Applied")}
        </Tag>
      case OperationRequestStatus.WaitingForApprover:
        return <Tag icon={<ClockCircleOutlined />} color="processing">
          {t("Waiting for Approver")}
        </Tag>
      case OperationRequestStatus.Cancelled:
        return <Tag icon={<CloseCircleOutlined />} color="error">
          {t("Cancelled")}
        </Tag>
      case OperationRequestStatus.Rejected:
        return <Tag icon={<CloseCircleOutlined />} color="error">
          {t("Rejected")}
        </Tag>
    }
    return <Tag icon={<CheckCircleOutlined />} color="success">
      {t("RollBack")}
    </Tag>

  }
  const onEdit = (data: OperationRequestSummary) => {
    setToEdit({ identityId: data.identityId, tenantId: data.tenantId, identityType: data.identityType })
    setOnediting(true)
  }

  const handleRequestOperation = async (data: any, fn: OperationRequestStatus) => {

    try {
      switch (fn) {
        case OperationRequestStatus.Applied:
          var result = await operationRequestStore.aproveOperation({
            "OperationRequestId": data.key,
            "CreatedByDisplayName": data.displayName,
          })
          break
        case OperationRequestStatus.Rejected:
          var result = await operationRequestStore.rejectOperation({
            "OperationRequestId": data.key,
            "CreatedByDisplayName": data.displayName,
          })
          break
        default:
          var result = await operationRequestStore.cancelOperation({
            "OperationRequestId": data.key,
            "CreatedByDisplayName": data.displayName,
          })
      }
      load(query)
    }
    catch (error: any) {
      setErrors([error])
      setTimeout(() => {
        setErrors([])
        setBusy(false)
      }, 5000)
      return
    }
  }

  const handleHistory = async (data: OperationRequestSummary) => {
    try {
      let OperationRequestId = data.id
      setApprovers(data.approvers);
      var result = await operationRequestStore.getHistory(OperationRequestId)
      setHistoryItem(result.reverse())
      setHistoryOn(true)
    }
    catch (error: any) {
      setErrors([error])
      setTimeout(() => {
        setErrors([])
        setBusy(false)
      }, 5000)
      return
    }
  }

  const BuildColumns = () => {
    let columns: TableColumn<OperationRequestSummary>[] = [
      {
        sortable: true,
        field: 'displayName',
        title: t('Display Name'),
        renderer: (data) => <div style={{ minWidth: 180, maxWidth: 360 }}>
          {data.identityType == IdentityType.User ? <UserOutlined /> : <UsergroupAddOutlined />} <span>{data.displayName}</span>
        </div>,
      },
      {
        field: 'email',
        title: t('Email'),
        renderer: (data) => <div style={{ minWidth: 180, maxWidth: 360 }}>
          <span>{data.email}</span>
        </div>,
      },
      {
        sortable: true,
        field: 'modificationDate',
        title: t('Modification Date'),
        width: 150,
        renderer: (data) => <span>{data.modificationDate ? formatDateTime(data.modificationDate) : '-'}</span>
      },
      {
        field: 'history',
        title: t('History'),
        align: 'center',
        width: 100,
        renderer: (data) => <span >{<ClockCircleOutlined className='icon-history' onClick={() => handleHistory(data)} />}</span>
      },
      {
        field: 'status',
        title: t('Status'),
        width: 150,
        renderer: (data) =>
          <span>{getStatus(data.status)}</span>
      },
      {
        field: 'tenantId',
        title: t('Tenant'),
        renderer: (data) => <span className='tenant-tag'>{
          data.status == OperationRequestStatus.WaitingForApprover ?
            <Tag onClick={() => onEdit(data)} style={{ cursor: 'pointer' }} color={"blue"}>{data.tenantName}</Tag> :
            <Tag color={"green"}>{data.tenantName}</Tag>
        }</span>
      },
      {
        field: 'action',
        title: t(''),
        renderer: (data) =>
          <>
            {data.status == OperationRequestStatus.WaitingForApprover ?
              <span>
                <>
                  {data.canApprove && <>
                    <Tooltip title={t("Reject")}>
                      <Button danger style={{ margin: 2 }} icon={<CloseOutlined />} onClick={() => handleRequestOperation(data, OperationRequestStatus.Rejected)} />
                    </Tooltip>
                    <Popconfirm
                      title={t("You are validating access to FI Connect to users outside the company. Ensure that users must access the requested accounts and each application with the requested role")}
                      okText={t("Yes")}
                      onConfirm={() => handleRequestOperation(data, OperationRequestStatus.Applied)}
                      cancelText={t("No")}
                    >
                    <Tooltip title={t("Approve")}>
                      <Button style={{ background: "#87d068", margin: 2 }} icon={<CheckOutlined />} />
                      </Tooltip>
                    </Popconfirm>
                  </>
                  }
                  {data.isMine &&
                    <>
                      <Tooltip title={t("Cancel")}><Button danger icon={<CloseSquareOutlined />} onClick={() => handleRequestOperation(data, OperationRequestStatus.Cancelled)} />
                      </Tooltip>
                    </>
                  }
                </>
              </span> : null}
          </>
      }
    ]

    return columns
  }


  const tableModel = {
    query: query,
    columns: BuildColumns(),
    data: currentState.value,
    sortFields: [],
  } as TableModel<OperationRequestSummary>



  const [statusFilter, setStatusFilter] = useState()

  const onStatusChange = (value) => {
    setStatusFilter(value)
    query.skip = 0
    query.parameters.status = value
    setQuery(query)
    load(query)
  }

  const allToApproveOrReject = () => {
    var items = currentState.items.get().filter(t => selected.any(x => x == t.id))
    return items.all(t => t.status == OperationRequestStatus.WaitingForApprover && t.canApprove)
  }

  const approveInBulk = async () => {
    var items = currentState.items.get().filter(t => selected.any(x => x == t.id))
    var toapply = items.filter(t => t.status == OperationRequestStatus.WaitingForApprover).map(x => x.id)
    await operationRequestStore.approveInBulk(toapply)
    load(query)
  }

  const rejectInBulk = async () => {
    var items = currentState.items.get().filter(t => selected.any(x => x == t.id))
    var toapply = items.filter(t => t.status == OperationRequestStatus.WaitingForApprover).map(x => x.id)
    await operationRequestStore.rejectInBulk(toapply)
    load(query)
  }

  const leftToolbar = (
    selected.length > 0 && <>
      {allToApproveOrReject() &&
        <> < li >
          <Tooltip title={t("Approve in bulk")}>
            <Button style={{ background: "#87d068" }} onClick={() => approveInBulk()} icon={<CheckOutlined />}></Button>
          </Tooltip>
        </li>
          <li>
            <Tooltip title={t("Reject in bulk")}>
              <Button danger onClick={() => rejectInBulk()} icon={<CloseOutlined />}></Button>
            </Tooltip>
          </li>
        </>
      }


    </>
  )
  const rightToolbar = (
    <>
      <li>
        <Select defaultValue={statusFilter}
          allowClear placeholder={t("Select Operation Request Status...")}
          style={{ width: 350 }}
          onChange={(value) => onStatusChange(value)}
        >
          <Option value={OperationRequestStatus.WaitingForApprover}>
            <span>{getStatus(OperationRequestStatus.WaitingForApprover)}</span>
          </Option>
          <Option value={OperationRequestStatus.Applied}>
            <span>{getStatus(OperationRequestStatus.Applied)}</span>
          </Option>
          <Option value={OperationRequestStatus.Cancelled}>
            <span>{getStatus(OperationRequestStatus.Cancelled)}</span>
          </Option>
          <Option value={OperationRequestStatus.Rejected}>
            <span>{getStatus(OperationRequestStatus.Rejected)}</span>
          </Option>

        </Select>
      </li>


    </>
  )


  return (
    <div>

      <Card>
        {errors && errors.length > 0 && errors.map(error =>
          <QueueAnim animConfig={[{ opacity: [1, 0], translateY: [0, 50] }]}>
            <div className='error-message' key="a">
              <span>x</span>
              <p >{t(error)}</p>
            </div>
          </QueueAnim>
        )}
        <TableView
          title={""}
          canSelect
          enableTableSettings
          scroll={{ x: 1700 }}
          onSelectedChange={(ids) => setSelected(ids)}
          rightToolbar={rightToolbar}
          leftToolbar={leftToolbar}
          model={tableModel}
          onQueryChanged={(q: Query) => onQueryChanged(q)}
          onRefresh={() => setQuery(clone(query))}
          canEdit={false}
          canDelete={false}
        >
        </TableView>
        {
          onEditing && <CreateOperationRequestView step={1} selectedTenantId={toEdit.tenantId} selectedUserId={toEdit.identityId} onClose={() => { setOnediting(false); load(query) }}  {...props} />
        }
      </Card>

      <SideView mask={false} width={400} onClose={() => setHistoryOn(false)} visible={historyOn}>
        {
          historyItem?.map((item) => (
            <div key={item.id} className='history-item'>
              <p><span>{t('User')}:</span>{item.email}</p>
              <p><span>{t('Date')}:</span>{formatDateTime(item.date)}</p>
              <p><span>{t('Request Type')}:</span>{item.requestType}</p>
              <p><span>{t('Status')}:</span>{getStatus(item.status)}</p>
            </div>
          ))
        }
        {
          approvers.map(x =>
            <Tag icon={<UserOutlined />} color="#55acee">
              {x}
            </Tag>
          )
        }
      </SideView>

    </div>
  )
}

// Wire up the React component to the Redux store
export default withTranslation()(withIdentity(withRouter(PatList)))
