import autobind from 'autobind-decorator';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {  Input, Checkbox, Alert } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RoleScopesStore, RoleScopeSummary } from 'src/stores/roles/rolescopes-store';
import { TableModel, TableView } from 'src/core/ui/collections/table';
import NewRoleScopeView from './rolescope-new';
import { CommandResult } from 'src/core/stores/types';
import { useMemo } from 'react';
import { container } from '../../../../../inversify.config';
import { clone } from '../../../../../core/utils/object';
import { formatMessage } from '../../../../../core/services/http.service';
import { Query } from '../../../../../core/stores/data-store';

// tslint:disable-next-line:no-empty-interface
interface RoleScopesViewProps {
    applicationId: string,
    id: string
}

interface RoleScopesViewState {
    query: Query;
    newRoleScopeShown: boolean;
}

const RoleScopes : React.FC<RoleScopesViewProps & RouteComponentProps & WithTranslation> = (props) =>  {

  const [query, setQuery] = React.useState({ searchQuery: '', orderBy: [{ field: 'scope', direction: 'Ascending', useProfile: false }], skip: 0, take: 10} as Query)

  const[newRoleScopeShown, setnewRoleScopeShown] = React.useState(false)
  const currentStore = useMemo(() => container.get(RoleScopesStore), [])
  const currentState = currentStore.state

  React.useEffect(() => {
    load(query)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps


  const load = (q: Query) => {
    currentStore.changeUrl(props.applicationId, props.id);
    currentStore.load(q);
    }

   
  const onDeleteRow = async (item: RoleScopeSummary) => {
    currentStore.changeUrl(props.applicationId, props.id);
    await currentStore.delete(item.scope)
    load(query)
  }

  const onSaveItem = async (item: RoleScopeSummary) => {
    await currentStore.update(item.id, item)
    load(query)
  }

        const { t } = props as any;
        const tableModel = {
            query: query,
            columns: [
                {
                    sortable: true,
                    field: 'scope',
                    title: t('Scope'),
                    renderer: (data) => <span>{data.scope}</span>
                }
                /*,
                {
                    sortable: false,
                    field: 'applicationName',
                    title: t('Application'),
                    renderer: (data) => <span>{data.applicationName}</span>
                }*/
          ],
          data: currentState.value,
            sortFields: [],
        } as TableModel<RoleScopeSummary>;
        return (
            <div>
            <TableView
              model={tableModel}
              onQueryChanged={(q: Query) => setQuery(q)}
              onNewItem={() =>setnewRoleScopeShown(true)}
                    onRefresh={() => setQuery(clone(query))}
              canEdit={false}
              canDelete={true}
              onDeleteRow={(item) => onDeleteRow(item)}
              onSaveRow={(item) => onSaveItem(item)}
              canCreateNew={true}
              error={currentState.errorMessage.value && formatMessage(currentState.errorMessage.value)}
            >
            </TableView>
            {
              newRoleScopeShown && <NewRoleScopeView onClose={() => { setnewRoleScopeShown(false); load(query) }} {...props} />
                }
            </div>
        );
    }

export default withTranslation()(withRouter(RoleScopes) );
