import { container } from 'src/inversify.config';
import DataStore from '../../core/stores/data-store';
import { UserSummary } from '../users/users-store';

export class OwnersStore extends DataStore<UserSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }

    changeUrl(appId: string) {
        this.baseUrl = this.publicUrl + `/api/v1/admin/applications/${appId}/users`;
    }

  
}