import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface RoleSummary {
  id: string,
  name: string,
  applicationName: string,
  description: string,
  isGlobal: boolean
}

export interface RoleReference {
  id: string
  name: string,
}

export interface NewRoleItem {
  id: string,
  name: string,
  description: string
}

export interface RoleItem {
  id: string,
  name: string,
  description: string,
}

export class RolesStore extends DataStore<RoleSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }

  public async update(id: string, item: RoleSummary) {
    return this.save(id, item)
  }

  public setApplicationId(applicationId: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/app/${applicationId}/roles`
  }


}

export class RoleItemStore extends FormStore<RoleItem, NewRoleItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`)
    this.publicUrl = baseUrl
  }
  s

  public setApplicationId(applicationId: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/app/${applicationId}/roles`
  }

  public async update(id: string, item: RoleItem) {
    return this.save(id, item)
  }
}

