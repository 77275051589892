import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface RoleScopeSummary {
    id : string
    scope: string;
    applicationName: string;
}

export interface RoleScopeReference {
    scope: string;
}

export interface NewRoleScopeItem {
    scope: string;
}

export interface RoleScopeItem {
    scope: string;
}

export class RoleScopesStore extends DataStore<RoleScopeSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }
  public async changeUrl(applicationId: string, role: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/app/${applicationId}/roles/${role}/scopes`
  }

  public async update(id: string, item: RoleScopeSummary) {
    return this.save(id, item)
  }
 }


export class RoleScopeItemStore extends FormStore<NewRoleScopeItem, NewRoleScopeItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`)
    this.publicUrl = baseUrl
  }
  public async changeUrl(applicationId: string, role: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/app/${applicationId}/roles/${role}/scopes`
  }
 
}