import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Alert, Card, Tag, Checkbox } from 'antd'
import { AppUsersWithRolesStore } from 'src/stores/applications/users-store';
import { getDefaultTableSettings, TableModel, TableView } from 'src/core/ui/collections/table'
import Link from 'src/core/ui/link'
import { RouteComponentProps, withRouter } from 'react-router'
import { UserSummary } from 'src/stores/users/users-store'
import { clone, formatBoolean, formatDate } from 'src/core/utils/object'
import NewUserView from 'src/pages/admin/users/user-new'
import { useMemo, useState } from 'react'
import { container } from 'src/inversify.config'
import { IdcardOutlined } from '@ant-design/icons'
import { Query } from 'src/core/stores/data-store';
import { IdentityProps, withIdentity } from '../../../../core/services/authentication';
import { formatMessage } from '../../../../core/services/http.service';
import AreaSelect from 'src/components/areaEdit'

// tslint:disable-next-line:no-empty-interface
interface AppUsersViewProps extends RouteComponentProps, WithTranslation {
  applicationId: string
}

interface UsersViewState {
  query: Query
  newUserShown: boolean
  roleId: string | undefined
  scope: string | undefined
}

const Users: React.FC<AppUsersViewProps & IdentityProps> = (props) => {

  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'email', direction: 'Ascending', useProfile: false }],
    skip: 0,
    take: getDefaultTableSettings().defaultPageSize,
    odataObject: {
       filter: {}
    },
    parameters: {

    }
  } as Query)

  const [newUserShown, setnewUserShown] = useState(false)
  const currentStore = useMemo(() => container.get(AppUsersWithRolesStore), [])
  const currentState = currentStore.state

  const [roleId, setRoleId] = useState<string | undefined>()
  const [scope, setScope] = useState<string | undefined>()
  const [areaFilter, setAreaFilter] = useState<any>()
  const [initPagination, setInitPagination] = useState(false)

  React.useEffect(() => {
    load(query)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps


  const load = (query: Query) => {
    currentStore.changeUrl(props.applicationId)
    currentStore.load(query)
  }

  
    const onChangeGlobal = (checked: any) => {
      setInitPagination(!initPagination)
      let newQuery = query
      newQuery.parameters.globalRoles = checked
      newQuery.skip = 0
      setQuery(newQuery)
      load(newQuery)
  }

  const { t } = props
  const tableModel = {
    query: query,
    columns: [
      {
        field: 'email',
        title: t('Email'),
            sortable: true,
            renderer: (data) => <span><Link to={{ pathname: `/admin/applications/${props.applicationId}/users/${data.id}`, state: { titles: [...(props.location.state ? (props.location.state as any).titles || [] : []), { url: `${process.env.PUBLIC_URL}${props.match.url}/${data.id}`, title: data.userName }] } }}><IdcardOutlined />&nbsp;<span>{data.userName}</span></Link></span>,
      },
      {
        field: 'firstName',
        title: t('First name'),
        renderer: (data) => <span>{data.firstName}</span>,
      },
      {
        field: 'lastName',
        title: t('Last name'),
        renderer: (data) => <span>{data.lastName}</span>,
      },
      {
        field: 'areaName',
        title: t('Area'),
        renderer: (data) => <span>{data.areaName}</span>,
      },
      {
        centered: true,
        field: 'roles',
        title: t('Roles'),
        renderer: (data) => data.roles ? data.roles.map(x =>
          <Tag color={x.isGlobal ? "rgb(14,14,79)" : "green"}>{x.name }</Tag>) : ""
      },
    

    ],
    data: currentState.value,
    sortFields: [],
    } as TableModel<UserSummary>
    const rightToolbar = (
        <>
            <li>
                <Checkbox style={{ marginTop: 5, marginLeft: 15 }} onChange={(x) => onChangeGlobal(x.target.checked)} >
                    {t("Global roles only")}
                </Checkbox>
            </li>
            <li>
                <AreaSelect minWidth={200} value={areaFilter} placeholder={t("Select Area...")} valueAsItemReference onChange={(value) => {
                    setInitPagination(!initPagination)
                    let newQuery = query
                    newQuery.filter = newQuery.filter || {}
                    newQuery!.filter['areaId'] = value ? { eq: { type: 'guid', value: value.id } } : undefined
                    setAreaFilter(value ? value.id : undefined)
                    newQuery.skip = 0
                    setQuery(newQuery)
                    load(newQuery)
                }
                } />
            </li>

        </>
    )
  return (<Card>
    <TableView
      enableTableSettings
      rightToolbar={rightToolbar}
      model={tableModel}
      onQueryChanged={(q: Query) => setQuery(q)}
      onNewItem={() => setnewUserShown(true)}
      onRefresh={() => setQuery(clone(query))}
      canEdit={false}
      canDelete={false}
      error={currentState.errorMessage.value && formatMessage(currentState.errorMessage.value)}
      canCreateNew={false}
      initPagination={initPagination}
      >
    </TableView>
    {
      newUserShown && <NewUserView {...props} applicationId={props.applicationId} onClose={() => { setnewUserShown(false); load(query) }} />
    }
  </Card>)
}


export default withTranslation()(withRouter(withIdentity(Users)))

//bulkTemplateName = { t("Application_Users.xlsx") }
//      bulkInsertUrl={`api/v1/admin/applications/bulk_insert_users/${props.applicationId}`}
//      bulkTemplateUrl={`api/v1/admin/applications/users_template`}