import React, { useState } from "react"
import { Input } from "antd"

import "./float.less"

const FloatInput = (props) => {
  const [focus, setFocus] = useState(false)
  let { label, value, placeholder, type, required, prefix } = props

  if (!placeholder) placeholder = label

  const isOccupied = focus || (value && value.length !== 0)

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder"

  const requiredMark = required ? <span className="text-danger">*</span> : null

  return (
    <div
      className={"float-label" + (prefix ? " with-prefix" : "")}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input onChange={props.onChange} type={type} defaultValue={value} prefix={prefix} {...props} placeholder={undefined} />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  )
}

export default FloatInput
