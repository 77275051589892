import DataStore from 'src/core/stores/data-store'
import FormStore from 'src/core/stores/form-store'
import { CommandResult } from 'src/core/stores/types'
import { UserSummary } from '../users/users-store'
import { IdentityType, HierarchyType } from './common'

export interface TenantMember {
  id: string
  tenantId: string
  tenantName: string
  identityId: string
  displayName: string
  identityType: IdentityType
  email: string
  isInheritable: boolean
  roles: TenantMemberRole[],
  hierarchyType: HierarchyType
}

export interface TenantMemberSummary {
  id: string
  identityId: string
  displayName: string
  identityType: IdentityType
  email: string
  isInheritable: boolean
  hierarchyType: HierarchyType
}

export interface TenantMemberRole {
  id: string
  roleId: string
  roleName: string
  applicationId: string
  applicationName: string
  hierarchyType: HierarchyType
}

export interface TenantMemberRoleBody {
  roleId: string
  roleName: string
  applicationId: string
  applicationName: string

}

export interface AddTenantMemberBody {
  identityId: string
  displayName: string
  email: string
  identityType: IdentityType
  roles?: TenantMemberRoleBody[]
  isInheritable: boolean
}

export interface TenantMemberRoleBody {
  roleId: string
  roleName: string
  applicationId: string
  applicationName: string

}

export interface EditTenantMemberBody {
  roles: TenantMemberRoleBody[]
  isInheritable: boolean
}

export interface RevokeAllAccess {
  applicationIds: string[],
  userId: string,
  includeParent: boolean
}

export interface TenantClientMember extends UserSummary {

}

export class TenantClientMemberDataStore extends DataStore<TenantClientMember> {
  private baseUrlMembers: string
  entityId: string | null = null;

  constructor(baseUrl: string) {
    super(baseUrl, [])
    this.baseUrlMembers = `${this.baseUrl}/api/v1/profile/{tenantId}/members`
  }

  public setTenantId(tenantId: string) {
    this.entityId = tenantId
    this.baseUrl = this.baseUrlMembers.replace('{tenantId}', encodeURIComponent(tenantId))

  }

}


export class TenantMemberDataStore extends DataStore<TenantMemberSummary> {
  private baseUrlMembers: string
  entityId: string | null = null;

  constructor(baseUrl: string) {
    super(baseUrl, [])
    this.baseUrlMembers = `${baseUrl}/api/v1/admin/tenants/{tenantId}/members`
  }

  public setTenantId(tenantId: string) {
    this.entityId = tenantId
    this.baseUrl = this.baseUrlMembers.replace('{tenantId}', encodeURIComponent(tenantId))
  }

  public async deleteTenantMember(memberId: string) {
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.delete(`${this.baseUrl}/${encodeURIComponent(memberId)}`)
      return result.data
    })
  }
}

export class TenantMemberFormStore extends FormStore<TenantMember, AddTenantMemberBody> {
  baseUrlMembers: string
  baseUrlProfile: string
  tenantId?: string

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrlMembers = `${baseUrl}/api/v1/admin/tenants`
    this.baseUrlProfile = `${baseUrl}/api/v1/profile/tenants`
  }

  public setTenantId(tenantId?: string) {
    this.tenantId = tenantId
  }
  public async Get(identityId: string, isGroup?: boolean, fromProfile: boolean = false) {
    return await this.handleCallAsync(async () => {
      var url = ``
      if (fromProfile) url = `${this.baseUrlProfile}/members/identity?`
      else url = `${this.baseUrlMembers}/members/identity?`
      url = url + 'identityId=' + identityId
      if (this.tenantId) {
        url = url + '&tenantId=' + this.tenantId
      }
      if (isGroup) {
        url = url + '&isGroup=true'
      }
      var response = await this.httpService.get<TenantMember>(url)
      return response.data

    })
  }

  public async addTenantMember(data: AddTenantMemberBody) {
    var url = `${this.baseUrlMembers}/members?`
    if (this.tenantId) {
      url = url + '&tenantId=' + this.tenantId
    }
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<AddTenantMemberBody, CommandResult<any>>(url, data)

      return result.data
    })
  }

  public async editTenantMember(memberId: string, data: EditTenantMemberBody) {
    var url = `${this.baseUrlMembers}/members/${encodeURIComponent(memberId)}?`
    if (this.tenantId) {
      url = url + '&tenantId=' + this.tenantId
    }
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.put<EditTenantMemberBody, CommandResult<any>>(url, data)

      return result.data
    })
  }

  public async revokeAccess(userId: string, appIds: string[], tenantId?: string,) {
    var url = `${this.baseUrlMembers}/members/revokeAccess?`
    if (this.tenantId) {
      url = url + '&tenantId=' + tenantId
    }
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<RevokeAllAccess, CommandResult<any>>(url, { applicationIds: appIds, userId: userId, includeParent: true })
      return result.data
    })
  }

  public async deleteTenantMember(memberId: string) {
    var url = `${this.baseUrlMembers}/${encodeURIComponent(this.tenantId ?? "")}/members`
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.delete(`${url}/${encodeURIComponent(memberId)}`)

      return result.data
    })
  }
}




export class TenantMemberForOperationRequestFormStore extends FormStore<TenantMember, AddTenantMemberBody> {
  baseUrlMembers: string
  tenantId?: string

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrlMembers = `${baseUrl}/api/v1/admin/operationRequests`
  }

  public setTenantId(tenantId?: string) {
    this.tenantId = tenantId
  }

  public async Get(identityId: string, isGroup?: boolean) {
    return await this.handleCallAsync(async () => {
      var url = `${this.baseUrlMembers}/permissions?`
      url = url + 'identityId=' + identityId
      if (this.tenantId) {
        url = url + '&tenantId=' + this.tenantId
      }
      if (isGroup) {
        url = url + '&isGroup=true'
      }
      var response = await this.httpService.get<TenantMember>(url)
      return response.data

    })
  }

  public async addTenantMember(data: AddTenantMemberBody, autoApprove: boolean = false) {
    var url = `${this.baseUrlMembers}/permissions?`
    if (this.tenantId) {
      url = url + '&tenantId=' + this.tenantId + '&autoApprove=' + autoApprove;
    }
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<AddTenantMemberBody, CommandResult<any>>(url, data)

      return result.data
    })
  }

  public async editTenantMember(memberId: string, data: EditTenantMemberBody) {
    var url = `${this.baseUrlMembers}/permissions/${encodeURIComponent(memberId)}?`
    if (this.tenantId) {
      url = url + '&tenantId=' + this.tenantId
    }
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.put<EditTenantMemberBody, CommandResult<any>>(url, data)

      return result.data
    })
  }

  public async revokeAccess(userId: string, appIds: string[], tenantId?: string,) {
    var url = `${this.baseUrlMembers}/members/revokeAccess?`
    if (this.tenantId) {
      url = url + '&tenantId=' + tenantId
    }
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<RevokeAllAccess, CommandResult<any>>(url, { applicationIds: appIds, userId: userId, includeParent: true })
      return result.data
    })
  }

  public async deleteTenantMember(memberId: string) {
    var url = `${this.baseUrlMembers}/${encodeURIComponent(this.tenantId ?? "")}/members`
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.delete(`${url}/${encodeURIComponent(memberId)}`)

      return result.data
    })
  }
}

