import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface UserRoleSummary {
    id: string;
    roleId: string;
    applicationId: string;
    applicationName: string;
    roleName: string;
}

export interface UserRoleReference {
    roleId: string;
    roleName: string;
}

export interface NewUserRoleItem {
    applicationId: string;
    roleId: string;
}

export interface UserRoleItem {
    roleId: string;
    roleName: string;
    applicationId: string;
    applicationName: string;
}


export class UserRolesStore extends DataStore<UserRoleSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }
  public async changeUser(userId: string) {
    this.baseUrl = this.publicUrl +`/api/v1/admin/users/${userId}/roles`;
    }

  public async update(id: string, item: UserRoleSummary) {
    return this.save(id, item)
  }
}

export class UserRoleItemStore extends FormStore<NewUserRoleItem, NewUserRoleItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`)
    this.publicUrl = baseUrl
  }
  public async changeUser(userId: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/users/${userId}/roles`
  }

  public async update(id: string, item: UserRoleSummary) {
    return this.save(id, item)
  }
}