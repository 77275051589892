import { CheckOutlined, FieldTimeOutlined, NotificationOutlined, ReloadOutlined } from '@ant-design/icons'
import { Alert, Badge, Button, message } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { IdentityProps, withIdentity } from '../services/authentication'
import Countdown from 'antd/lib/statistic/Countdown'
import { useIdleTimer } from 'react-idle-timer'
import { none } from '@hookstate/core'

interface Props extends WithTranslation, IdentityProps {
  timeout?: number
}

const ShellExpirationToken: FC<Props> = (props) => {
  const { t } = props
  const [isIdle, setIsIdle] = useState(false)
  const [expired, setExpired] = useState(false)
  const [expireTime] = useState(() => {
    var value = Date.now() + 60 * 60 * 1000 * 24
    if (props.identity.exp_time)
      value = props.identity.exp_time * 1000
    setTimeout(function () {
      setExpired(true)
    }, value - Date.now())
    return value
  })


  const onPrompt = () => {
  }

  const onIdle = () => {
    if (!isIdle && !expired) {

      message.warning({ content: <Countdown title={t("Your session will expire in")} value={expireTime} />, duration: 0 })
    }
    setIsIdle(true)
  }

  const onActive = (event) => {
    setIsIdle(false)
    
    setTimeout(function () {
      message.destroy()
    }, 1000)
  }


  const { }
    = useIdleTimer({
      onPrompt,
      onIdle,
      onActive,
      timeout: 1000 * 10 * 60,
      promptTimeout: 0,
      events: [
        'mousemove',
        'keydown',
        'wheel',
        'DOMMouseScroll',
        'mousewheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'MSPointerDown',
        'MSPointerMove',
        'visibilitychange'
      ],
      immediateEvents: [],
      debounce: 0,
      throttle: 0,
      eventsThrottle: 200,
      element: document,
      startOnMount: true,
      startManually: false,
      stopOnIdle: false,
      crossTab: false,
      name: 'idle-timer',
      syncTimers: 0,
      leaderElection: false
    })

  return (
    <>

      {expired &&
        <div className={'modal-idle-app-shadow'}>
          <div className={'modal-idle-app'}>
            <div className={'header-modal-idle-app'}></div>
            <div className={'body-modal-idle-app'}>
              <FieldTimeOutlined style={{ fontSize: 60, marginBottom: 20, color: "rgb(14,14,79)" }} />
              <Alert
                message={t("Your session has expired. ")}
                type="warning"
                showIcon
              />
              <Countdown onFinish={() => props.identity.logout()} title={t("This will redirect to Login page in")} value={Date.now() + 5000} />
              <Button style={{ marginTop: 20 }} onClick={() => props.identity.logout()} type="primary" icon={< CheckOutlined />}>
                {t("Ok")}
              </Button>
            </div>
          </div>
        </div>
      }
      {props.children}
    </>
  )
}

export default withIdentity(withTranslation()(ShellExpirationToken))
