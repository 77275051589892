import HttpService from '../../core/services/http.service'
import DataStore from '../../core/stores/data-store'
import { container } from '../../inversify.config'

export interface DashBoardSummary {
  usersCount: number
  appsCount: number
  groupsCount: number
  tenantsCount: number
  areasCount: number
}


export class DashBoardStore extends DataStore<DashBoardSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/dashboard`, [])
    this.publicUrl = baseUrl
  }

  public async GetResume() {
    var httpService = container.get(HttpService)
    const result = await httpService.get<DashBoardSummary>(this.baseUrl)
    return result.data
  }
  public async GetLastHourLogin() {
    var httpService = container.get(HttpService)
    const result = await httpService.get<any>(this.baseUrl +"/loginLastHours")
    return result.data
  }
  public async GetAdminPerApp() {
    var httpService = container.get(HttpService)
    const result = await httpService.get<any>(this.baseUrl + "/adminPerApp")
    return result.data
  }
  public async ConnectedPerApp() {
    var httpService = container.get(HttpService)
    const result = await httpService.get<any>(this.baseUrl + "/connectedPerApp")
    return result.data
  }
  public async GlobalAdminPerAreas(tenantId?: string, appId?: string) {
    var httpService = container.get(HttpService)
    var url = this.baseUrl + "/globalAdminPerAreas"
    const result = await httpService.get<any>(url, { tenantId, appId })
    return result.data
  }
    public async ExternalApiAccess(startDate: string, endDate: string) {
    var httpService = container.get(HttpService)
    var url = this.baseUrl + "/externalApiAccess"
    const result = await httpService.get<any>(url, {startDate, endDate})
    return result.data
  }

  public async identifyAs(userId: string) {
    var httpService = container.get(HttpService)
    var url = this.baseUrl + "/identifyAs/" + userId;
    const result = await httpService.get<any>(url)
    return result.data
  }

  public async identifyAsEnabled() {
    var httpService = container.get(HttpService)
    var url = this.baseUrl + "/identyfyasEnabled"
    const result = await httpService.get<boolean>(url)
    return result.data
  }

  public async CreateAdminReport() {
    var httpService = container.get(HttpService)
    var url = this.baseUrl + "/adminReport"
    const result = await httpService.get<boolean>(url)
    return result.data
  }
  
}

