import { FC, useState, useEffect } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Tag } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { TableModel, TableView, getDefaultTableSettings } from 'src/core/ui/collections/table'
import { clone, formatDateTime } from 'src/core/utils/object'
import { container } from 'src/inversify.config'
import { formatMessage } from 'src/core/services/http.service'
import { Query } from 'src/core/stores/data-store'
import { SessionLogStore, SessionLogSummary } from 'src/stores/authLogs/logs-store'
import { CacheProps, withCache } from 'src/core/services/cache.service'
import HttpService from 'src/core/services/http.service'


interface SessionLogViewProps extends RouteComponentProps, CacheProps, WithTranslation {

}

const SessionLog: FC<SessionLogViewProps> = (props) => {
  const { t } = props
  const sessionLogStore = container.get<SessionLogStore>(SessionLogStore)
  const sessionLogStoreState = sessionLogStore.state
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'date', direction: 'Descending', useProfile: false }],
    skip: 0,
    take: getDefaultTableSettings().defaultPageSize,
    parameters: {
      includeHierarchy: 'true',
    },
    odataObject: {
      filter: {}
    },
  } as Query)

  const [initPagination, setInitPagination] = useState(false)
  const httpService = container.get(HttpService)

  useEffect(() => {
    load(query)
  }, [query])

  const GetRoles = (data) => {
    const { r, g, b } = setColor()
    const backgroundColor = `rgb(${r}, ${g}, ${b})`

    return data.roles.map((role, index) => (
      <Tag key={index} color={backgroundColor}>{role}</Tag>
    ))
  }

  const setColor = () => {
    return { r: 0, g: 0, b: 164 }
  }


  const load = (q: Query) => {
    sessionLogStore.load(q)
  }


  const tableModel = {
    query: query,

    columns: [
      {
        centered: true,
        field: 'userName',
        title: t('Username'),
        renderer: (data) => <span>{data.userName}</span>
      },
      {
        field: 'roles',
        title: t('Roles'),
        renderer: (data) => <span>{GetRoles(data)}</span>
      },
      {
        field: 'applicationName',
        title: t('Application Name'),
        renderer: (data) => <span>{data.applicationName}</span>,
      },
      {
        sortable: true,
        field: 'date',
        title: t('Date'),
        renderer: (data) => <span>{formatDateTime(data.date, undefined, "DD-MM-YYYY HH:mm:ss")}</span>,
      },

    ],
    data: sessionLogStoreState.value,
    sortFields: [
      { field: 'date', text: t('Date'), useProfile: false }
    ],
  } as TableModel<SessionLogSummary>



  return (

    <div style={{ width: '100%' }}>
      <TableView
        enableTableSettings
        model={tableModel}
        onQueryChanged={(q: Query) => setQuery(q)}
        onRefresh={() => setQuery(clone(query))}
        error={sessionLogStoreState.errorMessage.value && formatMessage(sessionLogStoreState.errorMessage.value)}
      >
      </TableView>
    </div>
  )
}



// Wire up the React component to the Redux store
export default withCache(withTranslation()(withRouter(SessionLog)))