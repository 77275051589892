import React, { FC, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withIdentity, IdentityProps } from 'src/core/services/authentication'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Row, Col, Card, Tag, Tooltip, Button, Form, Modal, Alert, Spin } from 'antd'
import ContentHeader from 'src/core/ui/content-header'
import { container } from 'src/inversify.config'
import { AddTenantIdentityGroupItem, TenantIdentityGroupItem, TenantIdentityGroupStore, TenantItem, GroupMemberSummary, GroupMemberSummaryStore } from 'src/stores/tenant-identity/tenant-identity-store'
import { PlusOutlined, UserOutlined } from '@ant-design/icons'
import EditMemberDialog from 'src/pages/admin/tenants/components/identity-tenant-roles-editor'
import { Query } from 'src/core/stores/data-store'
import { TableModel, TableView } from 'src/core/ui/collections/table'
import { formatMessage } from 'src/core/services/http.service'
import { UserSummary } from 'src/stores/users/users-store'
import { nameof } from 'src/core/utils/object'
import UserSelect from 'src/components/userEdit'
import { AddTenantDialog } from './tenant-identities'




interface AddMemberDialogFormData {
    user: { value: string; label: string } & Partial<UserSummary>

}


export const AddMemberDialog: FC<{ groupId: string, onClose: (cancel: boolean) => void } & WithTranslation> = ({ groupId, t, onClose }) => {
    const [form] = Form.useForm<AddMemberDialogFormData>()
    const store = container.get<TenantIdentityGroupStore>(TenantIdentityGroupStore)
    const storeState = store.state

    const onAdd = async () => {
        let item: AddMemberDialogFormData
        try {
            item = (await form.validateFields()) as AddMemberDialogFormData
        } catch {
            return
        }

        const body: AddTenantIdentityGroupItem = {
            groupId: groupId,
            userId: item.user.value,
            permission: "Reader"

        }
        let response = await store.addGroupMember(body)
        if (response?.isSuccess) {
            form.resetFields()
            onClose(response)
        }
    }

    return (
        <Modal width={600} maskClosable={false} title={t('Add Member to Group')} visible={true} onOk={onAdd} onCancel={() => onClose(true)}>
            {storeState.errorMessage.get() && <Alert type="error" message={t(storeState.errorMessage.value || '')} />}
            <Spin spinning={storeState.isBusy.get()}>
                <Form size="middle" form={form} layout="vertical" autoComplete="off" initialValues={{}}>
                    <Form.Item noStyle name={nameof<AddMemberDialogFormData>('user')} label={t('User')} rules={[{ required: true }]}>
                        <UserSelect valueAsItemReference />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}





interface TenantGroupDetailPageProps extends WithTranslation, IdentityProps, RouteComponentProps { }


const TenantGroupDetailPage: FC<TenantGroupDetailPageProps> = (props) => {
    const { t, location, match } = props
    const groupId = (location?.state?.groupId as string) || ((match.params as any)['id'] as string)
    const groupName = location?.state?.groupName as string

    const entityStore = container.get<TenantIdentityGroupStore>(TenantIdentityGroupStore)

    const groupMemberDataStore = container.get<GroupMemberSummaryStore>(GroupMemberSummaryStore)
    const groupMemberDataStoreState = groupMemberDataStore.state

    const [selectedGroup, setSelectedGroup] = useState<TenantIdentityGroupItem | undefined>(undefined)
    const [forceReload, setForceReload] = useState(0)
    const [data, setData] = useState<TenantItem[]>([])

    const [addMemberVisible, SetAddMemberVisible] = useState(false)

    const isAdmin = props.identity.roles && props.identity.roles.filter((o) => o.includes('Administrator')).length != 0 ? true : false
    const [editIdentityVisible, setEditIdentityVisible] = useState(false)
    const [addTenantVisible, SetAddTenantVisible] = useState(false)
    const [selectedTenant, setSelectedTenant] = useState<any>(undefined)

    const [query, setQuery] = useState({
        searchQuery: '',
        orderBy: [{ field: 'userName', direction: 'Ascending', useProfile: false }],
        skip: 0,
        take: 10,
    } as Query)

    const load = async (id: string) => {

        const group = await entityStore.Get(id)
        setSelectedGroup(group)
       
        setData(group.tenants)
    }


    const onCloseAddTenant = (selectedTenantId: any, close: boolean) => {
        SetAddTenantVisible(false)
        load(groupId)
        if (close) {
            setSelectedTenant(selectedTenantId)
            setEditIdentityVisible(true)
            
        }
    }

    useEffect(() => {
        groupMemberDataStore.setGroupId(groupId)
        groupMemberDataStore.load(query)
        load(groupId)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const tableModel = {

        query: query,
        columns: [

            {
                sortable: true,
                searchable: true,
                useProfile: false,
                width: 250,
                maxWidth: 250,
                field: 'displayName',
                title: t('Display Name'),
                renderer: (data: GroupMemberSummary) => (

                    <span>
                        <UserOutlined /> {data.userName}
                    </span>
                ),
            },




        ],
        data: groupMemberDataStoreState.value,
    } as TableModel<GroupMemberSummary>

    return (
        <div>
            <Row gutter={0}>
                <Col span={16}>
                    <ContentHeader
                         
                        title={
                            <span>
                                {t('Group Details')}
                                {`${groupName ? ' - ' : ''}${groupName || ''}`}
                            </span>
                        }
                    />
                </Col>
            </Row>
            <Row gutter={16} align="top" justify="space-between">
                <Col span={8}>
                    <Card title={t("Group's Users")} bordered={true}>
                        <TableView
                            small
                            canDelete={selectedGroup ? selectedGroup.userIsOwner : false}
                            rowKey="userName"
                            onNewItem={() => SetAddMemberVisible(true)}
                            canCreateNew={selectedGroup ? selectedGroup.userIsOwner : false}
                            onQueryChanged={(query: Query) => {
                                setQuery(query)
                                groupMemberDataStore.load(query)
                            }}
                            showDeleteStatusWindow
                            onDeleteRow={(item: any) => groupMemberDataStore.delete(item.userId)}
                            onRefresh={() => groupMemberDataStore.load(query)}
                            model={tableModel}
                            error={groupMemberDataStoreState.errorMessage.value && formatMessage(groupMemberDataStoreState.errorMessage.value)}
                        />
                        {addMemberVisible && selectedGroup && (
                            <AddMemberDialog
                                groupId={selectedGroup.id}
                                onClose={() => {
                                    SetAddMemberVisible(false)
                                    groupMemberDataStore.load(query)
                                }}
                                {...props}
                            />
                        )}

                    </Card>
                </Col>
                <Col span={16}>
                    <Card title={t("Tenants")} bordered={true}>
                        <span>
                            {data.any(e => e.id == undefined) &&
                                <Tag
                                    onClick={() => {
                                        setSelectedTenant(undefined)
                                        setEditIdentityVisible(true)

                                    }}
                                    style={{ fontSize: "12px", padding: "2px 15px", cursor: "pointer" }} color={data.any(e => e.id == null) ? "#0E0E4F" : "seagreen"}>
                                    {t("Global")} </Tag>}
                            {data.filter(e => e.id != undefined).map(e =>

                                <Tag
                                    onClick={() => {
                                        setSelectedTenant(e.id)
                                        setEditIdentityVisible(true)

                                    }}
                                    style={{ fontSize: "12px", padding: "2px 15px", cursor: "pointer" }} color={e.id ?  "seagreen"  : "#0E0E4F"}>
                                    {e.id ? !e.title || e.title.length === 0 ? t("(Empty Name)") : e.title : t("Global")}
                                </Tag>
                            )}
                            <Tooltip placement="topRight" title={t('Add Tenant Roles')}>
                                <Button
                                    onClick={() => {
                                        SetAddTenantVisible(true)

                                    }}

                                    type="primary" icon={<PlusOutlined />} />
                            </Tooltip>

                        </span>
                    </Card>
                </Col>

            </Row>
            {editIdentityVisible && (
                <EditMemberDialog
                    identityId={groupId}
                    entityId={selectedTenant}
                    isGroup
                    onClose={() => {
                        load(groupId)
                        setEditIdentityVisible(false)
                    }}
                    {...props}
                />
            )}

            {addTenantVisible && (
                <AddTenantDialog
                    isAdmin={isAdmin}
                    onClose={onCloseAddTenant}
                    {...props}
                />
            )}
        </div>
    )
}

export default withIdentity(withTranslation()(withRouter(TenantGroupDetailPage)))
