import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface ConfigSettingItem {
  id: string,
  title : string,
  body: string
  description: string
  modifiedByDisplayName: string 
  modifiedOn : Date
}



export class ConfigSettingsStore extends DataStore<ConfigSettingItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/securityConfig`, [])
    this.publicUrl = baseUrl
  }

  public async update(id: string, item: ConfigSettingItem) {
    return this.save(id, item)
  }
}

export class ConfigSettingItemStore extends FormStore<ConfigSettingItem, ConfigSettingItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/securityConfig`)
    this.publicUrl = baseUrl
  }
}