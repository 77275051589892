import autobind from 'autobind-decorator'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Alert, Card } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConfigSettingsStore, ConfigSettingItem } from 'src/stores/configSettings/config-setting-store'
import { getDefaultTableSettings, ItemState, TableModel, TableView } from 'src/core/ui/collections/table'
import { formatDate, formatBoolean, clone } from 'src/core/utils/object'
import UpdateConfigView from './config-edit'
import ContentTitle from 'src/core/ui/content-header'
import Link from 'src/core/ui/link'
import { CommandResult } from '../../../core/stores/types'
import { withIdentity, IdentityProps } from "src/core/services/authentication"
import { FC, useState } from 'react'
import { container } from '../../../inversify.config'
import { DesktopOutlined, ExceptionOutlined } from '@ant-design/icons'
import { formatMessage } from '../../../core/services/http.service'
import { Query } from '../../../core/stores/data-store'

// tslint:disable-next-line:no-empty-interface
interface ConfigViewProps extends RouteComponentProps, WithTranslation {
}


const ConfigList: FC<ConfigViewProps & IdentityProps> = (props) => {
  const { t } = props
  const currentStore = React.useMemo(() => container.get(ConfigSettingsStore), []) as ConfigSettingsStore
  const currentState = currentStore.state
  const [query, setQuery] = useState({ searchQuery: '', orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }], skip: 0, take: getDefaultTableSettings().defaultPageSize } as Query)
  const [editConfigShown, setEditConfigShown] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ConfigSettingItem | undefined>()
  React.useEffect(() => {
    load(query)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps


  const load = (query: Query) => {
    currentStore.load(query)
  }

  const onQueryChanged = (query: Query) => {
    setQuery(query)
  }





  const tableModel = {
    query: query,
    columns: [
      {
        sortable: true,
        field: 'title',
        title: t('Title'),
        renderer: (data) => <a onClick={() => { setSelectedItem(data); setEditConfigShown(true) }}><span> <ExceptionOutlined />&nbsp;<span>{data.title}</span></span></a>
      },
      {
        sortable: false,
        field: 'description',
        title: t('Description'),
        renderer: (data) => <span>{data.description}</span>
      },
      {
        sortable: false,
        field: 'modifiedByDisplayName',
        title: t('Modify By'),
        renderer: (data) => <span>{data.modifiedByDisplayName}</span>
      },
      {
        sortable: false,
        field: 'modifiedOn',
        title: t('Modified On'),
        renderer: (data) => <span>{formatDate(data.modifiedOn)}</span>
      },
    ],
    data: currentState.value,
    sortFields: [
    ],
  } as TableModel<ConfigSettingItem>
  return (
    <div>
      <ContentTitle title={t("Security Configurations")} />
      <Card>

        <TableView
          enableTableSettings
          title={""}
          model={tableModel}
          onQueryChanged={(q: Query) => setQuery(q)}
          onRefresh={() => setQuery(clone(query))}
          canEdit={false}
          canDelete={false}
          error={currentState.errorMessage.value && formatMessage(currentState.errorMessage.value)}
          canCreateNew={false}
        >
        </TableView>
        {
          editConfigShown && <UpdateConfigView item={selectedItem} onClose={() => { setEditConfigShown(false); load(query) }} {...props} />
        }
      </Card>
    </div>
  )
}

export default withTranslation()(withRouter(withIdentity(ConfigList)))