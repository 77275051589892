import autobind from 'autobind-decorator'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Alert, Card, Button, Tag } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { getDefaultTableSettings, TableModel, TableView } from 'src/core/ui/collections/table'
import ContentTitle from 'src/core/ui/content-header'
import { CommandResult } from '../../../core/stores/types'
import { PatsStore, PatSummary } from '../../../stores/pats/pats-store'
import { clone, formatDate } from '../../../core/utils/object'
import { Query } from '../../../core/stores/data-store'
import { container } from '../../../inversify.config'
import { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import UserSelect from 'src/components/userEdit'
import TimeAgo from 'react-timeago'

// tslint:disable-next-line:no-empty-interface
interface PatListViewProps extends RouteComponentProps, WithTranslation {

}


const PatList: React.FC<PatListViewProps> = (props) => {

  const { t } = props
  const [userFilter, setUserFilter] = useState<any>()
  const [query, setQuery] = React.useState({
    searchQuery: '', skip: 0, take: getDefaultTableSettings().defaultPageSize, orderBy: [{ field: 'lastDateOfUse', direction: 'Descending', useProfile: false }], odataObject: {
      filter: {}
    },
  } as Query)


  const currentStore = React.useMemo(() => container.get(PatsStore), [])
  const currentState = currentStore.state

  React.useEffect(() => {
    load(query)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps


  const Build = (query: Query,  user: any): Query => {
    
    if (user) {
      var filterUser = {
        createdById: { eq: { type: 'guid', value: user.id } }
      }
      query.odataObject.filter.createdById = filterUser
    }
    else {
      query.odataObject.filter.createdById = undefined
    }
   
    return query
  }

  const load = (q: Query) => {
    currentStore.loadAll(Build(q, userFilter))
  }



  const onQueryChanged = (q: Query) => {
    setQuery(q)
  }

  const onUserFilterChange = (user: any) => {
    setUserFilter(user)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
    currentStore.loadAll(Build(newquery,user))
  }
  const onDeleteRow = async (item: PatSummary) => {
    var result = await currentStore.delete(item.id)
    load(query)
    return result;


  }


  const rightToolbar = (
    <React.Fragment>
     
      <li>
        <UserSelect minWidth={200} value={userFilter} placeholder={t("Select User...")} valueAsItemReference onChange={(value) => onUserFilterChange(value)} />
      </li>

    

    </React.Fragment>
  )


  const tableModel = {
    query: query,
    columns: [
      {
        sortable: true,
        field: 'name',
        title: t('Name'),
        renderer: (data) => <span>{data.name}  </span>,
        editor: (data) => <Input />
      },
      {
        field: 'expiresAt',
        title: t('Expires at'),
        renderer: (data) => <span>{data.expiresAt ? formatDate(data.expiresAt) : '-'}</span>,
        editor: (data) => <Input />
      },
      {
        field: 'createByName',
        title: t('Owner'),
        renderer: (data) => <Tag color="blue">{data.createByName}</Tag>,
        editor: (data) => <Input />
      },
      {
        field: 'lastDateOfUse',
        sortable: true,
        title: t('Last Date of Use'),
        renderer: (data) => <Tag color="green">{data.lastDateOfUse ? formatDate(data.lastDateOfUse) : '----'}</Tag>,
        editor: (data) => <Input />
      },
      {
        field: '',
        title: t(''),
        renderer: (data) => <Tag color="red"> <TimeAgo date={data.lastDateOfUse} /></Tag>,
        editor: (data) => <Input />
      },

    ],
    data: currentState.value,
    sortFields: [],
  } as TableModel<PatSummary>
  return (
    <div>
      <ContentTitle title={t("All Personal Access Tokens")} />
      <Card>
        <TableView
          enableTableSettings
          title={""}
          rightToolbar={rightToolbar}
          model={tableModel}
          onQueryChanged={(q: Query) => onQueryChanged(q)}
          onRefresh={() => setQuery(clone(query))}
          canEdit={false}
          canDelete={true}
          showDeleteStatusWindow
          onDeleteRow={(item) => onDeleteRow(item)}
          canCreateNew={true}>
        </TableView>

      </Card>
    </div>
  )
}

// Wire up the React component to the Redux store
export default withTranslation()(withRouter(PatList))
