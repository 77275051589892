import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'
import { RoleSummary } from '../roles/roles-store'

export interface UserSummary {
  id: string,
  locked: boolean,
  userName: string,
  firstName: string,
  lastName: string,
  validateScopes: boolean,
  email: string,
  modifiedOn: Date,
  areaId: string, //added to solve newUser issue
  areaName: string,
  draft: boolean,
  roles: RoleSummary[]
  hasPendingOperationRequest: boolean,
  jobTitle: string
  manager: string,
  teams: string[]
}

export interface UserReference {
  id: string
  userName: string
  areaId: string //added to solve newUser issue
}

export interface NewUserItem {
  userName: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmPassword: string,
  validateScopes: boolean,
  sendEmailInvite: boolean
  language: string,

  areaId: string,   //added to solve newUser issue
}

export interface UserItem {
  id: string,
  userName: string,
  firstName: string,
  lastName: string,
  language: string,
  email: string,
  validateScopes: boolean,
  modifiedOn: Date,

  jobTitle: string,
  manager: string,
  teams: string[],
  phoneNumber : string
  areaId: string, //added to solve newUser issue
  areaName: string;
  
}

export class UsersStore extends DataStore<UserSummary> {

  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/users`, [])
    this.publicUrl = baseUrl
  }
  public async inviteUser(email: string) {
    return await this.handleCallAsync(async () => {
      var response = await this.httpService.post<any, any>(`${this.baseUrl}/inviteUser/${email}`, {})
      return response.data
    })
  }
}

export class UserItemStore extends FormStore<UserItem, NewUserItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/users`)
    this.publicUrl = baseUrl
  }
  public async update(id: string, item: UserItem) {
    return this.save(id, item)
  }
}
