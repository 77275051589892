import * as React from 'react'
import { Form, Spin, Input, Modal, Row, Col, Alert, Checkbox, notification } from 'antd'
let FormItem = Form.Item
import autobind from 'autobind-decorator'
import { ConfigSettingItemStore, ConfigSettingItem } from 'src/stores/configSettings/config-setting-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { nameof } from 'src/core/utils/object'
import TextArea from 'antd/lib/input/TextArea'
import { container } from '../../../inversify.config'
import { ValidationRule } from 'lakmus'
import CodeEditor from 'src/common/code-editor'
import { SmileOutlined } from '@ant-design/icons'
import yaml from 'js-yaml'
import FloatInput from '../../../core/ui/awesome/floatComponent/input'
import FloatTextArea from '../../../core/ui/awesome/floatComponent/textArea'

interface UpdateConfigViewProps {
  onClose: () => void
  item: ConfigSettingItem
}



const UpdateConfigView: React.FC<UpdateConfigViewProps & WithTranslation> = (props) => {
  const { t } = props
  const [form] = Form.useForm<ConfigSettingItem>()
  const [initialValues, setInitialValues] = React.useState<ConfigSettingItem | undefined>()
  const [error, setError] = React.useState("")
  const currentStore = React.useMemo(() => container.get(ConfigSettingItemStore), []) as ConfigSettingItemStore
  const currentState = currentStore.state

  const [currentYamlValue, setCurrentYamlValue] = React.useState('')

  const inputRef = React.useRef()
  React.useEffect(
    () => {
      let value = JSON.parse(props.item.body) as any
      const yamlValue = props.item ? yaml.dump(value) : ''
      var initial = props.item
      initial.body = yamlValue
      setInitialValues(initial)
    }, [inputRef]
  )

  const onCancel = () => {
    props.onClose()
  }

  const onSave = async () => {
    setError("")
    let item: ConfigSettingItem
    try {
      item = (await form.validateFields()) as ConfigSettingItem

    } catch {
      return
    }

    let yamlResult: any = null
    try {
      yamlResult = yaml.load(item.body)
    } catch (e: any) {
      setError("Invalid yaml format")
      return
    }

    let jsonResult = JSON.stringify(yamlResult)
    item.body = jsonResult
    let response = await currentStore.save(props.item.id, item)
    if (response) {
      props.onClose()
      notification.open({
        message: t("Changes have been saved"),
        onClick: () => {
        },
        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      })
    }
  }
  const onValidateURIs = (rule: any, value: string, callback: Function) => {
    if (value !== null) {
      var lines = value.split("/\r?\n/")
      if (lines.any(ln => ln.length > 2000))
        callback(false)
    }
    callback()
  }


  return (
    <Modal
      maskClosable={false}
      visible
      width={800}
      onCancel={onCancel}
      onOk={onSave}
      closable={false}
      title={t('Edit Config')}
      cancelText={t('Cancel')}
      okText={t('Ok')}
    >
      {currentState.errorMessage.get() && <Alert type="error" message={t(currentState.errorMessage.value || '')} />}
      {error && <Alert type="error" message={t(error || '')} />}
      <Spin spinning={currentState.isBusy.get() || !initialValues}>
        {initialValues &&
          <Form layout="vertical" form={form} initialValues={initialValues}>
            <FormItem
              name={nameof<ConfigSettingItem>('title')}
              rules={[
                { required: true, message: t('Please input your Config title!') },
                { max: 200, message: t('Config Id must have less than 200 chars.') }
              ]}
            >

              <FloatInput label={t("Title")} placeholder={t("Title")} />
            </FormItem>
            <FormItem
              name={nameof<ConfigSettingItem>('description')}
              rules={[
              ]}
            >

              <FloatTextArea label={t("Description")} placeholder={t("Description")} />
            </FormItem>

            <FormItem label={t("Config Value")}
              name={nameof<ConfigSettingItem>('body')}
              rules={[
              ]}
            >

              <CodeEditor
                onChange={setCurrentYamlValue}
                classSuffix="fi-code-editor"
                height={300}
                language="yaml"
              />
            </FormItem>

          </Form>}
      </Spin>
    </Modal>
  )
}
export default withTranslation()(UpdateConfigView)
