import FormStore from 'src/core/stores/form-store';
import { CommandResult } from 'src/core/stores/types';

export interface TenantHierarchyTreeNode {
  id: string;
  title: string;
  children: TenantHierarchyTreeNode[];
  expanded: true;
}

export class TenantHierarchyTreeStore extends FormStore<TenantHierarchyTreeNode, {}> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/tenants/{tenantId}`);
  }

  public async load(tenantId: string): Promise<TenantHierarchyTreeNode> {
    return await this.handleCallAsync(async () => {
      const response = await this.httpService.get<TenantHierarchyTreeNode>(
        `${this.baseUrl.replace('{tenantId}', encodeURIComponent(tenantId))}/hierarchy-tree`
      );
      this._state.set((s) => {
        s.item = response.data as TenantHierarchyTreeNode;
        return s;
      });
      return response.data;
    });
  }

  public async changeParent(tenantId: string, newParentId: string | null) {
    const response = await this.httpService.put<{ newParentId: string | null }, CommandResult<any>>(
      `${this.baseUrl.replace('{tenantId}', encodeURIComponent(tenantId))}/change-parent`,
      { newParentId: newParentId }
    );
    return response.data;
  }
}
