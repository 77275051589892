import React, { useEffect, useState } from 'react'
import HttpService from 'src/core/services/http.service'
import { Form, Row, Button, Input, Modal, Alert, Spin } from 'antd'
import * as queryString from 'query-string';
import { withRouter, RouteComponentProps } from "react-router"
import { withTranslation, WithTranslation } from 'react-i18next'
import { BaseAppUrl, container } from '../../inversify.config'
import { LockOutlined, WarningOutlined } from '@ant-design/icons'
import QueueAnim from 'rc-queue-anim'

export interface ChangePasswordExpiredProps extends WithTranslation, RouteComponentProps {
    children?: React.ReactNode,
    user: any,
    token: any,
    setIsChangingPassword: any
}

const ChangePasswordExpired: React.FC<ChangePasswordExpiredProps> = (props) => {
    const { t, user, token, setIsChangingPassword } = props

    const httpService: HttpService = container.get(HttpService)

    const [errors, setErrors] = React.useState([])
    const [success, setSuccess] = React.useState(false)
    const [busy, setBusy] = useState(false);
    const [form] = Form.useForm<any>()

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        try {
            setBusy(true)
            var values = (await form.validateFields())
            if (values.password != values.confirmPassword) {
                setErrors(["Passwords don't match"])
                setSuccess(false)
                setBusy(false)
                return
            }
            httpService.post<any, any>(`${BaseAppUrl}/api/v1/account/changePassword`, {
                identityName: user,
                token: token,
                newPassword: values.password
            }).then(result => {
                if (!result.data.success) {
                    setErrors(result.data.errors)
                    setSuccess(false)
                } else {
                    setErrors([])
                    setSuccess(true)

                    httpService.post<any, any>(`${BaseAppUrl}/api/v1/account/login`, {
                        username: user,
                        password: values.password,
                        rememberLogin: true,
                        returnUrl: ''
                    });

                    if (!result.data.success) {
                        setSuccess(false);
                        setErrors(result.data.errors);
                    }
                    else {
                        setSuccess(true);
                        setErrors([]);
                        const parsed = queryString.parse(window.location.search || '');
                        const returnUrl = result.data.redirectTo ? result.data.redirectTo : parsed.ReturnUrl || parsed.returnUrl || parsed.returnurl;
                        if (returnUrl) {
                            window.location.href = returnUrl as string;
                        } else {
                            window.location.href = `${BaseAppUrl}`;
                        }
                    }
                }
            }).catch(error => {
                if (error.response && !error.response.data.success) {
                    setSuccess(false)
                    setErrors(Array.isArray(error.response.data.errors) ? error.response.data.errors : [error.response.data.title])
                    return
                }
                Modal.error({
                    title: 'Error',
                    content: `${error}`,
                })
            })
            setBusy(false);
        } catch (error) {
            setSuccess(false)
            setErrors([error]);
            return;
        }
    }

    useEffect(() => {
        if (errors.length > 0) {
            setTimeout(() => {
                setErrors([])
            }, 10000);
        }
    }, [errors]);

    return (
        <>
            <WarningOutlined style={{ fontSize: 48 }} />
            <p>{t('Your password has expired, please create a new password to login.')}</p>
            <Form size="large" form={form} layout="vertical" className='password-form'>

                {errors && errors.length > 0 && errors.map(error =>
                    <QueueAnim animConfig={[{ opacity: [1, 0], translateY: [0, 50] }]}>
                        <div className='error-message' key="a">
                            <span>x</span>
                            <p >{t(error)}</p>
                        </div>
                    </QueueAnim>
                )}

                {success && <Alert message={t("Password has been successfully changed")} type="success" showIcon />}

                <Spin spinning={busy}>
                    <Row className='password-fields-expired'>
                        <Form.Item
                            label={t('Password')}
                            name={'password'}
                            rules={[{ required: true, message: t('Enter your password') }]}
                        >
                            <Input autoComplete="off" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={t('Password')} />
                        </Form.Item>

                        <Form.Item
                            label={t('Confirm password')}
                            name={'confirmPassword'}
                            rules={[{ required: true, message: t('Re-enter your password') }]}
                        >
                            <Input autoComplete="off" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={t('Password')} />
                        </Form.Item>
                    </Row>

                    <Row className='password-buttons'>
                        <Button type="primary" onClick={() => setIsChangingPassword(false)} >{t('Cancel')}</Button>
                        <Button type="primary" onClick={handleSubmit}>{t('Change Password')}</Button>
                    </Row>
                </Spin>

            </Form>
        </>
    )
}

export default withTranslation()(withRouter(ChangePasswordExpired) as any)