import { FC, useState, useMemo } from 'react'
import { Form, Spin, Input, Modal, Row, Col, Alert, Checkbox } from 'antd'
let FormItem = Form.Item
import { NewUserItem, UserItemStore, } from 'src/stores/users/users-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import RoleEditor from 'src/components/roleEdit'
import ApplicationEditor from 'src/components/applicationEdit'
import AreaEditor from 'src/components/areaEdit'
import { nameof } from 'src/core/utils/object'
import { v4 as uuidv4 } from 'uuid'
import LangEditor from 'src/components/langEdit'
import { container } from 'src/inversify.config'
import FloatInput from "src/core/ui/awesome/floatComponent/input"

interface NewUserViewProps {
  onClose: () => void
  applicationId?: string
}

interface NewUserViewState {
  createPassword: boolean
}

const NewUserView: FC<NewUserViewProps & WithTranslation> = (props) => {

  const { t } = props
  const userStore = useMemo(() => container.get(UserItemStore), [])
  const userStoreState = userStore.state
  const [createPassword, setCreatePassword] = useState(false)
  const [form] = Form.useForm<NewUserItem>()
  const [initialValues, setInitialValues] = useState<NewUserItem | undefined>(undefined)
  const applicationId = props.applicationId ? props.applicationId : form.getFieldValue("applicationId")
  const [selectedAppId, setSelectedAppId] = useState<string | undefined>()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const onCancelNewItem = () => {
    userStore.clear()
    props.onClose()
  }

  const onCreateNewItem = async () => {
    if (!createPassword) {
      var passwd = `A-_0*${uuidv4()}`
      form.setFieldsValue({ "password": passwd })
      form.setFieldsValue({ "confirmPassword": passwd })
    }
    let item: any
    try {
      item = (await form.validateFields())
    } catch {
      return
    }
    setConfirmLoading(true)
    item.areaId = (item.areaId as any).id || item.areaId
    let response = await userStore.create(item)
    if (response) {
      form.resetFields()
      props.onClose()
    }
    setConfirmLoading(false)
  }
  return (
    <Modal
      maskClosable={false}
      visible
      confirmLoading={confirmLoading}
      onCancel={onCancelNewItem}
      onOk={onCreateNewItem}
      closable={false}
      title={t('New user')}
      cancelText={t('Cancel')}
      okText={t('Ok')}
    >
      {userStoreState.errorMessage.get() && <Alert type="error" message={t(userStoreState.errorMessage.value || '')} />}
      <Spin spinning={userStoreState.isBusy.get()}>
        <Form form={form} autoComplete="off" layout="vertical" initialValues={initialValues}>
          <FormItem name={nameof<NewUserItem>('userName')} rules={[{ required: true, message: t('Please input your username!') }]}>
            <FloatInput required label={t("Username")} placeholder={t("Username")} />
          </FormItem>
          <FormItem name={nameof<NewUserItem>('email')} rules={[
            { required: true, message: t('Please input your email!') },
            { type: 'email', message: t('Invalid email address.') }
          ]}>
            <FloatInput required label={t("Email")} placeholder={t("Email")} />
          </FormItem>
          <FormItem name={nameof<NewUserItem>('firstName')} rules={[{ required: true, message: t('Please input your first name!') }]}>
            <FloatInput required label={t("First name")} placeholder={t("First name")} />
          </FormItem>
          <FormItem name={nameof<NewUserItem>('lastName')} rules={[{ required: true, message: t('Please input your last name!') }]}>
            <FloatInput required label={t("Last name")} placeholder={t("Last name")} />
          </FormItem>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem name={nameof<NewUserItem>('sendEmailInvite')} rules={[]} valuePropName={'checked'}>
                <Checkbox><span>{t("Send email invite")}</span></Checkbox>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem>
                <Checkbox onChange={(value) => setCreatePassword(value.target.checked)}>{t("Create Password")}</Checkbox>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} hidden={!createPassword}>
            <Col span={12}>
              <FormItem name={nameof<NewUserItem>('password')} rules={[{ required: true, message: t('Please input your password!') }]}>
                <FloatInput label={t("Password")} required placeholder={t("Password")} type="password" autoComplete="off" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name={nameof<NewUserItem>('confirmPassword')} rules={[{ required: true, message: t('Please confirm your password!') }]}>
                <FloatInput label={t("Confirm password")} required placeholder={t("Confirm password")} type="password" autoComplete="off" />
              </FormItem>
            </Col>
          </Row>


          <FormItem  name={nameof<NewUserItem>('areaId')} rules={[{ required: true, message: t('Area required') }]}>
            <AreaEditor required label={t("Area")} valueAsItemReference />
          </FormItem>
          <FormItem name={nameof<NewUserItem>('language')} rules={[{ required: true, message: t('Please select your preferred language!') }]}>
            <LangEditor required label={t("Preferred language")} placeholder={t("Preferred language")} />
          </FormItem>
          <FormItem name={nameof<NewUserItem>('validateScopes')} rules={[]} valuePropName={'checked'}>
            <Checkbox><span>{t("Restrict client access by scope")}</span></Checkbox>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  )
}

// Wire up the React component to the Redux store
export default withTranslation()(NewUserView)
