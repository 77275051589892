import DataStore from 'src/core/stores/data-store'
import FormStore from 'src/core/stores/form-store'
import { CommandResult } from 'src/core/stores/types'
import { IdentityType } from '../tenants/common'

export interface TenantIdentitySummary {
  identityId: string
  displayName: string
  userName: string;
  email: string
  identityType: IdentityType
  tenants: TenantItem[],
}

export interface TenantIdentityGroupItem {
  id: string
  name: string
  description: string
  userIsOwner: boolean
  tenants: TenantItem[]
}

export interface TenantItem {
  id: string
  title: string
  hierarchy: string
}


export interface AddTenantIdentityGroupItem {
  userId: string
  groupId: string
  permission: string

}

export interface DeleteTenantIdentity {
  identityId: string
  tenantId: string
}

export class TenantIdentityDataStore extends DataStore<TenantIdentitySummary> {
  baseUrlMembers: string
  entityId: string | null = null;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/tenantIdentities`, [])
    this.baseUrlMembers = this.baseUrl
  }

  public async Get(userId: string) {
    return await this.handleCallAsync(async () => {
      var url = `${this.baseUrl}/${userId}`
      var response = await this.httpService.get<TenantIdentitySummary>(url)
      return response.data

    })
  }

  public async Delete(data: DeleteTenantIdentity) {
    return await this.handleCallAsync(async () => {
      var url = `${this.baseUrl}`
      var response = await this.httpService.delete<DeleteTenantIdentity,any>(url,data)
      return response.data

    })
  }

}

export interface GroupMemberSummary {

  groupId: string,
  groupName: string,
  userId: string,
  userName: string,
  permission: string
}


export class GroupMemberSummaryStore extends DataStore<GroupMemberSummary> {
  baseUrlMembers: string
  entityId: string | null = null;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/tenantIdentities/groups`, [])
    this.baseUrlMembers = this.baseUrl
  }

  public setGroupId(groupId: string) {
    this.baseUrl = this.baseUrlMembers + "/" + groupId + "/users"
  }

}

export class TenantIdentityGroupsDataStore extends DataStore<TenantIdentitySummary> {
  baseUrlMembers: string
  entityId: string | null = null;

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/tenantIdentities/groups`, [])
    this.baseUrlMembers = `${baseUrl}/api/v1/admin/tenantIdentities`
  }

  public async Delete(data: DeleteTenantIdentity) {
    return await this.handleCallAsync(async () => {
      var url = `${this.baseUrlMembers}`
      var response = await this.httpService.delete<DeleteTenantIdentity, any>(url, data)
      return response.data

    })
  }
}

export class TenantIdentityGroupStore extends FormStore<TenantIdentityGroupItem, AddTenantIdentityGroupItem> {

  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/tenantIdentities/groups`)
  }

  public async Get(groupId: string) {
    return await this.handleCallAsync(async () => {
      var url = `${this.baseUrl}/${groupId}`
      var response = await this.httpService.get<TenantIdentityGroupItem>(url)
      return response.data

    })

  }
  

  public async addGroupMember(data: AddTenantIdentityGroupItem) {
    var url = `${this.baseUrl}/${data.groupId}/users?`
    return await this.handleCallAsync(async () => {
      var result = await this.httpService.post<AddTenantIdentityGroupItem, CommandResult<any>>(url, data)
      return result.data
    })
  }
}