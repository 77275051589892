import { Badge, Pagination, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import 'react-sortable-tree/style.css';
import { container } from 'src/inversify.config';
import { NotificationItem, NotificationItemStore } from 'src/stores/systemNotifications/notification-store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Query } from '../../core/stores/data-store';
import NotificationView from './notification-Item';

export interface NotificationListProps extends WithTranslation {
  reload: number;
  setNotificationCount: (count: number) => void;
  visible: boolean;
}

const NotificationList: FC<NotificationListProps> = ({ t, reload, setNotificationCount, visible }) => {
  const [query] = useState({
    searchQuery: '',
    orderBy: [{ field: 'creationTime', direction: 'Descending', useProfile: false }],
    skip: 0,
    take: 100,
  } as Query);

  const [wasOpen, setWasOpen] = useState(-1);

  const notificationStore = container.get<NotificationItemStore>(NotificationItemStore);
  const notificationState = notificationStore.state;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
     load();
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (visible) {
      setWasOpen(1)
      load()
    }
    else{(wasOpen > 0) ? setNotificationReadStatus() : load()}    
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps

  const setNotificationReadStatus = async () => {
    await notificationStore.SetReadStatus();
    await load();
  };

  const load = async () => {
    await notificationStore.load(query);
    setNotificationCount(notificationState.items.get().filter((x) => !x.readed).length);
  };

  const remove = async (not: NotificationItem) => {
    await notificationStore.delete(not.id);
    await load();
  };

  const clear = async () => {
    await notificationStore.deleteAll();
    await load();
  };

  if (!notificationState.count.get() || notificationState.count.get() === 0) {
    return <h2>{t('No Notifications')}</h2>;
  }

  //Pagination  
  const pageSizeOptions = ['10', '25', '50'];
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = notificationState.items.get().slice(startIndex, endIndex);

  return (
    <div data-testid="openNotificationsButton" className="notification-content">
      <Spin spinning={notificationState.isBusy.get()}>
        <Badge count={notificationState.items.get().filter((x) => !x.readed).length}>
          <h2>{t('Notifications')}</h2>
        </Badge>
        <span style={{ fontSize: '16px', marginLeft: '10px' }}>{'(' + notificationState.count.get() + ')'} </span>
        <button type='button' className='ant-btn ant-btn-default' style={{ float: 'right' }} onClick={clear}> {t('Clear All')} </button>


        {currentData.map((x) => (
          <NotificationView key={x.id} notification={x} removeNotification={() => remove(x)} />
        ))}

        <div className="pagination-sticky-ala">
          <Pagination
            className='pagination-sticky'
            current={currentPage}
            total={notificationState.items.get().length}
            pageSize={pageSize}
            onShowSizeChange={(current, size) => {
              setCurrentPage(1);
              setPageSize(size);
            }}
            showSizeChanger
            pageSizeOptions={pageSizeOptions}
            onChange={(page) => setCurrentPage(page)}
          />
        </div>
      </Spin>
    </div>
  );
};

export default withTranslation()(NotificationList);
