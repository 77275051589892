import * as React from 'react'
import { Row, Col, Card } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import HttpService from 'src/core/services/http.service';
import PrivacyRightsExcerciseFormView from './privacy-rights-excercise-form';
import ContentTitle from 'src/core/ui/content-header';
import { FC } from 'react';

interface PrivacyRightsExcerciseViewProps extends WithTranslation {
}


const PrivacyRightsExcerciseView: FC<PrivacyRightsExcerciseViewProps> = (props) => {

  const { t } = props

        return <div>
            <ContentTitle type={t('EXERCISE YOUR RIGHTS')} title={t('RIGHTS AND EXERCISE OF THE INTERESTED PARTIES')} />
            <div id="excercise-rigths-form">
                <Card>
                    <Row gutter={10}>
                        <Col xl={{ span: 10 }} md={{ span: 24 }}>
                            <p><strong>{t('EXERCISE YOUR RIGHTS')}</strong></p>
                            <h3 style={{ color: '#00AEFF' }}>{t('RIGHTS AND EXERCISE OF THE INTERESTED PARTIES')}</h3>
                            <p>{t('rights-intro')}</p>
                            <br />
                            <p><strong>{t('Request for access to your personal data')}</strong> {t('personal-data-info')}</p>
                            <br />
                            <p><strong>{t('Request for rectification of your personal data')}</strong> {t('data-rectification-info')}</p>
                            <br />
                            <p><strong>{t('Request to delete your personal data')}</strong> {t('data-delete-info')}</p>
                            <br />
                            <p><strong>{t('Request for limitation of treatment')}</strong> {t('limitation-treatment-info')}</p>
                            <br />
                            <p><strong>{t('Data portability request')}</strong> {t('data-portability-info')}</p>
                            <br />
                            <p><strong>{t('Request to object to data processing')}</strong> {t('object-data-info')}</p>
                            <br />
                            <p><strong>{t('Request for denial of profiling')}</strong> {t('denial-profiling-info')}</p>
                        </Col>
                        <Col xl={{ span: 14 }} md={{ span: 24 }}><PrivacyRightsExcerciseFormView></PrivacyRightsExcerciseFormView></Col>
                    </Row>
                </Card>
            </div>
        </div>
}

export default (withTranslation()(PrivacyRightsExcerciseView)) as any