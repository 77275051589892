import { Input, DatePicker, Select, Spin, Checkbox, Row, Col, Upload, Button, Alert } from 'antd'
const { Dragger } = Upload
let FormItem = Form.Item
import { nameof } from 'src/core/utils/object'
import { withTranslation, WithTranslation } from 'react-i18next'
import Form from 'antd/lib/form'
import HttpService from 'src/core/services/http.service'
import { container } from 'src/inversify.config'
import moment, { Moment } from 'moment'
import { notify, FloatingNotificationSettings } from 'src/components/floatingNotification'
import autobind from 'autobind-decorator'
import { InboxOutlined } from '@ant-design/icons'
import React from 'react'
import FloatDatePicker from '../../core/ui/awesome/floatComponent/datePicker'
import FloatInput from '../../core/ui/awesome/floatComponent/input'
import FloatSelect from '../../core/ui/awesome/floatComponent/select'
import FloatTextArea from '../../core/ui/awesome/floatComponent/textArea'

interface PrivacyRequestExcerciseItem {
  requestDate: Date
  fullName: string
  email: string
  requestType: string
  representativeData: string
  requestDetails: string
  rightsToExcercise: string[]
  identificationDocuments: any[]
}

interface PrivacyRightsExcerciseFormViewProps extends WithTranslation {
  onUpdateItem: (values: any) => Promise<any>
}

interface PrivacyRightsExcerciseFormViewState {
  isBusy: boolean
  files: any[]
  error?: string
}

const PrivacyRightsExcerciseFormView: React.FC<PrivacyRightsExcerciseFormViewProps> = (props) => {
  const [isBusy, setIsBusy] = React.useState(false)
  const [form] = Form.useForm<PrivacyRequestExcerciseItem>()
  const [files, setFiles] = React.useState([])
  const [error, setError] = React.useState<string>()
  const { t } = props
  const httpService = container.get(HttpService)

  const onSend = async () => {

    setIsBusy(true)
    try {
      var values = (await form.validateFields()) as PrivacyRequestExcerciseItem
      const bodyFormData = new FormData()
      bodyFormData.append('email', values.email)
      bodyFormData.append('fullName', values.fullName)
      if (values.representativeData)
        bodyFormData.append('representativeData', values.representativeData)
      bodyFormData.append('requestDate', moment(values.requestDate).format("DD/MM/yyyy") as any)
      if (values.requestDetails)
        bodyFormData.append('requestDetails', values.requestDetails)
      bodyFormData.append('rightsToExcercise', JSON.stringify(values.rightsToExcercise))
      bodyFormData.append('requestType', values.requestType)
      files.forEach((file: any) => {
        bodyFormData.append('files[]', file)
      })
      httpService.post(`${process.env.PUBLIC_URL}/api/v1/profile/excerciseRights`, bodyFormData)
        .then(result => {
          const notificationSettings: FloatingNotificationSettings = {
            type: 'success',
            autoClose: true,
            key: 'requestSent',
            message: props.t('Successful action'),
            description: props.t('Request sent successfully')
          }
          notify(notificationSettings)
          form.resetFields()
          setIsBusy(false)
          setFiles([])
        })
        .catch(error => {
          setIsBusy(false)
          setError(error.messages)
        })
    } catch (e: any) {
      console.log(e)
      setIsBusy(false)
    }

  }

  const validateFileSizes = (rule: any, value: any, callback: any) => {



    let filesSizeSum = 0
    if (value && value.fileList) {
      value.fileList.forEach((file: any) => {
        filesSizeSum += file.size
      })
      if (filesSizeSum > 2 * 1024 * 1024) {
        callback(t('2MB files size limit exceeded'))
      }
    }
    callback()
  }

  return <Spin spinning={isBusy}>
    {error && <Alert type="error" message={error}></Alert>}
    <Form form={form} layout={'vertical'}>
      <FormItem name={"requestDate"} rules={[{ required: true, message: t('Please input the request date!') }]} initialValue={moment()} >
        <FloatDatePicker required label={t("Request date")} placeholder={t("Request date")} style={{ minWidth: 200 }} />
      </FormItem>

      <FormItem name={"fullName"} rules={[{ required: true, message: t('Please input the name of the interested!') }]}>
        <FloatInput required label={t("Name and surname of the interested")} placeholder={t("Full name")} />
      </FormItem>

      <FormItem name={nameof<PrivacyRequestExcerciseItem>('email')}
        rules={[
          { required: true, message: t('Please input the email!') },
          { type: 'email', message: t('Please type a valid email!') }]}
      >

        <FloatInput required label={t("Email")} placeholder={t("Email")} />

      </FormItem>


      <FormItem name={nameof<PrivacyRequestExcerciseItem>('requestType')} rules={[{ required: true, message: t('Please input the request type!') }]} >

        <FloatSelect
          style={{width : "100%"} }
          required
          label={t("Please indicate if it is a direct request or through a representative")}
          placeholder={t("Please indicate if it is a direct request or through a representative")}
          >
          <Select.Option value="Direct">{t('Direct request')}</Select.Option>
          <Select.Option value="Representative">{t('Request through a representative')}</Select.Option>
        </FloatSelect>
      </FormItem>


      <FormItem name={nameof<PrivacyRequestExcerciseItem>('representativeData')} rules={[]}>

        <FloatInput label={t("In case of being requested by representation, please indicate the representative's data")} placeholder={t("In case of being requested by representation, please indicate the representative's data")} />
      </FormItem>


      <FormItem rules={[{ required: true, message: t('Please check at least one right to exercise!') }]} name={nameof<PrivacyRequestExcerciseItem>('rightsToExcercise')} label={t("What right do you want to exercise?")}>

        <Checkbox.Group style={{ width: '100%', marginBottom : "10px" }}>
          <Row>
            <Col span={24}>
              <Checkbox value="Access">{t('Right of access')}</Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Checkbox value="Information">{t('Right to be informed (that is, to obtain a copy of the same that may be in the possession of the company)')}</Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Checkbox value="Correction">{t('Right to rectification (that is, to correct personal data, or to complete incomplete data)')}</Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Checkbox value="TreatmentOpisition">{t('Right to object to treatment')}</Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Checkbox value="Supression">{t('Right to erasure (right to be forgotten)')}</Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Checkbox value="DataPortability">{t('Right to data portability')}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </FormItem>

      <FormItem name={nameof<PrivacyRequestExcerciseItem>('requestDetails')} >

        <FloatTextArea placeholder={t("To help us process your request efficiently, please provide as much detail as possible about the personal data you request to exercise your right")} label={t("To help us process your request efficiently, please provide as much detail as possible about the personal data you request to exercise your right")} />
      </FormItem>

      <FormItem name={nameof<PrivacyRequestExcerciseItem>('identificationDocuments')} rules={[
        { required: true, message: t('Please add the required documents!') },
        { validator: validateFileSizes }
      ]} label={t('Attach to this application a copy of your identification, or where appropriate, a copy of the order and identification of the representative')}
      >
        <Dragger fileList={files} accept={".pdf,.doc,.docx,.png,.jpg,.jpeg"} beforeUpload={(file: any) => {
          setFiles([...files, file])
          return false
        }} onRemove={(file: any) => {
          const index = files.indexOf(file)
          const newFileList = files.slice()
          newFileList.splice(index, 1)
          setFiles(newFileList)
        }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
          <p className="ant-upload-hint">
            {t('(2MB size limit)')}
          </p>
        </Dragger>
      </FormItem>
      <Button type="primary" onClick={onSend} style={{ float: 'right', marginTop: 14 }}>{t('Send')}</Button>
    </Form>
  </Spin>
}

export default withTranslation()(PrivacyRightsExcerciseFormView) as any