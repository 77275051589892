import React, { FC, useEffect, useState, useMemo } from 'react'
import { WithTranslation } from 'react-i18next'
import { Spin, Alert, Modal, Tag, Radio, Button, Tooltip, notification, Switch, Card, Avatar, Row, Col, Select, message } from 'antd'
import { container } from 'src/inversify.config'
import {
  TenantClientMemberDataStore,
  TenantClientMember,
} from 'src/stores/tenants/tenant-members-store'
import { formatDate, nameof } from 'src/core/utils/object'
import HttpService, { formatMessage } from 'src/core/services/http.service'
import { UserSummary, UsersStore } from 'src/stores/users/users-store'
import Form from 'antd/lib/form'
import UserSelect from 'src/components/userEdit'
import GroupSelect from 'src/components/groupEdit'
import RolesSelect from 'src/components/roleEdit'
import ApplicationsSelect from 'src/components/applicationEdit'
import { HierarchyType, IdentityType } from 'src/stores/tenants/common'
import { Query } from 'src/core/stores/data-store'
import { TableModel, TableView, getDefaultTableSettings } from 'src/core/ui/collections/table'
import { DownloadOutlined, EditOutlined, ExportOutlined, TeamOutlined, UserOutlined, SearchOutlined, FormOutlined, LockOutlined, UnlockOutlined, ClockCircleOutlined, CheckCircleOutlined, GoldOutlined, MailOutlined } from '@ant-design/icons'
import EditMemberDialog from 'src/pages/admin/tenants/components/identity-tenant-roles-editor'
import FileSaver from 'file-saver'
import { IdentityProps } from "src/core/services/authentication"
import { Link } from 'react-router-dom'
import CreateOperationRequestView from './permissions/create-poermission'
import BulkUploadMenu from '../../../../components/bulk-upload/bulk-upload-menu'

export const ProfileTenantMembers: FC<{ entityId: string } & WithTranslation & IdentityProps> = (props) => {
  const { t, entityId } = props
  const entityMemberDataStore = container.get<TenantClientMemberDataStore>(TenantClientMemberDataStore)
  const entityMemberDataState = entityMemberDataStore.state
  const httpService = container.get(HttpService)
  const isAdmin = props.identity.roles && props.identity.roles.some(role => role === "Administrator")
  const [isTenantAdmin] = useState(props.identity.roles != undefined && props.identity.roles.some(value => value === "TenantAdministrator"))

  const [selectedUser, setSelectedUser] = useState(undefined)
  const [editMemberVisible, setEditMemberVisible] = useState(false)
  const [newUserShown, setNewUserShown] = useState(false)
  const [editUserShown, setEditUserShown] = useState(false)
  const usersStore = useMemo(() => container.get(UsersStore), [])

  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'modifiedOn', direction: 'Descending', useProfile: false }],
    skip: 0,
    take: getDefaultTableSettings().defaultPageSize,
    parameters: {
      "excludeOperationRequest": "true"
    }
  } as Query)

  useEffect(() => {
    entityMemberDataStore.setTenantId(entityId)
    entityMemberDataStore.load(query)

  }, [entityId, query]) // eslint-disable-line react-hooks/exhaustive-deps


  const load = (q: Query) => {
    usersStore.load(q)
  }

  const [messageApi, contextHolder] = message.useMessage()
  const inviteUser = async (email: string) => {
    var result = await usersStore.inviteUser(email)
    if (result) {
      messageApi.open({
        type: 'success',
        content: t('Invitation mail sent successfully.'),
      })
      load(query)
    }
    else {
      messageApi.open({
        type: 'error',
        content: t('Failed to send invitation mail. Please try again later.'),
      })
    }
  }


  const GetStatus = (user: UserSummary) => {
    if (user.draft) {
      return <span>
        <Tooltip title={t("Draft")}><Avatar style={{ background: 'blue' }} size={30} icon={<FormOutlined />} /></Tooltip>
      </span>
    }
    if (user.locked) {
      return <span className='button-unlock'>
        <Tooltip title={t("Click to unlock")}><Avatar style={{ background: 'red' }} size={30} icon={<UnlockOutlined />} /></Tooltip>
      </span>
    }
    return <span><Tooltip title={t("Active")}><Avatar style={{ background: 'green' }} size={30} icon={<UserOutlined />} /></Tooltip></span>

  }

  const GetTitle = (user: UserSummary) => {
    if (user.draft) {
      return <span style={{ cursor: "pointer", fontSize: "12px" }} onClick={() => { setSelectedUser(user.id); setEditUserShown(true) }}>  <LockOutlined /> &nbsp;<span style={{ textDecoration: 'line-through' }}>{user.userName} </span> </span>
    }
    return <span style={{ cursor: "pointer", fontSize: "12px" }} onClick={() => { setSelectedUser(user.id); setEditUserShown(true) }} > {user.locked ? <LockOutlined /> : <UserOutlined />} &nbsp; <span style={{ textDecoration: user.locked ? 'line-through' : 'none' }}>{user.userName} </span></span >

  }



  const tableModel = {
    query: query,
    columns: [
      {
        field: 'firstName',
        title: t('Name'),
        renderer: (data) => <span>{data.firstName} {data.lastName}</span>,
      },
      {
        sortable: true,
        centered: true,
        field: 'userName',
        title: t('User name'),
        renderer: (data) => GetTitle(data)
      },
      {
        field: 'email',
        title: t('Email'),
        renderer: (data) => <span style={{ fontSize: "12px" }}>{data.email}</span>,
      },
      {
        field: 'modifiedOn',
        title: t('Modified on'),
        sortable: true,
        align: "center",
        renderer: (data) => <span style={{ fontSize: 10 }}>{formatDate(data.modifiedOn)}</span>,
      },
      {
        field: 'invite',
        title: t(''),
        width: 50,
        align: 'center',
        renderer: (data) => <span>{data.draft ? <></> : <Tooltip title={t("Send email invite")}><MailOutlined onClick={() => inviteUser(data.email)} /></Tooltip>}</span>
      },
      {
        title: "",
        width: '5%',
        fixed: "right",
        renderer: (data) => (
          <div style={{ textAlign: 'center' }}>
            <Tooltip title="Edit Permissions">
              <SearchOutlined style={{ cursor: 'pointer', fontSize: 25 }}
                onClick={() => {
                  setSelectedUser(data.id)
                  setEditMemberVisible(true)
                }}
              />
            </Tooltip>
          </div>
        ),
      },

    ],
    data: entityMemberDataState.value,
    sortFields: [],
  } as TableModel<TenantClientMember>


  const [internal, setInternal] = useState()

  const onUserTypeChange = (value) => {
    setInternal(value)
    query.skip = 0
    query.parameters.external = value
    setQuery(query)
    entityMemberDataStore.load(query)
  }
  const rightToolbar = (
    <>
      <li>
        <Select defaultValue={internal}
          allowClear placeholder={t("Select user type")}
          style={{ width: 180 }}
          onChange={(value) => onUserTypeChange(value)}
        >
          <Select.Option value={1}>
            <span><Tag style={{ color: 'white' }} color={"rgb(14,14,79)"}>{t("Internal")}</Tag></span>
          </Select.Option>
          <Select.Option value={2}>
            <span><Tag style={{ color: 'white' }} color={"rgb(255,0,0)"}>{t("External")}</Tag></span>
          </Select.Option>
        </Select>
      </li>


    </>
  )




  return (
    <>
      {contextHolder}
      <TableView
        onQueryChanged={(query: Query) => {
          setQuery(query)
          entityMemberDataStore.load(query)
        }}
        enableTableSettings
        scroll={{ x: 1700 }}
        /* rightToolbar={rightToolbar}*/
        canCreateNew={true}
        onNewItem={() => setNewUserShown(true)}
        onRefresh={() => entityMemberDataStore.load(query)}
        model={tableModel}
        error={entityMemberDataState.errorMessage.value && formatMessage(entityMemberDataState.errorMessage.value)}
      />
      {
        newUserShown && <CreateOperationRequestView selectedTenantId={entityId} {...props} onClose={() => { setNewUserShown(false); entityMemberDataStore.load(query) }} />
      }
      {
        editUserShown && <CreateOperationRequestView selectedUserId={selectedUser} selectedTenantId={entityId} {...props} onClose={() => { setEditUserShown(false); entityMemberDataStore.load(query) }} />
      }
      {editMemberVisible && (
        <CreateOperationRequestView selectedUserId={selectedUser} step={1} selectedTenantId={entityId} {...props} onClose={() => { setEditMemberVisible(false); entityMemberDataStore.load(query) }} />
      )}
    </>
  )
}