import { NotificationOutlined } from '@ant-design/icons'
import { Badge } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import SideView from '../side-view'
import NotificationList from './notification-list'
import { HubConnectionBuilder } from '@microsoft/signalr'
import { infoNotification } from './notificationService'
import { IdentityProps, withIdentity } from '../../core/services/authentication'
import './notifications.less'
import TaskList from './task-list'
import { container } from '../../inversify.config'
import { NotificationItemStore } from '../../stores/systemNotifications/notification-store'
import { count } from 'console'

interface Props extends WithTranslation, IdentityProps { }

const ShellNotification: FC<Props> = (props) => {
  const { t } = props
  const [notificationNumber, setNotificationNumber] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [reload, setReload] = useState(-1)
  const [reloadProcess, setReloadProcess] = useState(-1)
  const ref = useRef();



  const notificationStore = container.get<NotificationItemStore>(NotificationItemStore)

  const GetCounter = async () => {
    var counter = await notificationStore.Counter()
    setNotificationNumber(counter.data as any)
  }

  useEffect(() => {
    GetCounter()
  }, [ref]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.PUBLIC_URL}/hubs/notifications`)
      .withAutomaticReconnect().build()

    connection
      .start()
      .then(() => {
        connection.on('NewNotification', (data: any) => {
          if (data.userId === props.identity.id) {
            setReload(data.unreadNotification * new Date().getTime())
            setNotificationNumber(data.unreadNotification)
            infoNotification(t('New Notification'), t(data.notification.description))
            setReloadProcess(new Date().getTime())
          }
        })
        connection.on('BeginConnection', (data: any) => {
        })
        connection.on('NewProcess', (data: any) => {
          if (data.userId === props.identity.id) {
            setReloadProcess(new Date().getTime())
          }
        })
      })
      .catch((e: Error) => console.log('Connection failed: ', e))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {props.children}
      <div className={'messageFloating'}>
        <TaskList reload={reloadProcess} />
        <div
          data-testid="openNotificationsButton"
          className={notificationNumber > 0 ? 'floating-container messages' : 'floating-container nomessages'}
          onClick={() => setIsOpen(true)}
        >
          <Badge count={notificationNumber}>
            <NotificationOutlined style={{ fontSize: '32px', marginTop: 12, color : "white" }} />
          </Badge>
        </div>
      </div>
      <SideView className="notifications-flyout" mask={false} getContainer={false} onClose={() => setIsOpen(false)} visible={isOpen}>
        <NotificationList
          reload={reload}
          visible={isOpen}
          setNotificationCount={(count: number) => setNotificationNumber(count)}
        />
      </SideView>
    </>
  )
}

export default withIdentity(withTranslation()(ShellNotification))
