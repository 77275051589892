import { FC, useMemo, useState } from 'react'
import { Form, Spin, Modal, Alert } from 'antd'
let FormItem = Form.Item
import { NewUserGroupItem, UserGroupItemStore } from 'src/stores/users/usersgroups-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import GroupEditor from 'src/components/groupEdit'
import { nameof } from 'src/core/utils/object'
import { container } from 'src/inversify.config'

interface NewUserGroupViewProps extends WithTranslation {
  id: string,
  onClose: () => void
}


const NewUserGroupView: FC<NewUserGroupViewProps> = (props) => {

  const { t } = props
  const userGroupStore = useMemo(() => container.get(UserGroupItemStore), [])
  const userGroupStoreState = userGroupStore.state
  const [form] = Form.useForm<NewUserGroupItem>()
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onCancelNewItem = () => {
    userGroupStore.clear()
    props.onClose()
  }

  const onCreateNewItem = async () => {
    userGroupStore.changeUser(props.id)
    let item: any
    try {
      item = (await form.validateFields()) as any
    } catch {
      return
    }
    setConfirmLoading(true)
    item.groupId = item.groupId.id
    let response = await userGroupStore.create(item)
    if (response) {
      form.resetFields()
      props.onClose()
    }
    setConfirmLoading(false)
  }
  return (
    <Modal
      cancelText={t('Cancel')}
      okText={t('Ok')}
      visible
      confirmLoading={confirmLoading}
      onCancel={onCancelNewItem}
      onOk={onCreateNewItem}
      closable={false}
      title={t("New user")}>
      {userGroupStoreState.errorMessage.get() && <Alert type="error" message={t(userGroupStoreState.errorMessage.value || '')} />}
      <Spin spinning={userGroupStoreState.isBusy.get()}>
        <Form layout="vertical" id="modaForm" form={form}>
          <FormItem label={t("Group")} name={nameof<NewUserGroupItem>('groupId')} rules={[{ required: true, message: t('Group required') }]}>
            <GroupEditor valueAsItemReference={true} />
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  )
}

// Wire up the React component to the Redux store
export default withTranslation()(NewUserGroupView)
