import * as React from 'react'
import { Row, Col, Modal, Card, Button, Spin } from 'antd'
import { FC, useState, useEffect } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { IdentityProps, withIdentity } from 'src/core/services/authentication'
import { container } from '../../inversify.config'
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { DisclaimerStore, DisclaimerItemStore, DisclaimerItem } from '../../stores/profiles/disclaimer-store'
import { Query, SortProfile, QueryResult } from '../../core/stores/data-store'
import moment from 'moment'
import ContentTitle from 'src/core/ui/content-header'
import { AuthenticationResult } from '../../core/services/authentication'
import HttpService from '../../core/services/http.service'

interface ProfileDisclaimersProps extends WithTranslation, IdentityProps {
  identity: AuthenticationResult
}

interface AppItem {
  id: string,
  title: string,
  description: string,
  icon: string,
  privateUrl: string,
  publicUrl: string,
  prerelease: boolean,
  hasAccess: boolean,
}

const AppIcon: FC<{ icon?: string; id?: string, identityUrl: string, backcolor?: string }> = (props) => {
  const httpService = container.get(HttpService)
  const [busy, setBusy] = React.useState(false)
  const [iconSource64, setIconSource64] = useState(props.icon)
  const inputRef = React.useRef()

  React.useEffect(() => {
    if (props.id) {
      setBusy(true)
      httpService
        .get<QueryResult<AppItem>>(`${props.identityUrl}/api/v1/applications/icon/${props.id}`)
        .then((result) => {
          setIconSource64(result.data as any)
          setBusy(false)
        })
        .catch((error) => {
          setBusy(false)
        })
    }
  }, [inputRef])

  return !busy ? (
    <div style={{ height: 48, width: 48, backgroundColor: props.backcolor ? props.backcolor : '#0e0e4f', borderRadius: 25 }}>
      <img width={48} height={48} src={iconSource64} />
    </div>
  ) : (
    <LoadingOutlined style={{ fontSize: '48px', color: '#0e0e4f' }} />
  )
}

const ProfileDisclaimers: FC<ProfileDisclaimersProps> = (props) => {

  const { t, identity } = props
  const [showConfirmUpdated, setShowConfirmUpdated] = useState<boolean>(false)
  const [loadDisclaimers, setLoadDisclaimers] = useState<boolean>(false)
  const currentStore = React.useMemo(() => container.get(DisclaimerStore), []) as DisclaimerStore
  const updateStore = React.useMemo(() => container.get(DisclaimerItemStore), [])
  const currentState = currentStore.state
  const [appSelected, setAppSelected] = useState<DisclaimerItem>()
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [
      { field: 'Accepted', direction: 'Descending' },
      { field: 'OnAcceptedDate', direction: 'Ascending' }
    ],
    skip: 0, take: 9999
  } as Query)
  useEffect(() => {
    load(query)
  }, [query, loadDisclaimers]) // eslint-disable-line react-hooks/exhaustive-deps

  const load = (query: Query) => {
    currentStore.load(query)
  }

  const onCheckedDisclaimer = async (d: DisclaimerItem) => {
    setShowConfirmUpdated(true)
    setAppSelected(d)
  }

  const onUpdateItem = () => {
    setShowConfirmUpdated(false)
    updateStore.updateDisclaimer(appSelected.appName).then(function () {
      setLoadDisclaimers(!loadDisclaimers)
    })
  }

  return (

    <div>
      <>
        <Row gutter={0}>
          <ContentTitle type={t('MY DISCLAIMERS')} title={t('Disclaimers')} />
        </Row>

        <p style={{ padding: 20 }}>{t('disclaimer_text')}</p>

        <Card>
          <Spin spinning={currentState.isBusy.get()}>
            <Modal
              visible={showConfirmUpdated}
              closable={false}
              onOk={() => onUpdateItem()}
              onCancel={() => setShowConfirmUpdated(false)}
              okText={t("Ok")}
              cancelText={t("Cancel")}
              title={t('Confirmation required')}>
              <p>{t('disclaimer_text')}</p>
            </Modal>
            {
              currentState.items.value.map((d) => (
                <div style={{ padding: 10 }}>
                  <Row style={{ marginTop: '10px' }} className='disclaimer-row'>
                    <Col span={10}>
                      <a href={d.privateUrl || '#'}>
                        <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'left', marginBottom: 8 }}>
                          <div style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '10px' }}>
                            <AppIcon id={(d.appInfoId).toString()} identityUrl={identity.identityUrl} />
                          </div>
                          <h3 style={{ marginBottom: 'auto', marginTop: 'auto', fontSize: 14 }}>{d.displayAppName}</h3>
                        </div>
                      </a>
                    </Col>
                    {d.accepted ?
                      <>
                        <Col span={6}><CheckCircleOutlined style={{ fontSize: '30px', color: 'green', marginLeft: '35px', marginTop: '12.5px' }} /></Col>
                        <Col span={8} style={{ marginTop: '17px' }}>{moment(d.onAcceptedDate).format('DD/MM/yyyy h:mm:ss a')}</Col>
                      </>
                      :
                      <>
                        <Col span={6}>
                          <Button
                            type='primary'
                            style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', borderRadius: '20px', width: '100px', marginTop: '12px' }}
                            onClick={() => onCheckedDisclaimer(d)}
                          >
                            {t('Accept')}
                          </Button>
                        </Col>
                        <Col span={8}></Col>
                      </>
                    }
                  </Row>
                </div>
              ))
            }
          </Spin>
        </Card>
      </>
    </div>
  )
}

export default withIdentity(withTranslation()(ProfileDisclaimers)) as any