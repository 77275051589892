/// <reference path="modules.d.ts" />
import 'reflect-metadata'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './app'
import './core/assets/site.less'
import './assets/app.less'
import './core/assets/antd-custom.less'
import './core/assets/theme.less'
import { AppConfig, initialize as initializeInversify } from './inversify.config'
import 'src/core/utils/linq'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import './core/utils/linq'
import CacheContent from 'src/core/services/cache.service'
import ShellIdleApp from 'src/core/ui/shell-idle-app';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')
fetch(`${process.env.PUBLIC_URL}/api/v1/config`)
  .then((response) => response.json())
  .then((config) => {
    initializeInversify(config)
    ReactDOM.render(
      <BrowserRouter basename={baseUrl}>
        <AppConfig.Provider value={config}>
          <I18nextProvider i18n={i18n}>
            <CacheContent scope="identity-V2">
              <BrowserRouter basename="/identity">
                <App />
              </BrowserRouter>
            </CacheContent>
          </I18nextProvider>
        </AppConfig.Provider>
      </BrowserRouter>,
      rootElement
    )
  })
