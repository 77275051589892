import autobind from 'autobind-decorator';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Alert, Card, Tag, Col, Button, Form } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ContentTitle from 'src/core/ui/content-header';
import { CheckingPermissionsStore } from '../../../stores/inspector/checkingpermissions-store';
import UserEditor from '../../../components/userEdit';
import FormItem from 'antd/lib/form/FormItem';
import { Query } from '../../../core/stores/data-store';
import { useEffect, useMemo, useState } from 'react';
import { container } from '../../../inversify.config';
import { DesktopOutlined, UserAddOutlined } from '@ant-design/icons';

// tslint:disable-next-line:no-empty-interface
interface PermissionListViewProps extends RouteComponentProps, WithTranslation {
   
}

interface PermissionListViewState {
    query: Query;  
    userId: string;
}

const PermissionListView: React.FC<PermissionListViewProps> = (props) => {

  const [query, setQuery] = useState({
    searchQuery: '', skip: 0, take: 10
  } as Query)
  const [userId, setUserId] = useState<string | undefined>();

  const currentStore = useMemo(() => container.get(CheckingPermissionsStore), [])
  const currentState = currentStore.state

  useEffect(() => {
    load(userId)
  }, [userId]) // eslint-disable-line react-hooks/exhaustive-deps


  const load = async (value: string) => {
    if (value) {
      currentStore.changeUrl(value)
       await currentStore.load(query)
    }
  }

   
        const { t } = props;
        var result = currentState.items.get().firstOrDefault();
        var groups : any ;
        if (result) {
            groups = result.groups.map(x => <li>{x}</li>)           
        }

        return (
            <div>
                <ContentTitle title={t("User Permissions")} />
                <Card style={{ marginBottom: 5 }}>
                    <Col span={12}>
                        <Form>
                            <FormItem label={t("User")}>
                                <UserEditor valueAsItemReference onChange={(user) => setUserId(user.id)}  placeholder={t('Select a user to check')} />
                            </FormItem>                            
                        </Form>
                    </Col>
                </Card>
            <Card>
                    <div className={ "ant-table"}>
                    <table>

                    {(!result || !result.groups) && <Alert message={t('Please select a user to check permissions')} type={"info"}></Alert>}
                    
                            {result && result.groups &&
                                <>
                                    <thead className={"ant-table-thead"}><tr><th colSpan={2} style={{ textAlign: "left" }}><UserAddOutlined/> {t('Groups')}</th></tr></thead>
                                    <tbody className="ant-table-tbody">
                                        <tr><td colSpan={2}>
                                            {(result.groups.length == 0) && <Alert message={t('No groups found for this user')} type={"success"}></Alert>}
                        {result.groups.map
                          (x => <Tag>{x}</Tag>)}</td></tr>
                                    </tbody>
                                </>
                    }
                                        
                    
                    {result && result.applicationPermissions.map(x =>
                            <>
                            <thead className={"ant-table-thead"}><tr><th colSpan={2} style={{ textAlign: "left" }}><DesktopOutlined /> {x.applicationName}</th></tr></thead>
                            <thead className={"ant-table-thead"}><tr><th>{t('Roles')}</th></tr></thead>
                            <tbody className="ant-table-tbody">
                                <tr>
                                    <td>{x.roles.map(r => <Tag>{r}</Tag>)}</td>
                                </tr> 
                            </tbody>
                            </>
                    )}
                        </table>
                        </div>
                </Card>
            </div>
        );
    }

// Wire up the React component to the Redux store
export default withTranslation()(withRouter(PermissionListView) as any) as any as React.ComponentClass<PermissionListViewProps>;
