import DataStore from '../../core/stores/data-store'
import FormStore from 'src/core/stores/form-store';
import { container } from '../../inversify.config';
import HttpService from '../../core/services/http.service';

export interface DisclaimerItem {
  appInfoId: number;
  displayAppName: string;
  appName: string;
  accepted: boolean;
  onAcceptedDate: string;
  privateUrl: string;
}

export class DisclaimerStore extends DataStore<DisclaimerItem> {
  private publicUrl: string;
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/profile/disclaimers`, [])
    this.publicUrl = baseUrl;
  }
}

export class DisclaimerItemStore extends FormStore<DisclaimerItem, DisclaimerItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/profile/disclaimer`)
    this.publicUrl = baseUrl
  }

  public async updateDisclaimer(appInfoName: string) {
    var httpService = container.get(HttpService)
    const result = await httpService.put<any, any>(`${this.baseUrl}/${appInfoName}`, [])
    return result.data;
  }

}