import { container } from 'src/inversify.config';
import DataStore from '../../core/stores/data-store';
import { UserSummary } from '../users/users-store';

export class AppUsersStore extends DataStore<UserSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }
    changeUrl(appId: string) {
        this.baseUrl = `api/v1/admin/applications/${appId}/users`;
    }
  
}

export class AppUsersWithRolesStore extends DataStore<UserSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }
  changeUrl(appId: string) {
    this.baseUrl = `api/v1/admin/applications/${appId}/usersWithRoles`
  }

}

