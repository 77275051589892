import 'reflect-metadata'
import * as React from 'react'
import { Container } from 'inversify'
import HttpService from './core/services/http.service'
import { AppConfiguration, ConfigurationService } from './core/services/configuration.service'
import { PreferencesService } from './core/services/preferences.service'

import { ApplicationItemStore, ApplicationsStore, ApplicationWithRoleStore } from './stores/applications/applications-store'
import { OwnersStore } from './stores/applications/owners-store'
import { AppPermissionsStore, AppPermissionItemStore } from './stores/applications/permissions-store'
import { AppUsersStore , AppUsersWithRolesStore } from './stores/applications/users-store'

import { GroupRoleItemStore, GroupRolesStore } from './stores/groups/groupsroles-store'
import { GroupItemStore, GroupsStore } from './stores/groups/groups-store'
import { GroupUsersStore, GroupUserItemStore } from './stores/groups/groupsusers-store'

import { CheckingPermissionsStore } from './stores/inspector/checkingpermissions-store'

import { PatItemStore, PatsStore } from './stores/pats/pats-store'

import { UserProfileStore } from './stores/profiles/profile-store'

import { RoleScopeItemStore, RoleScopesStore } from './stores/roles/rolescopes-store'
import { RolesStore, RoleItemStore } from './stores/roles/roles-store'

import { ScopedPermissionsStore, ScopedPermissionItemStore } from './stores/scopedPermissions/scoped-permissions-store'

import { UserGroupsStore, UserGroupItemStore, UserGroupsForModalStore } from './stores/users/usersgroups-store'
import { UserRolesStore, UserRoleItemStore } from './stores/users/usersroles-store'
import { UsersStore, UserItemStore } from './stores/users/users-store'

import { ConfigSettingItemStore, ConfigSettingsStore } from './stores/configSettings/config-setting-store'

import { PoliciesStore, PolicyItemStore } from './stores/policies/policies-store'

import { AreasStore, AreaItemStore } from './stores/areas/areas-store'

import { TenantHierarchyTreeStore } from './stores/tenants/tenant-hierarchy-tree-store'

import { TenantMemberDataStore, TenantMemberFormStore, TenantMemberForOperationRequestFormStore, TenantClientMemberDataStore } from './stores/tenants/tenant-members-store'

import { TenantItemStore, TenantSummaryStore, TenantSummaryForSelectStore, AssignedToStore } from './stores/tenants/tenants-store'

import { TenantIdentityDataStore, TenantIdentityGroupsDataStore, TenantIdentityGroupStore, GroupMemberSummaryStore } from './stores/tenant-identity/tenant-identity-store'

import { NotificationItemStore, TaskItemStore } from './stores/systemNotifications/notification-store'
import { DisclaimerStore, DisclaimerItemStore } from './stores/profiles/disclaimer-store'
import { AuthLogsStore, SystemLogStore, SessionLogStore, ExternalApiAccessLogStore } from './stores/authLogs/logs-store'

import { UpsertPermissionAssignedStore, OperationRequestStore } from './stores/operationRequests/operationRequests-store'

import { DashBoardStore } from './stores/dashBoard/dashBoard-store'

// Initialize DI/IoC container
const container = new Container()

const AppConfig = React.createContext<AppConfiguration>({} as any)

var BaseAppUrl = process.env.PUBLIC_URL
var AppEnvironment = ""

function initialize(config?: any) {
  let baseUri = process.env.PUBLIC_URL || config ? config.serviceUrl || config.ServiceUrl : `${window.location.protocol}//${window.location.host}`
  BaseAppUrl = baseUri
  AppEnvironment = config?.environment
  if (!container.isBound(HttpService)) {
    // Initialize services if container is not configured before
    container
      .bind(HttpService)
      .toSelf()
      .inSingletonScope()
      .onActivation((context: any, instance: any) => {
        instance.setup(baseUri)
        return instance
      })
  }

  // Initialize services
  container
    .bind(ConfigurationService)
    .toSelf()
    .inSingletonScope()
    .onActivation((context, instance) => {
      var config = instance.current()
      return instance
    })

  container
    .bind(PreferencesService)
    .toSelf()
    .inSingletonScope()
    .onActivation((context, instance) => {
      var prefs = instance.current()
      return instance
    })

  container.bind(DashBoardStore).toConstantValue(new DashBoardStore(baseUri))
  container.bind(ApplicationItemStore).toConstantValue(new ApplicationItemStore(baseUri))
  container.bind(ApplicationWithRoleStore).toConstantValue(new ApplicationWithRoleStore(baseUri))
  container.bind(ApplicationsStore).toConstantValue(new ApplicationsStore(baseUri))
  container.bind(OwnersStore).toConstantValue(new OwnersStore(baseUri))
  container.bind(AppPermissionsStore).toConstantValue(new AppPermissionsStore(baseUri))
  container.bind(AppPermissionItemStore).toConstantValue(new AppPermissionItemStore(baseUri))
  container.bind(AppUsersStore).toConstantValue(new AppUsersStore(baseUri))
  container.bind(AppUsersWithRolesStore).toConstantValue(new AppUsersWithRolesStore(baseUri))

  container.bind(GroupRoleItemStore).toConstantValue(new GroupRoleItemStore(baseUri))
  container.bind(GroupRolesStore).toConstantValue(new GroupRolesStore(baseUri))
  container.bind(GroupUserItemStore).toConstantValue(new GroupUserItemStore(baseUri))
  container.bind(GroupUsersStore).toConstantValue(new GroupUsersStore(baseUri))
  container.bind(GroupItemStore).toConstantValue(new GroupItemStore(baseUri))
  container.bind(GroupsStore).toConstantValue(new GroupsStore(baseUri))

  container.bind(CheckingPermissionsStore).toConstantValue(new CheckingPermissionsStore(baseUri))

  container.bind(PatsStore).toConstantValue(new PatsStore(baseUri))
  container.bind(PatItemStore).toConstantValue(new PatItemStore(baseUri))

  container.bind(UserProfileStore).toConstantValue(new UserProfileStore(baseUri))

  container.bind(RoleScopesStore).toConstantValue(new RoleScopesStore(baseUri))
  container.bind(RoleScopeItemStore).toConstantValue(new RoleScopeItemStore(baseUri))
  container.bind(RolesStore).toConstantValue(new RolesStore(baseUri))
  container.bind(RoleItemStore).toConstantValue(new RoleItemStore(baseUri))

  container.bind(ScopedPermissionItemStore).toConstantValue(new ScopedPermissionItemStore(baseUri))
  container.bind(ScopedPermissionsStore).toConstantValue(new ScopedPermissionsStore(baseUri))

  container.bind(UserGroupsStore).toConstantValue(new UserGroupsStore(baseUri))
  container.bind(UserGroupsForModalStore).toConstantValue(new UserGroupsForModalStore(baseUri))
  container.bind(UserRolesStore).toConstantValue(new UserRolesStore(baseUri))
  container.bind(UserGroupItemStore).toConstantValue(new UserGroupItemStore(baseUri))
  container.bind(UserRoleItemStore).toConstantValue(new UserRoleItemStore(baseUri))
  container.bind(UsersStore).toConstantValue(new UsersStore(baseUri))
  container.bind(UserItemStore).toConstantValue(new UserItemStore(baseUri))

  container.bind(PoliciesStore).toConstantValue(new PoliciesStore(baseUri))
  container.bind(PolicyItemStore).toConstantValue(new PolicyItemStore(baseUri))

  container.bind(ConfigSettingsStore).toConstantValue(new ConfigSettingsStore(baseUri))
  container.bind(ConfigSettingItemStore).toConstantValue(new ConfigSettingItemStore(baseUri))

  container.bind(AreasStore).toConstantValue(new AreasStore(baseUri))
  container.bind(AreaItemStore).toConstantValue(new AreaItemStore(baseUri))

  container.bind(TenantHierarchyTreeStore).toConstantValue(new TenantHierarchyTreeStore(baseUri))

  container.bind(TenantMemberDataStore).toConstantValue(new TenantMemberDataStore(baseUri))
  container.bind(TenantClientMemberDataStore).toConstantValue(new TenantClientMemberDataStore(baseUri))
  container.bind(TenantMemberForOperationRequestFormStore).toConstantValue(new TenantMemberForOperationRequestFormStore(baseUri))
  container.bind(TenantMemberFormStore).toConstantValue(new TenantMemberFormStore(baseUri))

  container.bind(TenantItemStore).toConstantValue(new TenantItemStore(baseUri))
  container.bind(TenantSummaryStore).toConstantValue(new TenantSummaryStore(baseUri))
  container.bind(TenantSummaryForSelectStore).toConstantValue(new TenantSummaryForSelectStore(baseUri))
  container.bind(AssignedToStore).toConstantValue(new AssignedToStore(baseUri))


  container.bind(TenantIdentityDataStore).toConstantValue(new TenantIdentityDataStore(baseUri))
  container.bind(TenantIdentityGroupsDataStore).toConstantValue(new TenantIdentityGroupsDataStore(baseUri))
  container.bind(TenantIdentityGroupStore).toConstantValue(new TenantIdentityGroupStore(baseUri))
  container.bind(GroupMemberSummaryStore).toConstantValue(new GroupMemberSummaryStore(baseUri))

  container.bind(NotificationItemStore).toConstantValue(new NotificationItemStore(baseUri))
  container.bind(TaskItemStore).toConstantValue(new TaskItemStore(baseUri))

  container.bind(DisclaimerStore).toConstantValue(new DisclaimerStore(baseUri))
  container.bind(DisclaimerItemStore).toConstantValue(new DisclaimerItemStore(baseUri))

  container.bind(AuthLogsStore).toConstantValue(new AuthLogsStore(baseUri))
  container.bind(SystemLogStore).toConstantValue(new SystemLogStore(baseUri))
  container.bind(SessionLogStore).toConstantValue(new SessionLogStore(baseUri))
  container.bind(ExternalApiAccessLogStore).toConstantValue(new ExternalApiAccessLogStore(baseUri))

  container.bind(OperationRequestStore).toConstantValue(new OperationRequestStore(baseUri))
  container.bind(UpsertPermissionAssignedStore).toConstantValue(new UpsertPermissionAssignedStore(baseUri))
}
export { container, initialize, AppConfig, BaseAppUrl, AppEnvironment }
