export const content =
`<html>
<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;	
	font-size:11.0pt;
	}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	margin-bottom:.0001pt;	
	page-break-after:avoid;
	font-size:16.0pt;	
	color:#2F5496;
	font-weight:normal;}
h2
	{mso-style-link:"Heading 2 Char";
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	margin-bottom:.0001pt;	
	page-break-after:avoid;
	font-size:13.0pt;	
	color:#2F5496;
	font-weight:normal;}
a:link
	{color:#0563C1;
	text-decoration:underline;}
a:visited
	{color:#954F72;
	text-decoration:underline;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;}	
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:9.0pt;	

span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	
	color:#2F5496;}
span.Heading2Char
	{mso-style-name:"Heading 2 Char";
	mso-style-link:"Heading 2";
	
	color:#2F5496;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";}
span.CommentSubjectChar
	{mso-style-name:"Comment Subject Char";
	mso-style-link:"Comment Subject";
	font-weight:bold;}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	
p.Figoup-Titre1, li.Figoup-Titre1, div.Figoup-Titre1
	{mso-style-name:"Fi goup - Titre 1";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:2.0cm;
	text-align:justify;
	text-indent:-1.0cm;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:#00AFFF;
	text-decoration:underline;}
p.Figoup-Titre1CxSpFirst, li.Figoup-Titre1CxSpFirst, div.Figoup-Titre1CxSpFirst
	{mso-style-name:"Fi goup - Titre 1CxSpFirst";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:2.0cm;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:-1.0cm;
	page-break-after:avoid;
	font-size:9.0pt;	
	color:#00AFFF;
	text-decoration:underline;}
p.Figoup-Titre1CxSpMiddle, li.Figoup-Titre1CxSpMiddle, div.Figoup-Titre1CxSpMiddle
	{mso-style-name:"Fi goup - Titre 1CxSpMiddle";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:2.0cm;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:-1.0cm;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:#00AFFF;
	text-decoration:underline;}
p.Figoup-Titre1CxSpLast, li.Figoup-Titre1CxSpLast, div.Figoup-Titre1CxSpLast
	{mso-style-name:"Fi goup - Titre 1CxSpLast";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:2.0cm;
	text-align:justify;
	text-indent:-1.0cm;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:#00AFFF;
	text-decoration:underline;}
p.Figroup-11, li.Figroup-11, div.Figroup-11
	{mso-style-name:"Fi group - 1\.1";
	mso-style-link:"Fi group - 1\.1 Car";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:1.0cm;
	text-align:justify;
	text-indent:-1.0cm;
	text-autospace:none;
	font-size:9.0pt;
	
p.Figroup-111, li.Figroup-111, div.Figroup-111
	{mso-style-name:"Fi group - 1\.1\.1";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:2.0cm;
	text-align:justify;
	text-indent:-32.1pt;
	text-autospace:none;
	font-size:9.0pt;
	
span.Figroup-11Car
	{mso-style-name:"Fi group - 1\.1 Car";
	mso-style-link:"Fi group - 1\.1";
	font-family:"Arial",sans-serif;}
p.Figroup-1111, li.Figroup-1111, div.Figroup-1111
	{mso-style-name:"Fi group - 1\.1\.1\.1";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:63.9pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:9.0pt;
	
	font-weight:bold;}
p.Figroup-1111CxSpFirst, li.Figroup-1111CxSpFirst, div.Figroup-1111CxSpFirst
	{mso-style-name:"Fi group - 1\.1\.1\.1CxSpFirst";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:63.9pt;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:9.0pt;
	
	font-weight:bold;}
p.Figroup-1111CxSpMiddle, li.Figroup-1111CxSpMiddle, div.Figroup-1111CxSpMiddle
	{mso-style-name:"Fi group - 1\.1\.1\.1CxSpMiddle";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:63.9pt;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:9.0pt;
	
	font-weight:bold;}
p.Figroup-1111CxSpLast, li.Figroup-1111CxSpLast, div.Figroup-1111CxSpLast
	{mso-style-name:"Fi group - 1\.1\.1\.1CxSpLast";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:63.9pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:9.0pt;
	
	font-weight:bold;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 70.85pt 70.85pt 70.85pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=ES link="#0563C1" vlink="#954F72">

<div class=WordSection1>

<div style='border:solid windowtext 1.0pt;padding:8px;margin-bottom:10px;'>

<h1 align=center style='text-align:center;border:none;padding:0cm;margin:0px;'><span
lang=FR>CGU DE LA PLATEFORME</span></h1>

</div>

<p class=MsoNormal><span lang=FR style='font-size:16.0pt;color:#2F5496'>1. Définitions</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>Administrateur :</span></b><span
lang=FR> salarié de F.initiatives  responsable de la gestion de la plateforme pour le
CLIENT (notamment pour la gestion des droits et accès aux services en lien avec
les instructions du CLIENT.).</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>CLIENT :</span></b><span
lang=FR> l’entité bénéficiant de la Mission Principale.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>Contenu du CLIENT
:</span></b><span lang=FR> informations remplies et déposées par l’Utilisateur
Autorisé. Le CLIENT a été informé que le suivi des temps R&amp;D est une
composante clé dans la sécurisation des crédits d’impôts et qu’il doit être
rempli avec précision et rigueur.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>Contrat de
prestation de services </span></b><span lang=FR>: quelle que soit la forme de
l’engagement contractuel (devis etc.) dès lors qu’il nécessite l’utilisation
d’une méthode de suivi temps par le CLIENT afin d’encadrer sa justification des
frais de personnel. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>Données
Personnelles </span></b><span lang=FR>: toute information permettant
d’identifier de manière directe ou indirecte un individu. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>Guide
utilisateur: </span></b><span lang=FR>l’utilisation de la Plateforme doit être
réalisée en conformité avec les CGU, dans le cadre des instructions prévues
dans le guide utilisateur, plus particulièrement sur l’utilisation de cette
méthode de suivi temps.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>Mission
Principale :</span></b><span lang=FR> mission réalisée conformément au Contrat
de prestation de services.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>Informations
Confidentielles </span></b><span lang=FR>: toute information en lien avec le
CLIENT qui est déposée sur la Plateforme. Ces Informations Confidentielles
doivent être traitées selon les conditions prévues au sein du Contrat de
prestation de services. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>Utilisateur
Autorisé :</span></b><span lang=FR> utilisateur pouvant avoir accès aux services,
sous réserve de la création préalable de son compte par l’Utilisateur
Privilégié. Est expressément entendu que relève de la responsabilité de
l’Utilisateur Privilégié (i) d’identifier la personne pouvant avoir accès à la
Plateforme (ii) d’informer l’Utilisateur Autorisé des CGU et (iii) de s’assurer
de leur respect.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>Utilisateur Privilégié</span></b><span
lang=FR> : Utilisateur ayant des droits de création et suppression de compte
d’Utilisateur Autorisé selon les instructions qu’il a reçu du CLIENT. Cas
contact privilégié de l’Administrateur en cas de questions, demandes etc.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span lang=FR>Plateforme :</span></b><span
lang=FR> méthode de suivi temps proposée par F.initiatives  afin de faciliter la
gestion des projets de R&amp;D du CLIENT au titre de la Mission Principale,
permettant notamment une justification encadrée des suivis temps dans le cadre
d’un éventuel contrôle par l’administration fiscale. </span></p>

<p class=MsoNormal><span lang=FR style='font-size:16.0pt;color:#2F5496'>2. Devoir et obligation
d’information des parties</span></p>

<p class=MsoNormal><span lang=FR>Chaque Partie certifie :</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>- Prendre toutes
les mesures nécessaires pour éviter un accès non autorisé aux Informations Confidentielles
à des parties tierces. Dès lors, chaque Utilisateur Autorisé a un identifiant
unique et un mot de passe qu’il ne doit en aucun cas communiquer ;</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>- Hormis dans le
cadre des finalités en lien avec la Mission Principale, de ne pas traiter les
Informations Confidentielles ;</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>- De notifier dans
les plus brefs délais à l’autre Partie en cas d’accès non autorisé à la
Plateforme.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>Le CLIENT certifie
de son côté :</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>-qu’il donnera à l’Utilisateur
Privilégié des instructions claires et non équivoques sur les personnes pouvant
avoir accès à la Plateforme mais également les droits associés (droit d’accès
ou droit de modification.) Dans l’hypothèse où l’Utilisateur Privilégié, en cas
de changement de droit, n’avertirait pas l’Administrateur d’une difficulté à
réaliser une action, il est entendu que les conséquences de ce défaut
d’information et d’action ne pourraient être reprochées à F.initiatives qui sera exonéré
de responsabilité.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>- que l’ensemble
des données qui sera collectée sera véridique, fidèle et sincère.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>- qu’il imposera à
l’Utilisateur Autorisé de:</span></p>

<p class=MsoNormal style='text-align:justify;text-indent:35.4pt'><span lang=FR>*
lire, accepter et signer le Guide utilisateur avant que celui-ci n’accède à la
Plateforme;</span></p>

<p class=MsoNormal style='text-align:justify;text-indent:35.4pt'><span lang=FR>*
avertir aussi vite que possible l’Administrateur dès que les accès doivent être
retirés et le compte supprimé;</span></p>

<p class=MsoNormal style='text-align:justify;text-indent:35.4pt'><span lang=FR>*
notifier à l’Administrateur dès qu’il a connaissance – ou des suspicions – de
compromission de ses accès;</span></p>

<p class=MsoNormal style='text-align:justify;text-indent:35.4pt'><span lang=FR>*
ne jamais transférer ses accès à un tiers. Un identifiant / mot de passe
correspondront toujours à un accès et un Utilisateur Autorisé.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;
text-align:justify'><span lang=FR>F.initiatives ne serait être tenu
pour responsable de toutes failles ou conséquences liées à un non-respect des
règles par un Utilisateur Autorisé.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;
text-align:justify'><span lang=FR>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;
text-align:justify'><span lang=FR> Le CLIENT autorise l’accès,
non seulement à F.initiatives mais également à l’hébergeur ou toute partie tierce
ayant besoin d’avoir accès à la Plateforme tant que des conditions de
confidentialité standards sont respectées. Les conditions de confidentialité de
l’hébergeur sont les suivantes: </span><span lang=FR><a
href="https://privacy.microsoft.com/fr-FR/privacystatement?amp;clcid=0xC">https://privacy.microsoft.com/fr-FR/privacystatement?amp;clcid=0xC</a></span><span
lang=FR> </span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>&nbsp;</span></p>

<p class=MsoNormal><span lang=FR style='font-size:16.0pt;color:#2F5496'>3. Utilisation de la
Plateforme</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>Le CLIENT est
informé qu’il n’a accès à titre gracieux à cette Plateforme uniquement dans le
cadre d’un Contrat de prestation de services.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>En cas de résiliation, les Utilisateurs Autorisés perdront leur droit de création,
modification et suppression et auront uniquement un droit d’accès pendant la
période de prescription. Le CLIENT aura donc accès et pourra télécharger les
suivis temps pour les années de CIR ayant fait l’objet d’un Contrat de prestation
de service, et ce, jusqu’au 31/12 de l’année N+3, l’année N étant l’année de
génération du CERFA concerné, sous réserve d’être à jour de ses factures</span><span
lang=FR>. </span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>Le CLIENT ne
saurait reprocher à F.initiatives des opérations de maintenance correctives ou
évolutives sur la Plateforme. De la même manière, en cas de maintenance de la
part de l’hébergeur, la responsabilité de F.initiatives ne pourra être engagée. </span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR> </span></p>

<p class=MsoNormal><span lang=FR style='font-size:16.0pt;color:#2F5496'>4. Données personnelles</span></p>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.25pt;margin-left:
0cm;text-align:justify'><span lang=FR style='font-size:11.0pt'>Dans le cadre de l’exécution de
la Mission Principale au titre du Contrat de prestation de services, le CLIENT
est informé qu’il a notamment besoin de prouver la réalité du suivi temps de
ses équipes en cas d’un éventuel contrôle par l’administration. Le CLIENT reste
responsable de traitement et F.initiatives sous-traitant. </span>La
Plateforme est hébergée par Azure (Microsoft Ireland Operations Ltd), qui a
également la qualité de responsable de traitement.</p>

<p class=MsoNormal style='text-align:justify'><span lang=FR>Dans le cadre de
l’utilisation de cet outil, l</span><span lang=FR>es données personnelles
traitées des collaborateurs du CLIENT sont les suivantes : prénom, nom,
fonction, temps passé par projet, le cas échéant, absence, détail des tâches
etc. Le CLIENT est informé qu’il n’a pas besoin du consentement des personnes
concernées, la base légale du traitement étant le respect d’une obligation
légale, la justification du crédit d’impôt demandé. Chaque collaborateur du
CLIENT devra donc remplir ses feuilles de temps selon la réalité du temps passé
sur ses missions.</span></p>

<p class=MsoNormal style='text-align:justify'><span lang=FR style='font-size:11.0pt;'>La nature des opérations
réalisées sur les données est la collecte, l’enregistrement, l’organisation, la
conservation, l’extraction, la consultation, l’utilisation, et éventuellement
la communication en cas de contrôle par l’administration.</span></p>

<p class=MsoNormal><span lang=FR style='font-size:16.0pt;color:#2F5496'>5. Loi Applicable et Juridiction</span></p>

<p class=MsoNormal><span lang=FR>La loi et la juridiction applicable à ces CGU
sont les mêmes que celles qui sont prévues au sein du Contrat de prestation de
services.</span></p>

</div>

</body>

</html>
`;