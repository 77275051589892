import autobind from 'autobind-decorator'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Alert, Card, Select, notification } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ApplicationsStore, ApplicationSummary, AppStatus } from 'src/stores/applications/applications-store'
import { getDefaultTableSettings, ItemState, TableModel, TableView } from 'src/core/ui/collections/table'
import { formatDate, formatBoolean, clone } from 'src/core/utils/object'
import NewApplicationView from './application-new'
import ContentTitle from 'src/core/ui/content-header'
import Link from 'src/core/ui/link'
import { CommandResult } from '../../../core/stores/types'
import { withIdentity, IdentityProps } from "src/core/services/authentication"
import { FC, useState } from 'react'
import { BaseAppUrl, container } from '../../../inversify.config'
import { CaretRightOutlined, CloseOutlined, CloudSyncOutlined, DesktopOutlined } from '@ant-design/icons'
import { formatMessage } from '../../../core/services/http.service'
import { Query } from '../../../core/stores/data-store'
import Tooltip from 'antd/es/tooltip'
import { CacheProps, withCache } from '../../../core/services/cache.service'

// tslint:disable-next-line:no-empty-interface
interface ApplicationsViewProps extends RouteComponentProps, WithTranslation, CacheProps {
}


const Applications: FC<ApplicationsViewProps & IdentityProps> = (props) => {
  const { t } = props
  const applicationsStore = React.useMemo(() => container.get(ApplicationsStore), []) as ApplicationsStore
  const applicationsStoreState = applicationsStore.state
  const [query, setQuery] = useState({ searchQuery: '', orderBy: [{ field: 'displayName', direction: 'Ascending', useProfile: false }], skip: 0, take: getDefaultTableSettings().defaultPageSize} as Query)
  const [newApplicationShown, setNewApplicationShown] = useState(false)

  const cacheKey = "IDENTITY-APPLICATIONS-LIST"


  React.useEffect(() => {
    var currentQuery = query
    var queryStored = props.cache.getWithCustomKey(cacheKey, true)
    if (queryStored && queryStored.query)
      currentQuery = queryStored.query
    setQuery(currentQuery)
    load(currentQuery)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const load = (query: Query) => {
    props.cache.saveWithCustomKey(cacheKey, { query: query })
    applicationsStore.load(query)
  }

  const onQueryChanged = (query: Query) => {
    setQuery(query)
    load(query)
  }


  const onDeleteRow = async (item: ApplicationSummary, state: ItemState) => {
    try {
      const result = await applicationsStore.delete(item.id)
      if (result && result.isSuccess) {
        notification.success({
          message: t('Successful action'),
          description: t('Application deleted successfully.'),
        })
      } else {
        notification.error({
          message: t('An error ocurred'),
          description: t(result.errors),
        })
      }
      load(query)
    } catch (error) {
      notification.error({
        message: t('An error ocurred'),
        description: t('Failed to delete the application. Please try again later.'),
      })
    }
  }


  const getStatus = (application: ApplicationSummary) => {
    let status = application.status
    return (
      <Select onChange={(value) => applicationsStore.changeStatus(application.id, value)} style={{ width: 200 }} defaultValue={status} >
        <Select.Option value={AppStatus.Running}>
          <><CaretRightOutlined style={{ color: "green", fontSize: 20, marginRight: 5 }} />{t("Running")}</>
        </Select.Option>
        <Select.Option value={AppStatus.Maintenance}>
          <><CloudSyncOutlined style={{ color: "darkred", fontSize: 20, marginRight: 5 }} />{t("Maintenance")}</>
        </Select.Option>
        <Select.Option value={AppStatus.Stopped}>
          <><CloseOutlined style={{ color: "red", fontSize: 20, marginRight: 5 }} /> {t("Stopped")}</>
        </Select.Option>
      </Select >
    )
  }


  const tableModel = {
    query: query,
    columns: [
      {
        sortable: false,
        field: 'name',
        title: t('Application Id'),
        renderer: (data) => <span><Link to={{ pathname: `/admin/applications/${data.id}`, state: { titles: [...(props.location.state ? (props.location.state as any).titles || [] : []), { url: `${BaseAppUrl}/identity/${props.match.url}/${data.id}`, title: data.name }] } }}><DesktopOutlined />&nbsp;<span>{data.name}</span></Link></span>
      },
      {
        sortable: true,
        field: 'displayName',
        title: t('Application name'),
        renderer: (data) => <span>{data.displayName}</span>
      },
      {
        sortable: false,
        field: 'enabled',
        title: t('Enabled'),
        renderer: (data) => <span>{data.enabled ? t("Yes") : t("No")}</span>
      },
      {
        sortable: false,
        field: 'description',
        title: t('Description'),
        renderer: (data) => <span>{data.description}</span>
      },
      {
        sortable: false,
        field: 'status',
        title: t('Status'),
        align: "center",
        renderer: (data) => <span>{getStatus(data)}</span>
      }
    ],
    data: applicationsStoreState.value,
    sortFields: [
    ],
  } as TableModel<ApplicationSummary>
  return (
    <div>
      <ContentTitle title={t("Applications")} />
      <Card>

        <TableView
          model={tableModel}
          enableTableSettings
          onQueryChanged={(q: Query) => onQueryChanged(q)}
          onNewItem={() => setNewApplicationShown(true)}
          onRefresh={() => load(query)}
          canEdit={false}
          canDelete={true}
          onDeleteRow={onDeleteRow}
          error={applicationsStoreState.errorMessage.value && formatMessage(applicationsStoreState.errorMessage.value)}
          canCreateNew={props.identity.roles != undefined && props.identity.roles.some(value => value === "Administrator")}>
        </TableView>
        {
          newApplicationShown && <NewApplicationView onClose={() => { setNewApplicationShown(false); load(query) }} {...props} />
        }
      </Card>
    </div>
  )
}

export default withCache(withTranslation()(withRouter(withIdentity(Applications))))