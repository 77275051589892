import React, { useEffect, useRef, createElement as create } from 'react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import 'monaco-editor/esm/vs/basic-languages/monaco.contribution';
import 'monaco-editor/esm/vs/basic-languages/markdown/markdown.contribution';
import 'monaco-editor/esm/vs/basic-languages/yaml/yaml.contribution';
import { withTranslation, WithTranslation } from 'react-i18next';
import { css } from '@emotion/css';

export interface CodeEditorProps {
  classSuffix?: string;
  idSuffix?: string;
  value?: string;
  language: string;
  height?: number;
  onChange?: (value?: string) => void;
}

const CodeEditor: React.FC<CodeEditorProps & WithTranslation> = ({ value, height, onChange, language, classSuffix = 'fi-code-editor', idSuffix }) => {
  if (height == null) height = 800;
  const element = useRef<undefined | HTMLElement>();
  const editor = useRef<undefined | monaco.editor.IStandaloneCodeEditor>();
  useEffect(() => {
    const resize = () => {
      if (editor.current) {
        editor.current.layout({ height: 0, width: 0 });
        editor.current.layout();
      }
    };
    window.addEventListener('resize', resize);
    setTimeout(() => resize); // push to next tick
    return () => window.removeEventListener('resize', resize);
  });
  useEffect(() => {
    if (element.current) {
      editor.current = monaco.editor.create(element.current, {
        value,
        language,
        minimap: {
          enabled: false
        }
      });
      editor.current.onDidChangeModelContent(() => {
        if (editor.current && onChange) onChange(editor.current.getValue());
      });
    }
    return () => editor.current && editor.current.dispose();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editor.current && editor.current.getValue() !== value) {
      try {
        editor.current.setValue(value);
      } catch {}
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return create('div', {
    className: `${classSuffix ? `code-editor-root__${classSuffix}` : 'code-editor-root'} ${css({
      border: '1px solid #e5e5ea'
    })}`,
    id: idSuffix ? `code-editor-root__${idSuffix}` : undefined,
    children: create('div', {
      ref: element,
      className: `${classSuffix ? `code-editor-container__${classSuffix}` : 'code-editor-container'} ${css({
        height
      })}`,
      id: idSuffix ? `code-editor-container__${idSuffix}` : undefined
    })
  });
};

export default withTranslation()(CodeEditor);
