import * as React from 'react'
import { Row, Col, Spin, Result, Card } from 'antd'
import { UserProfileStore, UserProfileItem } from 'src/stores/profiles/profile-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withIdentity, IdentityProps } from 'src/core/services/authentication'
import { RouteComponentProps, withRouter } from 'react-router'
import ContentTitle from 'src/core/ui/content-header'
import ProfilePersonalInfoView from './profile-personal-info'
import ProfilePictureView from './profile-picture'
import { clone } from 'src/core/utils/object'
import { FC, useEffect, useState } from 'react'
import { container } from '../../inversify.config'
import ShellDisclaimer from '../../core/ui/shell-disclaimer'
import "./profile.scss"

interface ProfileViewProps extends WithTranslation {
}

const ProfileView: FC<ProfileViewProps & IdentityProps & RouteComponentProps> = (props) => {
  const { t } = props
  const currentStore = React.useMemo(() => container.get(UserProfileStore), [])
  const currentState = currentStore.state
  const [profile, setProfile] = useState<UserProfileItem>()
  const inputRef = React.useRef()

  useEffect(
    () => {
      load()

    }, [inputRef]
  )
  const load = async () => {
    var result = await currentStore.get()
    setProfile(result)
  }

  const onUpdateItem = (values: any) => {
    return currentStore.updateProfile(values)
  }

 

  return <Spin spinning={currentState.isBusy.get()}>
    <ShellDisclaimer>
      <ContentTitle type={t('Your Profile')} title={profile?.userName} />
      {profile &&
        <div style={{ padding : 10 }}>
          <Row gutter={16}>
            <Col span={14}>
              <ProfilePersonalInfoView
                profile={profile}
                onUpdateItem={onUpdateItem}
              />
            </Col>
            <Col span={10}>
              <ProfilePictureView profile={profile} />
            </Col>
          </Row>
        </div>}
    </ShellDisclaimer>
  </Spin>

}

export default withTranslation()(withRouter(withIdentity(ProfileView)))
