import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface UserPermissionsSummary {
    groups: string[];
    applicationPermissions: ApplicationPermissionsSummary[];
}
export interface ApplicationPermissionsSummary {    
    applicationName: string,
    roles: string[];
    scopedPermissions: ScopedPermissionSummary[];
}
export interface ScopedPermissionSummary {
    permissionName: string;
    scopeName: string;
}

export class CheckingPermissionsStore extends DataStore<UserPermissionsSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/groups`, [])
    this.publicUrl = baseUrl
  }
    changeUrl(userId: string) {
        this.baseUrl = this.publicUrl + `/api/v1/admin/users/check/permissions/${userId}`;
    }
  
}
