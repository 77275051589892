import React, { FC, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withIdentity, IdentityProps } from 'src/core/services/authentication'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Row, Col, Card, Spin } from 'antd'
import ContentHeader from 'src/core/ui/content-header'
import { container } from 'src/inversify.config'
import { TenantItemStore } from 'src/stores/tenants/tenants-store'
import { TenantDetailsMembersTab } from './components/members/tenant-details-members-tab'
import TenantDetailsGeneralTab from './components/tenant-details-general-tab'
import { TenantDetailsHierarchyTreeSection } from './components/tree-section/tenant-details-hierarchy'

interface TenantDetailsPageProps extends WithTranslation, IdentityProps, RouteComponentProps<{ id: string }> { }

enum TenantDetailsTabs {
  General = 'General',
  Members = 'Members',
}

const TenantDetailsPage: FC<TenantDetailsPageProps> = (props) => {
  const { t, location, match, identity } = props
  const entityId = match.params.id
  console.log(entityId)
  const [IsAdmin] = useState(identity.roles?.any(r => r === "Administrator"))

  const entityStore = container.get<TenantItemStore>(TenantItemStore)

  const [selectedTenant, setSelectedTenant] = useState<{ id: string; title: string } | undefined>()
  const [activeTab, changeActiveTab] = useState<string>(TenantDetailsTabs.General)
  const [forceReload, setForceReload] = useState(0)

  const load = async () => {
    const entity = await entityStore.load(match.params.id, true, false)
    setSelectedTenant({ id: match.params.id, title: entity.title })
  }

  useEffect(() => {
    if (match.params.id) {
      load()
    }
  }, [match.params.id]) // eslint-disable-line react-hooks/exhaustive-deps


  const getTabList = () => {
    var result = [
      { key: TenantDetailsTabs.General, tab: t(TenantDetailsTabs.General) },

    ]

    if (IsAdmin ) {
      result.push({ key: TenantDetailsTabs.Members, tab: t(TenantDetailsTabs.Members) })
    }
    return result
  }
  return (
    <div>
      <Row gutter={0}>
        <Col span={16}>
          <ContentHeader
            title={
              <span>
                {t('Tenant Details')}
                {`${selectedTenant ? ' - ' : ''}${selectedTenant?.title || ''}`}
              </span>
            }
          />
        </Col>
      </Row>
      <Spin spinning={entityStore.state.isBusy.get()}>
        {selectedTenant && < Row gutter={16} align="top" justify="space-between">
          <Col span={10}>
            <Card
              id="entity-hierarchy-tree-container"
              bodyStyle={{ height: '100%' }}
              style={{ width: '100%', height: 'calc(100vh - 160px)', minHeight: 600, overflow: 'auto' }}
            >
              <TenantDetailsHierarchyTreeSection
                entityId={entityId}
                forceReload={forceReload}
                {...props}
                onSelectionChange={(e) => {
                  if (e.id === selectedTenant.id) {
                    setSelectedTenant(e)
                    load()
                  } else {
                    setSelectedTenant(e)
                  }
                  changeActiveTab(TenantDetailsTabs.General)
                }}
              />
            </Card>
          </Col>
          <Col span={14}>
            <Card
              style={{ width: '100%', height: 'calc(100vh - 160px)', minHeight: 600, overflow: 'auto' }}
              tabList={getTabList()}
              activeTabKey={activeTab}
              onTabChange={changeActiveTab}
              title={<h3 style={{ marginBottom: 0 }}>{selectedTenant.title}</h3>}
            >
              {activeTab === TenantDetailsTabs.General && <TenantDetailsGeneralTab onChange={() => setForceReload(forceReload + 1)}  {...props} tenantId={selectedTenant.id} />}
              {activeTab === TenantDetailsTabs.Members && <TenantDetailsMembersTab {...props} entityId={selectedTenant.id} />}
            </Card>
          </Col>
        </Row>}
      </Spin>
    </div>
  )
}

export default withIdentity(withTranslation()(withRouter(TenantDetailsPage)))
