import autobind from 'autobind-decorator'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Checkbox, Alert, Select, Tag, Row, Col } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { GroupPermission, GroupUsersStore, GroupUserSummary } from 'src/stores/groups/groupsusers-store'
import { getDefaultTableSettings, TableModel, TableView } from 'src/core/ui/collections/table'
import NewGroupUserView from './groupuser-new'
import { CommandResult } from '../../../../core/stores/types'
import { useState } from 'react'
import { Query } from '../../../../core/stores/data-store'
import { container } from '../../../../inversify.config'
import { clone } from '../../../../core/utils/object'
import { formatMessage } from '../../../../core/services/http.service'
import { CheckOutlined } from '@ant-design/icons'
import BulkUploadMenu from '../../../../components/bulk-upload/bulk-upload-menu'


// tslint:disable-next-line:no-empty-interface
interface GroupUsersViewProps {
  id: string
  hideGroup: boolean
}


const GroupUsers: React.FC<GroupUsersViewProps & RouteComponentProps & WithTranslation> = (props) => {

  const [query, setQuery] = useState({ searchQuery: '', orderBy: [{ field: 'userName', direction: 'Ascending', useProfile: true }], skip: 0, take: getDefaultTableSettings().defaultPageSize} as Query)

  const [newGroupUserShown, setnewGroupUserShown] = useState(false)
  const currentStore = React.useMemo(() => container.get(GroupUsersStore), [])
  const currentState = currentStore.state

  React.useEffect(() => {
    load(query)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps


  const load = (query: Query) => {
    currentStore.changeGroup(props.id)
    currentStore.load(query)
  }

  const onDeleteRow = async (item: GroupUserSummary) => {
    currentStore.changeGroup(props.id)
    await currentStore.delete(item.userId)
    load(query)
  }

  const onSaveItem = async (item: GroupUserSummary) => {
    currentStore.changeGroup(props.id)
    var result = await currentStore.update(item.userId, item)
    load(query)
    return result
  }



  const { t } = props


  const tableModel = {
    query: query,
    columns: props.hideGroup ? [

      {
        field: 'userName',
        title: t('User'),
        renderer: (data) => <span>{data.userName}</span>
      },
      {
        sortable: true,
        field: 'permission',
        title: t('Is Owner'),
        renderer: (data) =><span> { data.permission == GroupPermission.Owner ? <CheckOutlined />  : "" }</span>,
        editor: (data) => (
          <Select defaultValue={data.permission} style={{ width: 150 }}>
            <Select.Option value={GroupPermission.Reader}>{t("No")}</Select.Option>
            <Select.Option value={GroupPermission.Owner}>{t("Yes")}</Select.Option>
          </Select >)
      }

    ] :
      [
        {
          sortable: false,
          field: 'groupName',
          title: t('Group'),
          renderer: (data) => <span>{data.groupName}</span>
        },
        {
          sortable: false,
          field: 'userName',
          title: t('User'),
          renderer: (data) => <span>{data.userName}</span>
        },
        {
          sortable: false,
          field: 'permission',
          title: t('Permission'),
          renderer: (data) => <span>{data.permission}</span>,
          editor: (data) => (
            <Select defaultValue={data.permission} style={{ width: 150 }}>
              <Select.Option value={GroupPermission.Reader}>{t(GroupPermission.Reader)}</Select.Option>
              <Select.Option value={GroupPermission.Owner}>{t(GroupPermission.Owner)}</Select.Option>
            </Select >)
        }
      ],
    data: currentState.value,
    sortFields: [

    ],
  } as TableModel<GroupUserSummary>

  const bulkUploadOptions = [
    {
      optionLabel: 'Bulk Insert Group members',
      modalName: 'Bulk Insert Group members',
      bulkInsertTemplateName: 'Group members template',
      bulkInsertTemplateUrl: `api/v1/admin/groups/template/` + props.id,
      bulkInsertUrl: `api/v1/admin/groups/bulk_insert/` + props.id,
    }]


  return (
    <div>

      <TableView
        enableTableSettings
        leftToolbar={
          <Row gutter={5}>
            <Col>
              <BulkUploadMenu options={bulkUploadOptions} />
            </Col>
          </Row>
        }
        rowKey={"userId"}
        title={""}
        model={tableModel}
        onQueryChanged={(q: Query) => setQuery(q)}
        onNewItem={() => setnewGroupUserShown(true)}
        onRefresh={() => setQuery(clone<Query>(query))}
        canEdit={true}
        canDelete={true}
        onDeleteRow={(item) => onDeleteRow(item)}
        onSaveRow={(item) => onSaveItem(item)}
        canCreateNew={true}
        error={currentState.errorMessage.value && formatMessage(currentState.errorMessage.value)}
      >
      </TableView>
      {
        newGroupUserShown && <NewGroupUserView onClose={() => { setnewGroupUserShown(false); load(query) }} {...props} />
      }
    </div>
  )
}

// Wire up the React component to the Redux store
export default withTranslation()(withRouter(GroupUsers))
