import * as React from 'react'
import HomeMenu from './menu'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { withIdentity, IdentityProps } from "src/core/services/authentication"
import { withTranslation, WithTranslation } from 'react-i18next'
import { isNullOrWhitespace } from 'src/core/utils/object'
import "./home.scss"
import { LoadingOutlined } from '@ant-design/icons'
import logo from 'src/core/assets/logo.png'
import { Spin } from 'antd'

const Home: React.FC<RouteComponentProps & IdentityProps & WithTranslation> = (props) => {
  const { t } = props
  const getDisplayName = () => {
    var result = `${props.identity.firstName || ''} ${props.identity.lastName || ''}`
    if (isNullOrWhitespace(result)) {
      result = props.identity.profile.name
    }
    return result
  }
  const [displayName] = React.useState(getDisplayName())


  React.useEffect(() => {
    document.title = "Inno.Connect"
  }, [])

  return <>
    <div className={"home-page "}>

      <span className="fi-connect-logo" style={{ marginBottom: "0px", marginTop: "0px" }}></span>
      <hr className={'logo-line'} style={{ margin: "0px", border: 'none', height: '1.5px', backgroundColor: 'white', marginLeft: "20%", marginRight: "20%" }}></hr>
      <h1 className="home-header" style={{ marginBottom: "35px" }}>{t("Welcome")} {displayName}</h1>
      <Route exact path={`${props.match.path}`} component={HomeMenu} />
    </div>
  </>
}

export default withTranslation()(withRouter(withIdentity(Home) as any) as any)
