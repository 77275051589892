import { Dropdown, Menu } from 'antd';
import React, { FC, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IdentityProps, withIdentity } from 'src/core/services/authentication';
import UploadModal from './upload-modal';

export interface BulkUploadOption {
  optionLabel: string;
  modalName: string;
  bulkInsertTemplateName: string;
  bulkInsertTemplateUrl: string;
  bulkInsertUrl: string;
}

interface Props extends WithTranslation, IdentityProps {
  onBulkUploadComplete?: () => void;
  onBulkUploadCancel?: () => void;
  options: BulkUploadOption[];
}

const BulkUploadMenu: FC<Props> = (props: Props) => {
  const [option, setOption] = useState<BulkUploadOption | null>(null);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);

  const { onBulkUploadComplete, onBulkUploadCancel, options, t } = props;

  const handleSelectBulkInsertOption = (option: string | undefined) => {
    const selected = options.find((o) => o.optionLabel === option);
    if (selected) {
      setOption(selected);
      setUploadModalOpen(true);
      return;
    }
    setOption(null);
  };

  const renderSelectionMenu = (
    <Menu onClick={(option: any) => handleSelectBulkInsertOption(option.key)}>
      {options.map((item: BulkUploadOption) => (
        <Menu.Item key={item.optionLabel}>{t(item.optionLabel)}</Menu.Item>
      ))}
    </Menu>
  );


  return (
    <>
      <Dropdown.Button overlay={renderSelectionMenu}>{t('Bulk Upload Options')}</Dropdown.Button>
      <UploadModal
        onBulkUploadComplete={onBulkUploadComplete}
        onBulkUploadCancel={onBulkUploadCancel}
        uploadModalOpen={uploadModalOpen}
        setUploadModalOpen={setUploadModalOpen}
        modalName={t(option?.modalName)}
        bulkInsertTemplateName={t(option?.bulkInsertTemplateName)}
        bulkInsertTemplateUrl={option?.bulkInsertTemplateUrl}
        bulkInsertUrl={option?.bulkInsertUrl}
        hideUploadResult={true}
      />
    </>
  );
};

export default withIdentity(withTranslation()(BulkUploadMenu));
