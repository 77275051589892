import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface UserGroupSummary {
    id: string;
    groupId: string;
    groupName: string;
}

export interface UserGroupReference {
    groupId: string;
    groupName: string;
}

export interface NewUserGroupItem {
    groupId: string;
}

export interface UserGroupItem {
    groupId: string;
    groupName: string;
}

export class UserGroupsStore extends DataStore<UserGroupSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }

    public async changeUser(userId: string) {
        this.baseUrl = this.publicUrl + `/api/v1/admin/users/${userId}/groups`;
    }

  public async update(id: string, item: UserGroupSummary) {
    return this.save(id, item)
  }
}

export class UserGroupsForModalStore extends DataStore<UserGroupSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }

  public async changeUser(userId: string, fromProfile: boolean = false) {
    if(fromProfile) this.baseUrl = this.publicUrl + `/api/v1/profile/users/${userId}/groups`
    else this.baseUrl = this.publicUrl + `/api/v1/admin/users/${userId}/groups`
  }

  public async update(id: string, item: UserGroupSummary) {
    return this.save(id, item)
  }
}

export class UserGroupItemStore extends FormStore<NewUserGroupItem, NewUserGroupItem > {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`)
    this.publicUrl = baseUrl
  }

  public async changeUser(userId: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/users/${userId}/groups`
  }

  public async update(id: string, item: UserGroupSummary) {
    return this.save(id, item)
  }
}