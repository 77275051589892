import { notification } from 'antd';

export interface FloatingNotificationSettings {
    type?: 'success' | 'info' | 'warning' | 'error';
    message: string;
    description: string;
    key: string;
    duration?: number;
    autoClose: boolean
    customIcon?: any;
    placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
}

export function notify(notificationSettings: FloatingNotificationSettings) {
    let secondsToGo = notificationSettings.autoClose ? (notificationSettings.duration ? notificationSettings.duration : 4) : 0;
    let settings: any = {
        key: notificationSettings.key,
        message: notificationSettings.message + (notificationSettings.autoClose ? ` (${secondsToGo}).` : ''),
        description: notificationSettings.description,
        placement: notificationSettings.placement ? notificationSettings.placement : 'topRight',
        duration: 0

    };
    if (!notificationSettings.type) {
        if (notificationSettings.customIcon) {
            settings['icon'] = notificationSettings.customIcon;
        }

    } else {
        notification[notificationSettings.type](settings);
    }

    if (notificationSettings.autoClose) {
        const timer = setInterval(() => {
            secondsToGo -= 1;
            settings = {
                ...settings,
                message: notificationSettings.message + ` (${secondsToGo}).`,
            };
            if (secondsToGo === -1) {
                clearInterval(timer);
                notification.close(notificationSettings.key);
            } else {
                if (!notificationSettings.type) {
                    notification.open(settings);
                } else {
                    notification[notificationSettings.type]({
                        ...settings,
                        onClose: () => clearInterval(timer)
                    });
                }
            }
        }, 1000);
    }
}