import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router'
import Login from './login'
import ChangePassword from './changePassword'
import AccessDenied from './accessDenied'

interface LoginRoutesProps extends RouteComponentProps {

}
const LoginRoutes: React.FC<LoginRoutesProps> = (props) => {


    return <Switch>
        <Route path={`${props.match.path}/changePassword`} component={ChangePassword} />,
        <Route path={`${props.match.path}/login`} component={Login} />,
        <Route path={`${props.match.path}/logout`} component={Login} />,
        <Route path={`${props.match.path}/accessDenied`} component={AccessDenied} />
    </Switch>

}
export default withRouter(LoginRoutes)
