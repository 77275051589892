import { Badge, notification, Spin } from 'antd'
import React, { FC, useEffect, useRef, useState } from 'react'
import { container } from 'src/inversify.config'
import { TaskItem, TaskItemStore } from 'src/stores/systemNotifications/notification-store'
import { WithTranslation, withTranslation } from 'react-i18next'
import { NotificationType } from './notification-Item'
import { Query } from '../../core/stores/data-store'
import { FileExcelOutlined, LoadingOutlined, SettingOutlined } from '@ant-design/icons'

export interface TaskListProps extends WithTranslation {
  reload: number
}
export interface TaskListState {
  query: Query,
  busy: boolean,
  keys: string[]
}
const TaskList: FC<TaskListProps> = (props) => {

  const { t, reload } = props
  const [keys, setKeys] = useState([])
  const [busy, setBusy] = useState(false)
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'startTime', direction: 'Descending', useProfile: false }],
    skip: 0,
    take: 25,
  } as Query)

  const currentStore = container.get<TaskItemStore>(TaskItemStore)
  const currentState = currentStore.state
  const ref = useRef();

  useEffect(() => {
    Load();
  }, [reload,ref]) // eslint-disable-line react-hooks/exhaustive-deps

  const Load = async () => {
    await currentStore.load(query)
    openNotification()
  }

  const GetTitle = (type: string) => {
    switch (type) {
      case NotificationType.AdminReport:
        return <><FileExcelOutlined style={{ marginRight: 10 }}></FileExcelOutlined>  {t("Creating Admin Report...")} </>
      case NotificationType.BulkInsertGroupMembers:
        return <><SettingOutlined style={{ marginRight: 10 }}></SettingOutlined>  {t("Inserting Group Members...")} </>
      case NotificationType.BulkInsertPermisionRequestForTenant:
        return <><SettingOutlined style={{ marginRight: 10 }}></SettingOutlined>  {t("Inserting Permission Request for Tenant...")} </>
      case NotificationType.BulkInsertUsers:
        return <><SettingOutlined style={{ marginRight: 10 }}></SettingOutlined>  {t("Inserting Users...")} </>
      case NotificationType.BulkInsertGroupsWithTenant:
        return <><SettingOutlined style={{ marginRight: 10 }}></SettingOutlined>  {t("Inserting Groups with tenants...")} </>
      case NotificationType.BulkInsertUserAndPermisionRequestForTenant:
        return <><SettingOutlined style={{ marginRight: 10 }}></SettingOutlined>  {t("Inserting Permission Requets for tenants...")} </>
      case NotificationType.BulkInsertUsersWithTenant:
        return <><SettingOutlined style={{ marginRight: 10 }}></SettingOutlined>  {t("Inserting Users with tenants...")} </>
      case NotificationType.ExportMembers:
        return <><FileExcelOutlined style={{ marginRight: 10 }}></FileExcelOutlined>  {t("Exporting members...")} </>
      case NotificationType.ExportTenants:
        return <><FileExcelOutlined style={{ marginRight: 10 }}></FileExcelOutlined>  {t("Exporting tenants...")} </>
    }
  }


  const openNotification = () => {
    var items = currentState.items.get()
    var currentkeys = keys
    for (let i = 0; i < items.length; i++) {
      var x = items[i]
      if (!currentkeys.any(k => k == x.id)) {
        notification.success({
          message: <>{GetTitle(x.type)} </>,
          duration: 0,
          closeIcon: <></>,
          className: "custom-notification-process",
          icon: <><LoadingOutlined style={{ color: "rgb(14,14,79)" }} /> </>,
          key: x.id,
          placement: "bottomLeft",
        })
        currentkeys.push(x.id)
      }
    }
    for (let i = 0; i < currentkeys.length; i++) {
      if (!items.any(x => x.id == keys[i])) {
        notification.close(keys[i])
      }
    }
    setKeys(currentkeys)
  }
  

  return (<React.Fragment>

  </React.Fragment>)

};

export default withTranslation()(TaskList)