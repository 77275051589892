import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface GroupSummary {
  id: string,
  name: string,
  description: string
  areaName: string
  areaId: string
}

export interface GroupReference {
  id: string
  name: string,

}

export interface NewGroupItem {
  id: string,
  name: string,
  description: string
  areaId: string
}

export interface GroupItem {
  id: string,
  name: string,
  description: string,
  areaId: string
}

export class GroupsStore extends DataStore<GroupSummary> {

  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/groups`, [])
    this.publicUrl = baseUrl
  }

  public async update(id: string, item: GroupSummary) {
    return this.save(id, item)
  }
}

export class GroupItemStore extends FormStore<GroupItem, NewGroupItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/groups`)
    this.publicUrl = baseUrl
  }

  public async update(id: string, item: GroupItem) {
    return this.save(id, item)
  }
}
