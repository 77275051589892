import { CloseOutlined, NotificationOutlined } from '@ant-design/icons'
import { Alert, Badge, Card, Spin, Row, Col } from 'antd'
import FileSaver from 'file-saver'
import React, { FC, ReactNode, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import HttpService from '../../core/services/http.service'
import { container } from '../../inversify.config'
import { NotificationItem } from 'src/stores/systemNotifications/notification-store'
import ExportNotification from './export-notification'
import BulkInsertNotification from './bulk-insert-notification'

export enum NotificationType {
  ExportMembers = 'ExportMembers',
  BulkInsertUsers = 'BulkInsertUsers',
  BulkInsertGroupMembers = "BulkInsertGroupMembers",
  BulkInsertUsersWithTenant = "BulkInsertUsersWithTenant",
  BulkInsertGroupsWithTenant = "BulkInsertGroupsWithTenant",
  ExportTenants = 'ExportTenants',
  BulkInsertPermisionRequestForTenant = "BulkInsertPermisionRequestForTenant",
  BulkInsertUserAndPermisionRequestForTenant = "BulkInsertUserAndPermisionRequestForTenant",
  AdminReport = "AdminReport"
}

interface Props extends WithTranslation {
  notification: NotificationItem
  openNotification?: () => void
  removeNotification?: () => void
}

const NotificationView: FC<Props> = (props) => {
  const { t, notification } = props
  const [isBusy, setIsBusy] = useState(false)
  const httpService = container.get(HttpService)
  const downloadExcel = async (data: any, fileName: string) => {
    setIsBusy(true)
    const result = await httpService.get(`api/v1/admin/filestorage/downloadExcel?url=` + data, {
      responseType: 'arraybuffer',
    })
    const blob = new Blob([result.data as any], { type: result.headers['content-type'] });
    (FileSaver as any).saveAs(blob, `${t(fileName)}.xlsx`)
    setIsBusy(false)
  }

  const getNotificationContent = (): ReactNode => {
    switch (notification.type) {
      case NotificationType.ExportMembers:
        return <ExportNotification
          title={t('Export Member Success')}
          fileName={t('Members')}
          notification={notification}
          downloadExcel={downloadExcel}
        />
      case NotificationType.ExportTenants:
        return <ExportNotification
          title={t('Export Tenants Success')}
          fileName={t('Tenants')}
          notification={notification}
          downloadExcel={downloadExcel}
        />
      case NotificationType.AdminReport:
        return <ExportNotification
          title={t('Admin Report created Successfully')}
          fileName={t('All Admin')}
          notification={notification}
          downloadExcel={downloadExcel}
        />
      case NotificationType.BulkInsertUsers:
        return <BulkInsertNotification notification={notification} />
      case NotificationType.BulkInsertUsersWithTenant:
        return <BulkInsertNotification notification={notification} />
      case NotificationType.BulkInsertGroupsWithTenant:
        return <BulkInsertNotification notification={notification} />
      case NotificationType.BulkInsertGroupMembers:
        return <BulkInsertNotification notification={notification} />
      case NotificationType.BulkInsertPermisionRequestForTenant:
        return <BulkInsertNotification notification={notification} />
      case NotificationType.BulkInsertUserAndPermisionRequestForTenant:
        return <BulkInsertNotification notification={notification} />

    }

    return <>{notification.type}</>
  }

  return (
    <div className="notification-item">
      <Spin spinning={isBusy}>
        <Card
          title={
            <Row style={{ flexFlow: 'row' }}>
              <Col flex="none">
                <span style={{ marginRight: '10px' }}>
                  <Badge count={notification.readed ? 0 : undefined} dot>
                    <NotificationOutlined />
                  </Badge>
                </span>
              </Col>
              <Col flex="auto">
                <div style={{ whiteSpace: 'normal' }}>{t(notification.description)}</div>
              </Col>
            </Row>
          }
          extra={
            <CloseOutlined
              onClick={() => {
                if (props.removeNotification) props.removeNotification()
              }}
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
            />
          }
        >
          {getNotificationContent()}
        </Card>
      </Spin>
    </div>
  )
}

export default withTranslation()(NotificationView)
