import * as React from 'react'
import { Form, Spin, Input, Modal, Alert } from 'antd'
let FormItem = Form.Item
import autobind from 'autobind-decorator'
import { PatItemStore, NewPatItem } from 'src/stores/pats/pats-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { nameof } from 'src/core/utils/object'
import { DateInput } from 'src/core/ui/form/floatDateInput'
import ApplicationEditor from 'src/components/applicationEdit'
import moment from 'moment'
import { container } from '../../../inversify.config'
import FloatInput from '../../../core/ui/awesome/floatComponent/input'

interface NewPatViewProps {
  onClose: () => void,
  onCreated: (patValue: string) => void
}

const NewPatView: React.FC<NewPatViewProps & WithTranslation> = (props) => {
  const { t } = props
  const [form] = Form.useForm<NewPatItem>()
  const [initialValues, setInitialValues] = React.useState<NewPatItem | undefined>({
    name: '',
    applicationName: '',
    expiresAt: undefined
  })

  const currentStore = React.useMemo(() => container.get(PatItemStore), [])
  const currentState = currentStore.state



  const onCancel = () => {
    props.onClose()
  }

  const onCreate = async () => {
    let item: any
    try {
      item = (await form.validateFields()) as any
    } catch {
      return
    }
    item.applicationName = item.applicationName.name

    let response = await currentStore.create(item)
    if (response) {
      form.resetFields()
      props.onCreated(response.item.value)
      props.onClose()
    }
  }

  const validateExpirationDate = (rule: any, value: any, callback: any) => {


    if (value && moment(value).isBefore(moment.now())) {
      callback(t("The expiration date must be greater than today's date"))
    }

    callback()
  }

  const defaultExpiration = new Date()
  defaultExpiration.setHours(0, 0, 0, 0)
  defaultExpiration.setFullYear(defaultExpiration.getFullYear() + 5)
  return (
    <Modal
      maskClosable={false}
      visible
      onCancel={() => onCancel()}
      onOk={() => onCreate()}
      closable={false}
      title={t('New Personal Access Token (PAT)')}
      cancelText={t('Cancel')}
      okText={t('Ok')}
    >
      {currentState.errorMessage.get() && <Alert type="error" message={t(currentState.errorMessage.value || '')} />}
      <Spin spinning={currentState.isBusy.get()}>
        <Form id="modaForm" layout={"vertical"} form={form} initialValues={initialValues}>
          <FormItem
            name={nameof<NewPatItem>('name')}
            rules={[{ required: true, message: t("Please input your PAT's name!") }]}>

            <FloatInput required label={t("Name")} placeholder={t("Name")} />
          </FormItem>
          <FormItem
            name={nameof<NewPatItem>('applicationName')}
            rules={[{ required: true, message: t("Please select your PAT's Application!") }]}>

            <ApplicationEditor required label={t("Application")} valueAsItemReference placeholder={t("Application")} />

          </FormItem>
          <FormItem 
            name={nameof<NewPatItem>('expiresAt')}
            rules={[{ validator: validateExpirationDate }]}>

            <DateInput placeholder={t("Expires at")} label={t("Expires at")} />
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  )
}
export default withTranslation()(NewPatView) 