
export enum IdentityType {
  Unknown = 'Unknown',
  User = 'User',
  Group = 'Group',
}

export interface EditMemberDialogFormData {
    roles: RoleFormData[]
}
export interface RoleFormData { value: string; label: string, applicationId: string, applicationName: string, hierarchyType: HierarchyType }

export enum HierarchyType {
    Own = 'Own',
    ByHierarchy = 'ByHierarchy',
    Both = 'Both',
}