import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { Spin, Alert, Modal, Tag, Radio, Button, Tooltip, notification, Switch } from 'antd'
import { container } from 'src/inversify.config'
import {
  TenantMemberDataStore,
  TenantMemberFormStore,
  TenantMemberSummary,
} from 'src/stores/tenants/tenant-members-store'
import { nameof } from 'src/core/utils/object'
import HttpService, { formatMessage } from 'src/core/services/http.service'
import { UserSummary } from 'src/stores/users/users-store'
import Form from 'antd/lib/form'
import UserSelect from 'src/components/userEdit'
import GroupSelect from 'src/components/groupEdit'
import RolesSelect from 'src/components/roleEdit'
import ApplicationsSelect from 'src/components/applicationEdit'
import { HierarchyType, IdentityType } from 'src/stores/tenants/common'
import { Query } from 'src/core/stores/data-store'
import { TableModel, TableView, getDefaultTableSettings } from 'src/core/ui/collections/table'
import { DownloadOutlined, EditOutlined, ExportOutlined, TeamOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import { AddMemberDialog } from './tenant-details-members-tab-add'
import EditMemberDialog from 'src/pages/admin/tenants/components/identity-tenant-roles-editor'
import FileSaver from 'file-saver'
import { successNotification } from '../../../../../components/systemNotification/notificationService'
import { IdentityProps } from "src/core/services/authentication"

export const TenantDetailsMembersTab: FC<{ entityId: string } & WithTranslation & IdentityProps> = (props) => {
  const { t, entityId } = props
  const entityMemberDataStore = container.get<TenantMemberDataStore>(TenantMemberDataStore)
  const entityMemberDataState = entityMemberDataStore.state
  const httpService = container.get(HttpService)
  const isAdmin = props.identity.roles && props.identity.roles.some(role => role === "Administrator")

  const [isBusy, setIsBusy] = useState(false)
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'displayName', direction: 'Ascending', useProfile: true }],
    skip: 0,
    take: getDefaultTableSettings().defaultPageSize,
    parameters: {
      "includeRoles": "false",
      "excludeUserGlobal": "true"
    }
  } as Query)

  const [addMemberVisible, setAddMemberVisible] = useState(false)
  const [editMemberVisible, setEditMemberVisible] = useState(false)
  const [selectedMember, setSelectedMember] = useState<TenantMemberSummary>()

  useEffect(() => {
    entityMemberDataStore.setTenantId(entityId)
    entityMemberDataStore.load(query)

  }, [entityId, query]) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleExcludeUserGlobal = () => {
    setQuery(prevQuery => ({
      ...prevQuery,
      parameters: {
        ...prevQuery.parameters,
        excludeUserGlobal: prevQuery.parameters.excludeUserGlobal === "false" ? "true" : "false"
      }
    }))
  }

  const tableModel = {
    query: query,
    columns: [
      {
        title: t(''),
        renderer: (data: TenantMemberSummary) => (
          <span>
            <EditOutlined
              onClick={() => {
                setSelectedMember(data)
                setEditMemberVisible(true)
              }}
            />
          </span>
        ),
      },
      {
        sortable: true,
        searchable: true,
        field: 'displayName',
        title: t('Display Name'),
        renderer: (data: TenantMemberSummary) => (
          <span>
            {data.identityType === IdentityType.Group ? <TeamOutlined /> : <UserOutlined />}&nbsp;<span>{!data.displayName || data.displayName.length == 0 ? t("Empty Name") : data.displayName}</span>
          </span>
        ),
      },
      {
        sortable: true,
        searchable: true,
        field: 'email',
        title: t('Email'),
        renderer: (data: TenantMemberSummary) => data.identityType === IdentityType.Group ? "" : <span>{data.email}</span>,
      },

      {
        sortable: false,
        searchable: false,
        field: 'byHierarchy',
        title: t('Inheritance'),
        renderer: (data: TenantMemberSummary) => <> {
          (data.hierarchyType != HierarchyType.Own) &&
          <Tag style={{ fontSize: "12px", padding: "2px 15px", cursor: "text" }} key={data.id} color={"cadetblue"}>
            {t("Inherited")}</Tag>
        }

        </>
      },
    ],
    data: entityMemberDataState.value,
    sortFields: [],
  } as TableModel<TenantMemberSummary>

  const downloadExcel = async () => {
    setIsBusy(true)
    const result = await httpService.get(`api/v1/admin/tenants/${entityId}/members/export`)
    successNotification(t('Operation Completed Successfully'), t('Your operation will be processed in background.'))
    setIsBusy(false)
  }

  var rightToolbar = (
    <React.Fragment>
      {
        (isAdmin) &&
        <li>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '8px' }}>{t("Include Users Global")}</span>
            <Switch
              defaultChecked
              checked={query.parameters.excludeUserGlobal === "false"}
              onChange={toggleExcludeUserGlobal}
              style={{ display: 'flex', alignItems: 'center', height: '100%', marginTop: 4 }}
              className="vertical-centered-switch"
            />
          </label>
          <style>
            {`
                            .toolbar button {
                                min-height: 23px !important;
                                min-width: 36px !important;
                                padding: 0px 5px !important;
                            }
                        `}
          </style>
        </li>
      }
      <li>
        <Tooltip title={t("Export")}> <Button onClick={() => downloadExcel()} icon={<ExportOutlined />} /> </Tooltip>
      </li>

    </React.Fragment>
  )
  return (
    <Spin spinning={isBusy}>
      <TableView
        enableTableSettings
        rowKey="id"
        canCreateNew
        canDelete
        showDeleteStatusWindow
        rowClassName={(item: any) => { if (item.hierarchyType != HierarchyType.Own) return "hide-select" }}
        canSelectRow={(item: any) => { return item.hierarchyType != HierarchyType.Own }}
        onDeleteRow={(item: any) => entityMemberDataStore.delete(item.id)}
        onNewItem={() => setAddMemberVisible(true)}
        onQueryChanged={(query: Query) => {
          setQuery(query)
          entityMemberDataStore.load(query)
        }}
        rightToolbar={rightToolbar}
        onRefresh={() => entityMemberDataStore.load(query)}
        model={tableModel}
        error={entityMemberDataState.errorMessage.value && formatMessage(entityMemberDataState.errorMessage.value)}
      />
      {addMemberVisible && (
        <AddMemberDialog
          onClose={() => {
            setAddMemberVisible(false)
            entityMemberDataStore.load(query)
          }}
          {...props}
        />
      )}
      {editMemberVisible && (
        <EditMemberDialog
          identityId={(selectedMember as TenantMemberSummary).identityId}
          onClose={() => {
            setEditMemberVisible(false)
            setSelectedMember(undefined)
            entityMemberDataStore.load(query)
          }}

          {...props}
        />
      )}
    </Spin>
  )
}
