import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface AreaItem {
  id: string,
  name: string,
  default: boolean,
  system: boolean,
}
export interface NewAreaItem {
  name : string,
}

//added to fix new user issue
export interface AreaSummary {
    id: string,
    name: string,
    default: string,
    system: boolean,
}

export class AreasStore extends DataStore<AreaItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/areas`, [])
    this.publicUrl = baseUrl
  }

  public async update(id: string, item: AreaItem) {
    return this.save(id, item)
  }
}

export class AreaItemStore extends FormStore< AreaItem, NewAreaItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/areas`)
    this.publicUrl = baseUrl
  }
}