import { container } from 'src/inversify.config'
import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store';
import { CommandResult } from '../../core/stores/types';
import { UserSummary } from '../users/users-store';

export interface GroupRoleSummary {
    id: string;
    roleId: string;
    applicationId: string;
    applicationName: string;
    roleName: string;
}

export interface GroupRoleReference {
    roleId: string;
    roleName: string;
}

export interface NewGroupRoleItem {
    applicationId: string;
    roleId: string;
}

export interface GroupRoleItem {
    roleId: string;
    roleName: string;
    applicationId: string;
    applicationName: string;
}


export class GroupRolesStore extends DataStore<GroupRoleSummary> {

  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }
  public async changeGroup(groupId: string) {
    this.baseUrl = this.publicUrl +`/api/v1/admin/groups/${groupId}/roles`;
    }     

  public async update(id: string, item: GroupRoleSummary) {
    return this.save(id, item)
  }
   
}

export class GroupRoleItemStore extends FormStore<NewGroupRoleItem,NewGroupRoleItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`)
    this.publicUrl = baseUrl
  }

  public changeRole(groupId: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/groups/${groupId}/roles`;
    }
}