import React, { CSSProperties, FC, useEffect, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { container } from 'src/inversify.config'
import { Select, Spin, Tooltip } from 'antd'
import debounce from 'lodash/debounce'
import { Query } from 'src/core/stores/data-store'
import { TenantSummary, TenantSummaryForSelectStore } from 'src/stores/tenants/tenants-store'
import { ApartmentOutlined } from '@ant-design/icons'
import FloatSelect from '../core/ui/awesome/floatComponent/select'

const EntitySelect: FC<
  {
    label?: string
    required?: boolean
    excludeDescendand?: string
    addDefault?: boolean
    onChange?: (value: { value: string; label: string }) => void
    exclude?: string[]
    placeholder?: string
    value?: { value: string; label: string }
    fromProfile?: boolean
    notNulleable?: boolean
    loadTenantId?: any

  } & WithTranslation
  > = ({ t, onChange, exclude, placeholder = t('Select entity'), value, addDefault, excludeDescendand, fromProfile, notNulleable, loadTenantId, required, label, ...props }) => {

  const entitiesStore = container.get<TenantSummaryForSelectStore>(TenantSummaryForSelectStore)

  const [currentValue, setValue] = useState(value)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<TenantSummary[]>([])

  const load = async (search: string | undefined = undefined) => {
    setLoading(true)
    const items = await entitiesStore.getItems({
      searchQuery: search,
      orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
      skip: 0,
      parameters: {
        "excludeDescendand": excludeDescendand,
      },
      take: 25,
      filter: (exclude || []).length === 0 ? [] : [`id ne ${exclude?.join(' and id ne ')}`],
    } as Query, fromProfile)
    setOptions(items)
    if (notNulleable && items.length > 0) {
      setValue({ value: items[0].id, label: items[0].title })
      loadTenantId(items[0].id)
      if (onChange) onChange({ value: items[0].id, label: items[0].title })
    }
    setLoading(false)
  }

  useEffect(() => {
    let nextValue = value || currentValue
    if (nextValue && (exclude || []).includes(nextValue.value)) nextValue = undefined
    if (!nextValue && addDefault) nextValue = {
      value: "00000000-0000-0000-0000-000000000000", label: t("Global")
    }
    load()
    setValue(nextValue)
  }, [exclude, entitiesStore, value]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = debounce((value: string) => {
    load(value)
  }, 800)

  const onSelect = (nextValue: { value: string; label: string }) => {
    setValue(nextValue)
    onChange && onChange(nextValue)
  }

  return (
    <FloatSelect
      required={required}
      label={label}
      loading={!!loading}
      labelInValue
      placeholder={placeholder}
      onChange={onSelect}
      allowClear={!notNulleable}
      showSearch
      value={currentValue}
      notFoundContent={!!loading ? <Spin size="small" /> : undefined}
      filterOption={false}
      showArrow={true}
      onSearch={onSearch}
      style={{ width: '100%' }}
      {...props}

    >
      {addDefault && <Select.Option key={"global"} value={"00000000-0000-0000-0000-000000000000"}>
        {t("Global")}
      </Select.Option>}
      {options.map((o: TenantSummary) => (
        <Select.Option key={o.id} value={o.id}>
          <Tooltip
            title={o.title} placement="leftTop"
            overlayStyle={{ zIndex: 9999 }}
            overlayInnerStyle={{ backgroundColor: 'white', color: 'black' }}>
            <ApartmentOutlined style={{ marginRight: 5 }} />
            {o.title || o.title.trim().length != 0 ? o.title : t("(Empty name)")}
          </Tooltip>
        </Select.Option>
      ))}
    </FloatSelect>
  )
}

export default withTranslation()(EntitySelect) as any
