import { Button, Card, Col, Modal, Row, Skeleton, Spin } from 'antd'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { FC, useMemo, useState } from 'react'
import logo from '../core/assets/logo.png'
import { IdentityProps, withIdentity } from '../../core/services/authentication'
import { container } from '../../inversify.config'
import { DashBoardStore } from '../../stores/dashBoard/dashBoard-store'

import { CrownOutlined, HeatMapOutlined, SettingOutlined, SyncOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'

import UserEdit from '../userEdit'


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)



export interface AdminPerAreasProps extends WithTranslation, IdentityProps {
  text: string
}

const AdminPerAreas: FC<AdminPerAreasProps> = ({
  identity,
  t,
  text,
  children,
}) => {

  const [loading, setIsLoading] = useState<boolean>(false)
  const currentStore = useMemo(() => container.get(DashBoardStore), [])
  const currentState = currentStore.state
  const [data, setData] = useState<any>()
  const [zoom, setZoom] = useState(false)
  const inputRef = React.useRef()

  const [userId, setUserId] = useState<string | undefined>()

  const Impersonate = async () => {
    setIsLoading(true)
    await currentStore.identifyAs(userId)
    window.location.href = identity.identityUrl
  }

  return <>

    <div onClick={() => { setZoom(!zoom) }} className="admin-tile-special cursor-hand color-white bg-gradient-danger">
      <div >
        <div  className="admin-tile-icon bg-gradient-success">   <HeatMapOutlined /></div>
        <div className="admin-tile-text">{t('Identify As...')}</div>
      </div>
    </div>
    {zoom &&
      <Modal
        title={ t("Identity as...")}
        open={zoom}
        onOk={() => { Impersonate(); }}
        onCancel={() => setZoom(false)}
      >
        <Spin spinning={loading}>
          <Row gutter={50}>
            <Col span={24}><UserEdit placeholder={t("Select user to impersonate identity")} valueAsItemReference onChange={(value) => setUserId(value?.id)} /></Col>
          </Row>

        </Spin>
      </Modal>


    }
  </>

}


export default withIdentity(withTranslation()(AdminPerAreas) as any)
