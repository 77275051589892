import { Card, Modal, Skeleton, Spin } from 'antd'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { FC, useMemo, useState } from 'react'
import logo from '../core/assets/logo.png'
import { IdentityProps, withIdentity } from '../../core/services/authentication'
import { container } from '../../inversify.config'
import { DashBoardStore } from '../../stores/dashBoard/dashBoard-store'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import { SyncOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)


export interface LastLoginProps extends WithTranslation, IdentityProps {
  text: string
}

const LastLogin: FC<LastLoginProps> = ({
  identity,
  t,
  text,
  children,
}) => {

  const [loading, setIsLoading] = useState<boolean>(true)
  const currentStore = useMemo(() => container.get(DashBoardStore), [])
  const currentState = currentStore.state
  const [data, setData] = useState([])
  const inputRef = React.useRef()
  const [zoom, setZoom] = useState(false)

  const LoadPage = async () => {
    let chart1 = await currentStore.GetLastHourLogin()
    setData(chart1)
    setIsLoading(false)
  }

  React.useEffect(
    () => {
      LoadPage()
    }, [inputRef]
  )

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: t('Login success per hour'),
      },
    },
  }
  const CreateData = () => {
    let labels = []
    for (let i = 8; i >= 0; i--) {
      let HoursBefore = new Date()
      HoursBefore.setHours(HoursBefore.getHours() - i)
      let format = "hh:00"
      var time = moment(HoursBefore).format(format)
      labels.push(time)
    }
    let currentdata = []


    const current = {
      labels,
      datasets: [
        {
          label: t('Login Success'),
          data: data,
          borderColor: '#1a73e8',
          backgroundColor: '#1a73e8',
        },

      ],
    }
    return current

  }

  const Refresh = async () => {
    setIsLoading(true)
    await LoadPage()
  }


  return (
    <>
      <div className={"report-container"} >
        <Card
          style={{}}
          actions={
            !zoom ? [
              <ZoomInOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
              <SyncOutlined onClick={() => Refresh()} key="refresh" />


            ] :
              [
                <ZoomOutOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
                <SyncOutlined onClick={() => Refresh()} key="refresh" />
              ]
          }
        >
          <Skeleton loading={loading} avatar active>
            {data && <Line options={options} data={CreateData()} />}
          </Skeleton>
        </Card>
      </div>
      {zoom && <div className={zoom ? "report-fullscreen report-container opacity-1" : "report-fullscreen report-container opacity-0"} >
        <Card
          style={{}}
          actions={
            !zoom ? [
              <ZoomInOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
              <SyncOutlined onClick={() => Refresh()} key="refresh" />


            ] :
              [
                <ZoomOutOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
                <SyncOutlined onClick={() => Refresh()} key="refresh" />
              ]
          }
        >
          <Skeleton loading={loading} avatar active>
            {data && <Line options={options} data={CreateData()} />}
          </Skeleton>
        </Card>
      </div>
      }
    </>
  )


}


export default withIdentity(withTranslation()(LastLogin) as any)
