import DataStore from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface ScopedPermissionSummary {
    id: string;
    permissionName: string;
}

export class ScopedPermissionsStore extends DataStore<ScopedPermissionSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`, [])
    this.publicUrl = baseUrl
  }

  changeUrl(entityId: string, entityType: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/scopedpermission/${entityId}/${entityType}`;
    }
 
}

export interface NewScopedPermissionItem {
    permissionId: string;
    identityType: string;
}


export class ScopedPermissionItemStore extends FormStore<NewScopedPermissionItem, NewScopedPermissionItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}`)
    this.publicUrl = baseUrl
  }

  changeUrl(entityId: string, entityType: string) {
    this.baseUrl = this.publicUrl + `/api/v1/admin/scopedpermission/${entityId}/${entityType}`
  }
}