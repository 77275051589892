import React, { useEffect, useState } from 'react'
import { Form, Row, Button, Input, Modal, Card, Layout, Alert, Spin, Tooltip } from 'antd'
import HttpService from 'src/core/services/http.service'
import * as queryString from 'query-string'
import { withRouter, RouteComponentProps, useHistory } from "react-router"
import QueueAnim from 'rc-queue-anim'
import { ConfigurationService } from 'src/core/services/configuration.service'
const { Content } = Layout
import { withTranslation, WithTranslation } from 'react-i18next'
import { LockOutlined } from '@ant-design/icons'
import { AppEnvironment, BaseAppUrl, container } from '../../inversify.config'
import LanguageBox from 'src/components/language-box'
import FooterView from "src/core/ui/footer"
import whiteLogo from 'src/assets/logo-connect-bk.png'
import "./login.scss"
import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';


export interface ChangePasswordProps extends WithTranslation, RouteComponentProps {
    children?: React.ReactNode
}



const ChangePasswordView: React.FC<ChangePasswordProps> = (props) => {

    const httpService: HttpService = container.get(HttpService)


    const configurationService: ConfigurationService = container.get(ConfigurationService)

    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState(false)
    const [isBusy, setIsBusy] = useState(false)
    const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null)
    const [form] = Form.useForm<any>()
    const { t } = props

    const history = useHistory()

    const validateToken = async () => {
        setIsBusy(true);
        var parsed = queryString.parse(props.location.search);

        try {
            const result = await httpService.get<any>(`${BaseAppUrl}/api/v1/account/validateToken/${parsed["identityName"]}/${(parsed["token"] as string || "").replace(/\s/g, '+')}`);

            if (!result.data.success) {
                setIsBusy(false);
                setIsTokenValid(false);

                Modal.info({
                    title: 'Info',
                    content: t(`${result.data.errors}`),
                    centered: true,
                    onOk: () => { window.location.href = `${BaseAppUrl}` }
                });

                return;
            } else {
                setIsBusy(false);
                setIsTokenValid(true);
            }
        } catch (error) {
            setIsBusy(false);
            setIsTokenValid(false);
            Modal.error({
                title: 'Error',
                content: t(`${error}`),
                centered: true,
                onOk: () => { window.location.href = `${BaseAppUrl}` }
            });
        }
    }

    useEffect(() => {
        validateToken();
    }, [props.location.search]);

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        var parsed = queryString.parse(props.location.search)
        setIsBusy(true)
        try {
            var values = (await form.validateFields())
            if (values.password != values.confirmPassword) {
                setErrors(["Passwords don't match"])
                setSuccess(false)
                setIsBusy(false)
                return
            }

            httpService.post<any, any>(`${BaseAppUrl}/api/v1/account/changePassword`, {
                identityName: parsed["identityName"],
                token: (parsed["token"] as string || "").replace(/\s/g, '+'),
                newPassword: values.password,
                returnUrl: parsed["returnUrl"]
            }).then(result => {
                if (!result.data.success) {
                    setErrors(result.data.errors)
                    setSuccess(false)
                    setIsBusy(false)
                } else {
                    setErrors([])
                    setSuccess(true)
                    setIsBusy(false);
                    var returnUrl = parsed["returnUrl"]
                    if (returnUrl) {
                        window.location.href = returnUrl as string
                    } else {
                        configurationService.current().then(config => {
                            window.location.href = config.serviceUrl
                        })
                    }
                }
            }).catch(error => {
                if (error.response && !error.response.data.success) {
                    setSuccess(false)
                    setIsBusy(false);
                    setErrors(Array.isArray(error.response.data.errors) ? error.response.data.errors : [error.response.data.title])

                    return
                }
                Modal.error({
                    title: 'Error',
                    content: `${error}`,
                })
            })
        } catch {
            setIsBusy(false);
            return
        }
    }

    const environmet = AppEnvironment
    const PasswordRequeriments = (
        <div>
            <ul>
                <li>{t('At least 12 characters long.')}</li>
                <li>{t('At least one uppercase letter, one lowercase letter, one number, and one symbol.')}</li>
                <li>{t('Password must be changed every 90 days.')}</li>
                <li>{t('Cannot reuse any of the last 7 passwords.')}</li>
            </ul>
        </div>
    );

    return (
        <Layout className={"login-layout " + environmet}>
            <Content className='password-layout'>
                <Row>
                    <img src={whiteLogo} title="logo" width={200} />
                    <LanguageBox />
                </Row>
                <Spin spinning={isBusy}>
                    {isTokenValid &&
                        <Card className="password-card">

                            <Form size="large" form={form} layout="vertical" className='password-form'>
                                <QueueAnim>
                                    {errors && errors.length > 0 && errors.map(error => <Alert key={error} message={t(error)} type="error" showIcon />)}
                                </QueueAnim>

                                <p>{t('Please enter your password. Remember that the login name is your email address.')}</p>
                                {success && <Alert message={t("Password has been successfully reseted")} type="success" showIcon />}
                                <Tooltip title={PasswordRequeriments} placement="top" style={{ marginBottom: '20px' }}>
                                    <InfoCircleOutlined /> {t('Password Requirements')}
                                </Tooltip>
                                <Row className='password-fields'>
                                    <Form.Item
                                        label={t('Password')}
                                        name={'password'}
                                        rules={[{ required: true, message: t('Enter your password') }]}
                                    >
                                        <Input autoComplete="off" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={t('Password')} />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('Confirm password')}
                                        name={'confirmPassword'}
                                        rules={[{ required: true, message: t('Re-enter your password') }]}
                                    >
                                        <Input autoComplete="off" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={t('Password')} />
                                    </Form.Item>
                                </Row>

                                <Row className='password-buttons'>
                                    <Button type="primary" onClick={() => history.goBack()} >{t('Cancel')}</Button>
                                    <Button type="primary" onClick={handleSubmit}>{t('Create Password')}</Button>
                                </Row>

                            </Form>
                        </Card>
                    }
                </Spin>
            </Content>
            <FooterView footerLogo={whiteLogo} />
        </Layout>
    )
}

export default withTranslation()(withRouter(ChangePasswordView) as any)