import React, { useState } from "react"
import { DatePicker } from 'antd';

import "./float.less"

const FloatDatePicker = (props) => {
  const [focus, setFocus] = useState(false)
  let { label, value, placeholder, type, required, prefix } = props

  if (!placeholder) placeholder = label

  const isOccupied = focus || (value && value.length !== 0)

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder"

  const requiredMark = required ? <span className="text-danger">*</span> : null

  return (
    <div
      className={"float-label custom-datepicker" + (prefix ? " with-prefix" : "")}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <DatePicker onChange={props.onChange} type={type} defaultValue={value}  {...props} placeholder={" "} />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  )
}

export default FloatDatePicker
