import DataStore, { Query, QueryResult } from '../../core/stores/data-store'
import FormStore from '../../core/stores/form-store'

export interface PatSummary {
  id: string,
  name: string,
  expiresAt?: Date,
  createdById: string,
  createdOn: string,
  createByName: string
  lastDateOfUse? : Date
}
export interface NewPatItem {
  name: string,
  applicationName: string,
  expiresAt?: Date
}



export class PatsStore extends DataStore<PatSummary> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/keys`, [])
    this.publicUrl = baseUrl
  }

  public async update(id: string, item: PatSummary) {
    return this.save(id, item)
  }

  public async loadAll(query: Partial<Query>) {
    return await this.handleCallAsync(async () => {
      var response = await this.httpService.get<QueryResult<PatSummary>>(`${this.baseUrl}/allpats?${DataStore.buildUrl(query as Query)}`)
      this._state.set((s) => {
        s.items = response.data.items || (response.data as any).value || []
        s.count = response.data.count || response.data['@odata.count'] || 0
        return s
      })
      return true
    })
  }
}

export class PatItemStore extends FormStore<NewPatItem, NewPatItem> {
  private publicUrl: string
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/keys`)
    this.publicUrl = baseUrl
  }
}