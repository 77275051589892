import autobind from 'autobind-decorator'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Alert, Card } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { GroupsStore, GroupSummary } from 'src/stores/groups/groups-store'
import { getDefaultTableSettings, TableModel, TableView } from 'src/core/ui/collections/table'
import NewGroupView from './group-new'
import ContentTitle from 'src/core/ui/content-header'
import Link from 'src/core/ui/link'
import { CommandResult } from '../../../core/stores/types'
import { useEffect, useMemo, useState } from 'react'
import { Query } from '../../../core/stores/data-store'
import { container } from '../../../inversify.config'
import { GoldOutlined, UserAddOutlined } from '@ant-design/icons'
import { clone } from '../../../core/utils/object'
import { formatMessage } from '../../../core/services/http.service'
import AreaSelect from 'src/components/areaEdit'
import { CacheProps, withCache } from '../../../core/services/cache.service'

// tslint:disable-next-line:no-empty-interface
interface GroupsViewProps extends RouteComponentProps, WithTranslation, CacheProps {

}

interface GroupsViewState {
  query: Query
  newUserShown: boolean
  groupId: string | undefined
  scope: string | undefined
}

const Groups: React.FC<GroupsViewProps> = (props) => {

  const { t } = props
  const [query, setQuery] = useState({
    searchQuery: '', orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }], skip: 0, take: getDefaultTableSettings().defaultPageSize
  } as Query)

  const cacheKey = "IDENTITY-GROUPS-LIST"


  useEffect(() => {
    var currentQuery = query
    var areaId = areaFilter
    var queryStored = props.cache.getWithCustomKey(cacheKey,true)
    if (queryStored && queryStored.query)
      currentQuery = queryStored.query
    if (queryStored && queryStored.areaId)
      areaId = queryStored.areaId
    setAreaFilter(areaId) 
    setQuery(currentQuery)
    load(currentQuery)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [initPagination, setInitPagination] = useState(false)
  const [areaFilter, setAreaFilter] = useState<any>()

  const [newGroupShown, setnewGroupShown] = useState(false)
  const currentStore = useMemo(() => container.get(GroupsStore), [])
  const currentState = currentStore.state


  const load = (query: Query, areaId: string = areaFilter) => {

    props.cache.saveWithCustomKey(cacheKey, { query: query, areaId: areaId })
    currentStore.load(query)
  }


  const onDeleteRow = async (item: GroupSummary) => {
    await currentStore.delete(item.id)
    load(query)
  }

  const onSaveItem = async (item: GroupSummary) => {
    await currentStore.update(item.id, item)
    load(query)
  }

  const rightToolbar = (
    <>


      <li>
        <AreaSelect minWidth={200} value={areaFilter} placeholder={t("Select Area...")} valueAsItemReference onChange={(value) => {
          setInitPagination(!initPagination)
          let newQuery = query
          newQuery.filter = newQuery.filter || {}
          newQuery!.filter['areaId'] = value ? { eq: { type: 'guid', value: value.id } } : undefined
          setAreaFilter(value ? value.id : undefined)
          newQuery.skip = 0
          setQuery(newQuery)
          load(newQuery, value ? value.id : undefined)
        }
        } />
      </li>


    </>
  )


  const tableModel = {
    query: query,
    columns: [
      {
        field: 'name',
        title: t('Name'),
        sortable: true,
        renderer: (data) => <span><Link to={`/admin/groups/${data.id}`}><UserAddOutlined />&nbsp;<span>{data.name}</span></Link></span>,
        editor: (data) => <Input />
      },
      {
        field: 'description',
        title: t('Description'),
        renderer: (data) => <span>{data.description}</span>,
        editor: (data) => <Input />
      },
      {
        field: 'areaName',
        title: t('Area'),
        renderer: (data) => <span>  <GoldOutlined />&nbsp;{data.areaName}</span>,
      },
    ],
    data: currentState.value,
    sortFields: [],
  } as TableModel<GroupSummary>
  return (
    <div>
      <ContentTitle title={t("Groups")} />
      <Card>
        <TableView
          enableTableSettings
          model={tableModel}
          onQueryChanged={(q: Query) => { setQuery(clone<Query>(q)); load(clone<Query>(q)) }}
          rightToolbar={rightToolbar}
          onNewItem={() => setnewGroupShown(true)}
          onRefresh={() => load(query)}
          canEdit={false}
          canDelete={true}
          onSaveRow={(item) => onSaveItem(item)}
          onDeleteRow={(item) => onDeleteRow(item)}
          canCreateNew={true}
          error={currentState.errorMessage.value && formatMessage(currentState.errorMessage.value)}>
        </TableView>
        {
          newGroupShown && <NewGroupView onClose={() => { setnewGroupShown(false); load(query) }} {...props} />
        }
      </Card>
    </div>
  )
}
export default withCache(withTranslation()(withRouter(Groups)))
