import * as React from 'react'
import { Form, Card, Input, Button, Modal, Row, Col, Alert, Tabs, Spin } from 'antd'
let FormItem = Form.Item
let TextArea = Input.TextArea
import autobind from 'autobind-decorator'
import { withTranslation, WithTranslation } from 'react-i18next'
import ContentTitle from 'src/core/ui/content-header'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import FormEditorView from 'src/core/ui/form/form-editor'
import RoleScopesView from './scopes/rolescope-list'
import { clone } from 'src/core/utils/object'
const { TabPane } = Tabs
import ScopedPermissionList from 'src/components/scopedPermissionList'
import { RoleItemStore, RoleItem } from 'src/stores/roles/roles-store'
import { useEffect, useMemo, useRef, useState } from 'react'
import { container } from '../../../../inversify.config'
import FloatInput from '../../../../core/ui/awesome/floatComponent/input'
import FloatTextArea from '../../../../core/ui/awesome/floatComponent/textArea'

interface RoleViewProps extends WithTranslation, RouteComponentProps<RouterParameters> {

}


interface RouterParameters {
  applicationId: string
  id: string
}

const RoleView: React.FC<RoleViewProps> = (props) => {

  const currentStore = useMemo(() => container.get(RoleItemStore), [])
  const currentState = currentStore.state
  const [initialValues, setInitialValues] = useState<RoleItem | undefined>()
  const [form] = Form.useForm<RoleItem>()


  const inputRef = useRef()

  useEffect(() => {
    if (props.match.params.id) {
      load()
    }
  }, [inputRef]) // eslint-disable-line react-hooks/exhaustive-deps

  const load = async () => {
    currentStore.setApplicationId(props.match.params.applicationId)
    await currentStore.load(props.match.params.id)
    setInitialValues(currentState.item.get())
  }

  const onUpdate = async () => {
    let item: RoleItem
    try {
      item = (await form.validateFields()) as RoleItem
    } catch {
      return
    }
    currentStore.setApplicationId(props.match.params.applicationId)
    await currentStore.update(props.match.params.id, item)

  }

  const { t } = props
  return <div>
    <ContentTitle type={t("Role")} title={currentState.item.get()?.name} />
    <Row gutter={16}>
      <Col span={8}>
        <Card title={t("General properties")}>
          {currentState.errorMessage.get() && <Alert type="error" message={t(currentState.errorMessage.value || '')} />}
          <Spin spinning={currentState.isBusy.get()}>
            {initialValues &&
              <Form form={form} layout='vertical' initialValues={initialValues}>
                <FormItem 
                  name={'name'}
                >
                  <FloatInput label={t("Name")} readOnly placeholder={t("Name")} />
                </FormItem>
                <FormItem
                  name='description'>
                  <FloatTextArea label={t("Description")} placeholder={t("Description")} />
                </FormItem>
                <div
                  style={{
                    textAlign: 'right'
                  }}
                >
                  <Button onClick={() => { form.resetFields() }} style={{ marginRight: 8 }}>
                    {t('Cancel')}
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={() => onUpdate()} loading={currentState.isBusy?.value} >
                    {t('Save')}
                  </Button>
                </div>
              </Form>
            }
          </Spin>
        </Card>
      </Col>
      <Col span={16}>
        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab={t("Role access scopes")} key="1">
            <Card>
              <RoleScopesView id={props.match.params.id} applicationId={props.match.params.applicationId} />
            </Card>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  </div>
}

export default withRouter(withTranslation()(RoleView))
