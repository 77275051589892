import * as React from 'react'
import { Modal, Button, Tooltip, Steps, Alert, Form, Switch } from 'antd'
import autobind from 'autobind-decorator'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useState } from 'react'
import { CheckOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons'
import Step2 from './Steps/set-permissions'
import Step1 from './Steps/select-identity-tenant'
import { IdentityType } from '../../../../../stores/tenants/common'
import { UserItem, UserItemStore } from '../../../../../stores/users/users-store'
import { container } from '../../../../../inversify.config'
import { OperationRequestStore } from '../../../../../stores/operationRequests/operationRequests-store'
import { profile } from 'console'
import { IdentityProps, withIdentity } from '../../../../../core/services/authentication'

interface CreateOperationRequestViewProps {
  onClose: () => void
  selectedTenantId?: string
  selectedUserId?: string
  step?: number
}


const CreateOperationRequestView: React.FC<CreateOperationRequestViewProps & WithTranslation & IdentityProps> = (props) => {
  const { t } = props
  const [currentStep, setCurrentStep] = useState(props.step ? props.step : 0)
  const [editing] = useState(props.selectedUserId != undefined)
  const { Step } = Steps
  const [identityId, setIdentityId] = useState<string>(props.selectedUserId)
  const [tenantId, setTenantId] = useState<string>(props.selectedTenantId)
  const [errors, setErrors] = useState<string[]>([])
  const [apply, setApply] = useState(0)
  const [doneEnable, SetDoneEnable] = useState(false)

  const [form] = Form.useForm<UserItem>()
  const operationRequestStore = container.get(OperationRequestStore)
  const userStoreState = operationRequestStore.state

  const [confirmLoading, setConfirmLoading] = useState(false)

  const onCreateNewItem = async () => {

    let item: any
    try {
      item = (await form.validateFields())
    } catch {
      return false
    }
    setConfirmLoading(true)
    item.areaId = (item.areaId as any).id || item.areaId
    try {
      let response = await operationRequestStore.addNewUser(item)
      if (response) {
        var userId = response.identity
        setIdentityId(userId)
        form.resetFields()

      }
      setConfirmLoading(false)
      return true
    }
    catch {
      setErrors([operationRequestStore.state.errorMessage.get()])
      return false
    }
  }


  const steps = [
    {
      title: t('User Information'),
      content: (
        <Step1 form={form} selectedUserId={props.selectedUserId} onChangeIdentityId={(id) => setIdentityId(id)}
        />
      ),
    },
    {
      title: t("User's Permissions"),
      content: (
        <Step2 onDisableButton={(value) => SetDoneEnable(!value)}  apply={apply} identityId={identityId} onClose={(resp) => props.onClose()} entityId={tenantId} isGroup={false} />
      ),
    },
  ]

  const ValidateStep1 = async (close: boolean = false) => {
    let currentErrors = []

    if (!await onCreateNewItem())
      return
    if (!tenantId) {
      currentErrors.push(t("Select Tenant..."))
    }
    setErrors(currentErrors)
    if (currentErrors.length == 0)
      if (!close)
        setCurrentStep(currentStep + 1)
      else
        props.onClose();
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const next = (close: boolean = false) => {
    switch (currentStep) {
      case 0:
        ValidateStep1(close)
        break
      case 1:
        setApply(1)
        break
    }
  }

  const getFooter = () => {
    var result = []
    //if (currentStep > 0) {

 
    if (currentStep < steps.length - 1) {
      result.push(
        <Button type="default" loading={false} onClick={() => props.onClose()}>
          {t('Cancel')}
        </Button>
      )     

      result.push(
        <Button type="dashed" onClick={() => next(true)} style={{borderColor: '#0e0e4f', color: '#0e0e4f'}}>
          {t('Save and exit')}
        </Button>
      )
      result.push(
        <Button type="primary" onClick={() => next()}>
          {t('Next')}
        </Button>
      )
      
    }
    if (currentStep === steps.length - 1) {
      result.push(
        <Button type="default" loading={false} onClick={() => props.onClose()}>
          {t('Cancel')}
        </Button>
      )

      result.push(
        <Button type="primary" disabled={!doneEnable} loading={false} onClick={() => next()}>
          {t('Done')}
        </Button>
      )
     
    }

    return result
  }


  return (
    <Modal
      maskClosable={false}
      open={true}
      width={"800px"}
      centered
      closable={false}
      title={editing ? t("Edit Request") : t('Create Operation Request')}
      onCancel={() => props.onClose()}
      footer={getFooter()}
    >
      {errors && errors.map(x => <Alert message={x} type="error" />)}
      <Steps style={{ display: props.step == 1 ? "none" : undefined }} current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentStep].content}</div>

    </Modal>
  )
}

export default (withTranslation()(withIdentity(CreateOperationRequestView) as any)as any) as React.ComponentClass<CreateOperationRequestViewProps>

