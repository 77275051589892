import React, { useState } from "react"
import { Select } from "antd"

import "./float.less"

const FloatSelect = (props) => {
  const [focus, setFocus] = useState(false)
  let { label, value, placeholder, required } = props

  if (!placeholder) placeholder = label

  const isOccupied = focus || (value != undefined)
  const labelClass = isOccupied ? "label as-label" : "label as-placeholder"

  const requiredMark = required ? <span className="text-danger">*</span> : null

  return (
    <div
      className={"float-label"}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select onChange={props.onChange} value={value}  {...props} placeholder={""} />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  )
}

export default FloatSelect
