import DataStore from 'src/core/stores/data-store';

export interface NotificationItem {
  id: string;
  userId: string;
  description: string;
  type: string;
  data: any;
  taskId: string;
  readed: boolean;
  creationTime: Date;
}

export interface TaskItem {
  id: string
  startTime: Date
  endTime: Date
  createdById: string
  status: string
  result: any
  //Json
  request: any
  type: string
}
export class NotificationItemStore extends DataStore<NotificationItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/notifications`, []);
  }

  public async Counter() {
    return await this.httpService.get(this.baseUrl+"/active", {});
  }
  public async SetReadStatus() {
    await this.httpService.put(this.baseUrl, {});
  }
  public async delete(id: string) {
    await this.httpService.delete(this.baseUrl + '/' + id, {});
  }
  public async deleteAll() {
    await this.httpService.delete(this.baseUrl, {});
  }
}

export class TaskItemStore extends DataStore<TaskItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/v1/admin/tasks/active`, [])
  }

 
}
